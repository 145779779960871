import { RFS_URL } from 'config'
import { DocumentType, HttpHeader, MimeType } from 'constants/'
import { useCallback } from 'react'
import { ActiveFitting, DownloadableDocument, Fitting, RfsDocument } from 'types'
import { arrayBufferToBase64, identity, pick } from 'utils'
import { useRequest } from '../useRequest'
import { mergeDocumentsByType } from './mergeDocumentsByType'

enum ApiPathname {
  AUDIOGRAM_LATEST = '/partner/api/v1/audiogram-latest',
  CUSTOMER_IMAGES = '/cockpit/api/v1/customer-images/:customerId',
  DOCUMENTS = '/cockpit/api/v1/fittings/:fittingId/documents',
  DOCUMENTS_ONE = '/cockpit/api/v1/fittings/:fittingId/documents/:documentId/file',
  FITTINGS = '/partner/api/v1/fittings',
  FITTING = '/logistics/api/v1/fittings/:fittingId',
  ACTIVE_FITTING = '/tablet/api/v1/fittings/current',
  TABLET = '/logistics/api/v1/tablets/:tabletId',
  NOTE = '/logistics/api/v1/typeform',
}

type GetCustomerImagesQuery = { customerId: string }
type GetCustomerImagesResponse = string[]

type GetDocumentsQuery = { fittingId: string }
type GetDocumentsQueryAll = GetDocumentsQuery & { documentTypes: string[] }
type GetDocumentsResponse = RfsDocument[]

type GetDownloadableDocumentQuery = {
  document: RfsDocument
  fittingId: string
}
type GetDownloadableDocumentResponse = DownloadableDocument

type GetFittingsQuery = { accountId: string }
type GetFittingsResponse = Fitting[]

type GetFittingQuery = { fittingId: string }
type GetFittingResponse = Fitting

type GetCustomerFitting = { customerId: string }
type GetCustomerFittingResponse = ActiveFitting

interface UseHookContext {
  getCustomerImages: (query: GetCustomerImagesQuery) => Promise<GetCustomerImagesResponse>
  getDocuments: (query: GetDocumentsQuery) => Promise<GetDocumentsResponse>
  getDownloadableDocument: (query: GetDownloadableDocumentQuery) => Promise<GetDownloadableDocumentResponse>
  getFitting: (query: GetFittingQuery) => Promise<GetFittingResponse>
  getFittings: (query: GetFittingsQuery) => Promise<GetFittingsResponse>
  getActiveFitting: (query: GetCustomerFitting) => Promise<GetCustomerFittingResponse>
}

const baseUrl = new URL(RFS_URL)

const allowedDocumentsList: DocumentType[] = [
  DocumentType.MEDICAL_WAIVER,
  DocumentType.HIPAA_NOTICE,
  DocumentType.PURCHASE_DELIVERY_RECEIPT,
]

const filterQueryToSearchParams = identity // Take all that is passed in and apply to query params
const filterQueryToRouteParams = identity // Take all that is passed in and apply to route params

const useRemoteFittingService = (): UseHookContext => {
  const getCustomerImages = useRequest<GetCustomerImagesQuery, GetCustomerImagesResponse>({
    baseUrl,
    filterQueryToRouteParams,
    pathname: ApiPathname.CUSTOMER_IMAGES,
  })

  const getDocumentsRequest = useRequest<GetDocumentsQueryAll, GetDocumentsResponse>({
    baseUrl,
    filterQueryToRouteParams: pick(['fittingId']),
    filterQueryToSearchParams: pick(['documentTypes']),
    pathname: ApiPathname.DOCUMENTS,
  })

  const getDocuments = useCallback(
    async (query: GetDocumentsQuery) => {
      const queryParamsAll: GetDocumentsQueryAll = {
        ...query,
        documentTypes: allowedDocumentsList,
      }

      const documents = await getDocumentsRequest(queryParamsAll)

      return mergeDocumentsByType(documents)
    },
    [getDocumentsRequest]
  )

  const getDownloadableDocumentRequest = useRequest<GetDownloadableDocumentQuery, string>({
    baseUrl,
    filterQueryToRouteParams: ({ document, fittingId }: GetDownloadableDocumentQuery) => ({
      documentId: document.documentId,
      fittingId,
    }),
    getConfig: () =>
      ({
        headers: {
          [HttpHeader.ACCEPT]: MimeType.PDF,
        },
      } as Partial<RequestInit>),
    pathname: ApiPathname.DOCUMENTS_ONE,
    transformResponse: async (response: Response) => {
      const arrayBuffer = await response.arrayBuffer()

      return arrayBufferToBase64(arrayBuffer)
    },
  })

  const getDownloadableDocument = useCallback(
    async (query: GetDownloadableDocumentQuery) => {
      const contents = await getDownloadableDocumentRequest(query)

      return {
        contents,
        document: query.document,
      }
    },
    [getDownloadableDocumentRequest]
  )

  const getFitting = useRequest<GetFittingQuery, GetFittingResponse>({
    baseUrl,
    filterQueryToRouteParams,
    pathname: ApiPathname.FITTING,
  })

  const getFittings = useRequest<GetFittingsQuery, GetFittingsResponse>({
    baseUrl,
    filterQueryToSearchParams,
    pathname: ApiPathname.FITTINGS,
  })

  const getActiveFitting = useRequest<GetCustomerFitting, GetCustomerFittingResponse>({
    baseUrl,
    filterQueryToRouteParams,
    filterQueryToSearchParams: pick(['customerId']),
    pathname: ApiPathname.ACTIVE_FITTING,
  })

  return {
    getActiveFitting,
    getCustomerImages,
    getDocuments,
    getDownloadableDocument,
    getFitting,
    getFittings,
  }
}

export { useRemoteFittingService }
