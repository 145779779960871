import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core'
import { Route } from 'constants/'
import { useFeatureFlags } from 'hooks'
import { useTranslation } from 'react-i18next'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { Link } from '../../../Link'
import { useStyles } from './index.styles'

const Breadcrumbs = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isNewCustomerProgressBarFlagEnabled] = useFeatureFlags(['PROGRESS_BAR_V2'])
  const customersTitle = isNewCustomerProgressBarFlagEnabled
    ? t('bread_crumbs.active_trials')
    : t('bread_crumbs.customer_search')

  const routes = [
    { breadcrumb: t('bread_crumbs.documents'), path: `${Route.CUSTOMER_PROFILE}/documents` },
    { breadcrumb: t('bread_crumbs.media'), path: `${Route.CUSTOMER_PROFILE}/media` },
    { breadcrumb: t('bread_crumbs.timeline'), path: `${Route.CUSTOMER_PROFILE}/timeline` },
    { breadcrumb: t('bread_crumbs.personal_information'), path: `${Route.CUSTOMER_PROFILE}/personal-information` },
    { breadcrumb: t('bread_crumbs.customer_details'), path: Route.CUSTOMER_PROFILE },
    { breadcrumb: t('bread_crumbs.home'), path: '' },
    { breadcrumb: t('bread_crumbs.settings'), path: Route.SETTINGS },
    { breadcrumb: t('bread_crumbs.scheduling'), path: Route.SETTINGS_SCHEDULING },
    { breadcrumb: customersTitle, path: Route.CUSTOMERS },
    { breadcrumb: t('bread_crumbs.customer_search'), path: Route.GLOBAL_CUSTOMERS },
    { breadcrumb: t('bread_crumbs.notifications'), path: Route.NOTIFICATIONS },
  ]

  const breadcrumbs = useBreadcrumbs(routes)

  if (breadcrumbs.length < 2) {
    return null
  }

  return (
    <div>
      <div style={{ height: '1rem' }} />
      <MuiBreadcrumbs className={classes.container} separator=">">
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          if (index === breadcrumbs.length - 1) {
            return <span key={match.pathname}>{breadcrumb}</span>
          }

          return (
            <Link color="inherit" key={match} to={match.pathname} underline="hover">
              {breadcrumb}
            </Link>
          )
        })}
      </MuiBreadcrumbs>
    </div>
  )
}

export { Breadcrumbs }
