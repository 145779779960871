import { defaultQueryOptions } from 'constants/'
import { RoleFlagsMap, useUser } from 'hooks/useUser'
import { useQuery } from 'react-query'
import { JourneyStatus, Opportunity, PartnerPortalDeliveryModel, QueryOptions, SalesStatus, SortDirection } from 'types'
import { findLatestOpportunities, logFactory } from 'utils'
import { OpportunitySortableFields, useOpportunityService } from '../services'

const hookName = 'useOpportunities'
const log = logFactory(hookName)

type UseOpportunitiesQuery = {
  country?: string[]
  customerSalesforceId?: string[]
  deliveryModel?: PartnerPortalDeliveryModel[]
  fitterSalesforceId?: string[]
  journeyStatus?: JourneyStatus[]
  fetchAllPages?: boolean
  limit?: number
  offset?: number
  salesStatus?: SalesStatus[]
  sort?: SortDirection
  sortBy?: OpportunitySortableFields
}

const useOpportunities = (
  { fetchAllPages, ...query }: UseOpportunitiesQuery,
  options: QueryOptions = { enabled: true }
) => {
  const restrictQueryToOpportunityFitter = Boolean(query.fitterSalesforceId)

  const { getLatestOpportunities } = useOpportunityService({ restrictQueryToOpportunityFitter })
  let foundItems: Opportunity[] = []
  let hasNextPage = false
  let pageIndex = query.offset ?? 0
  let finalMeta

  const params = { ...query } as UseOpportunitiesQuery
  const user = useUser()

  const { isAdmin, isOperations } = user.roleFlags as RoleFlagsMap

  const doQuery = async () => {
    log(`🥰 Fetching latest opportunities...`)
    do {
      const { data, meta } = await getLatestOpportunities({ ...params, offset: pageIndex })

      foundItems = [...foundItems, ...data]
      finalMeta = meta
      hasNextPage = pageIndex + 1 < meta.pagesTotal
      pageIndex++
      if (!fetchAllPages) {
        hasNextPage = false
      }
    } while (hasNextPage)

    log(`🥰 Found ${foundItems.length} opportunities`, foundItems)

    const shouldNotFilter = !(isAdmin || isOperations)
    const latestOpportunities = findLatestOpportunities(foundItems, shouldNotFilter, query.sort)

    return { data: latestOpportunities, meta: finalMeta }
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useOpportunities }
export type { UseOpportunitiesQuery }
