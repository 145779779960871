import { Environment } from 'constants/'

if (!process.env.REACT_APP_ACCOUNT_SERVICE_URL) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const dotenv = require('dotenv')

  dotenv.config({
    path: './environments/.env.testing',
  })
}

const ACCOUNT_SERVICE_URL = process.env.REACT_APP_ACCOUNT_SERVICE_URL as string
const ALLOWED_ORIGIN_CLIENT_URLS = process.env.REACT_APP_ALLOWED_ORIGIN_CLIENT_URLS as string
const APP_VERSION = process.env.REACT_APP_VERSION as string
const APPOINTMENT_SERVICE_URL_EU = process.env.REACT_APP_APPOINTMENT_SERVICE_URL_EU as string
const APPOINTMENT_SERVICE_URL_NA = process.env.REACT_APP_APPOINTMENT_SERVICE_URL_NA as string
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE as string
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string
const COCKPIT_URL = process.env.REACT_APP_COCKPIT_URL as string
const CUSTOMER_PROFILE_CLIENT_URL = process.env.REACT_APP_CUSTOMER_PROFILE_CLIENT_URL as string
const CUSTOMER_SERVICE_URL_EU = process.env.REACT_APP_CUSTOMER_SERVICE_URL_EU as string
const CUSTOMER_SERVICE_URL_NA = process.env.REACT_APP_CUSTOMER_SERVICE_URL_NA as string
const DEVICE_COUPLING_FORM_URL = process.env.REACT_APP_DEVICE_COUPLING_FORM_URL as string
const DEVICE_SERVICE_URL_EU = process.env.REACT_APP_DEVICE_SERVICE_URL_EU as string
const DEVICE_SERVICE_URL_NA = process.env.REACT_APP_DEVICE_SERVICE_URL_NA as string
const DOCUMENT_CLIENT_URL = process.env.REACT_APP_DOCUMENT_CLIENT_URL as string
const DOCUMENT_SERVICE_URL_EU = process.env.REACT_APP_DOCUMENT_SERVICE_URL_EU as string
const DOCUMENT_SERVICE_URL_NA = process.env.REACT_APP_DOCUMENT_SERVICE_URL_NA as string
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as Environment
const FORCE_ENABLED_FEATURE_FLAGS = process.env.REACT_APP_FORCE_ENABLED_FEATURE_FLAGS?.split(',') ?? []
const HEARING_TEST_CLIENT_URL = process.env.REACT_APP_HEARING_TEST_CLIENT_URL as string
const HEARING_TEST_SERVICE_URL_NA = process.env.REACT_APP_HEARING_TEST_SERVICE_URL_NA as string
const ITEM_SERVICE_URL_NA = process.env.REACT_APP_ITEM_SERVICE_URL_NA as string
const LAUNCH_DARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string as string
const LEGACY_APPOINTMENT_SERVICE_URL = process.env.REACT_APP_LEGACY_APPOINTMENT_SERVICE_URL as string
const LIVECHAT_LICENSE = process.env.REACT_APP_LIVECHAT_LICENSE as string
const LOGPIT_URL = process.env.REACT_APP_LOGPIT_URL as string
const LOGROCKET_PROJECT_ID = process.env.REACT_APP_LOGROCKET_PROJECT_ID as string
const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string as string
const MRS_URL = process.env.REACT_APP_MRS_URL as string
const NOTE_SERVICE_URL_NA = process.env.REACT_APP_NOTE_SERVICE_URL_NA as string
const NOTIFICATION_SERVICE_URL = process.env.REACT_APP_NOTIFICATION_SERVICE_URL as string
const OLS_URL = process.env.REACT_APP_OLS_URL as string
const OPPORTUNITY_SERVICE_URL_EU = process.env.REACT_APP_OPPORTUNITY_SERVICE_URL_EU as string
const OPPORTUNITY_SERVICE_URL_NA = process.env.REACT_APP_OPPORTUNITY_SERVICE_URL_NA as string
const ORDER_SERVICE_URL_EU = process.env.REACT_APP_ORDER_SERVICE_URL_EU as string
const ORDER_SERVICE_URL_NA = process.env.REACT_APP_ORDER_SERVICE_URL_NA as string
const PARTNER_SERVICE_URL_EU = process.env.REACT_APP_PARTNER_SERVICE_URL_EU as string
const PARTNER_SERVICE_URL_NA = process.env.REACT_APP_PARTNER_SERVICE_URL_NA as string
const POST_APPOINTMENT_FORM_URL = process.env.REACT_APP_POST_APPOINTMENT_FORM_URL as string
const RFS_URL = process.env.REACT_APP_RFS_URL as string
const SALES_ORDER_FORM_URL = process.env.REACT_APP_SALES_ORDER_FORM_URL as string
const SALESFORCE_URL = process.env.REACT_APP_SALESFORCE_URL as string
const SCHEDULING_UI_URL = process.env.REACT_APP_SCHEDULING_UI_URL as string
const SHIPPING_SERVICE_URL_NA = process.env.REACT_APP_SHIPPING_SERVICE_URL_NA as string
const TYPEFORM_ORDER_FORM_URL = process.env.REACT_APP_TYPEFORM_ORDER_FORM_URL as string

const isDev = ENVIRONMENT === Environment.DEVELOPMENT
const liveEnvironments = [Environment.TESTING, Environment.PRODUCTION, Environment.STAGING]
const isLiveEnvironment = liveEnvironments.includes(ENVIRONMENT as Environment)

export {
  ACCOUNT_SERVICE_URL,
  ALLOWED_ORIGIN_CLIENT_URLS,
  APP_VERSION,
  APPOINTMENT_SERVICE_URL_EU,
  APPOINTMENT_SERVICE_URL_NA,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  COCKPIT_URL,
  CUSTOMER_PROFILE_CLIENT_URL,
  CUSTOMER_SERVICE_URL_EU,
  CUSTOMER_SERVICE_URL_NA,
  DEVICE_COUPLING_FORM_URL,
  DEVICE_SERVICE_URL_EU,
  DEVICE_SERVICE_URL_NA,
  DOCUMENT_CLIENT_URL,
  DOCUMENT_SERVICE_URL_EU,
  DOCUMENT_SERVICE_URL_NA,
  ENVIRONMENT,
  FORCE_ENABLED_FEATURE_FLAGS,
  HEARING_TEST_CLIENT_URL,
  HEARING_TEST_SERVICE_URL_NA,
  isDev,
  isLiveEnvironment,
  ITEM_SERVICE_URL_NA,
  LAUNCH_DARKLY_CLIENT_ID,
  LEGACY_APPOINTMENT_SERVICE_URL,
  LIVECHAT_LICENSE,
  LOGPIT_URL,
  LOGROCKET_PROJECT_ID,
  MIXPANEL_PROJECT_TOKEN,
  MRS_URL,
  NOTE_SERVICE_URL_NA,
  NOTIFICATION_SERVICE_URL,
  OLS_URL,
  OPPORTUNITY_SERVICE_URL_EU,
  OPPORTUNITY_SERVICE_URL_NA,
  ORDER_SERVICE_URL_EU,
  ORDER_SERVICE_URL_NA,
  PARTNER_SERVICE_URL_EU,
  PARTNER_SERVICE_URL_NA,
  POST_APPOINTMENT_FORM_URL,
  RFS_URL,
  SALES_ORDER_FORM_URL,
  SALESFORCE_URL,
  SCHEDULING_UI_URL,
  SHIPPING_SERVICE_URL_NA,
  TYPEFORM_ORDER_FORM_URL,
}
