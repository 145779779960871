import { Typography } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import { FormType, Route } from 'constants/'
import { useMatch } from 'react-router-dom'
import { Home } from './Home'
import { useStyles } from './index.styles'
import { Search } from './Search'
import { UserItems } from './UserItems'

const formTypeToTitle: Record<FormType, string> = {
  [FormType.POST_APPOINTMENT]: 'Post Appointment Outcome Form',
}

const matchPath = `${Route.FORMS}/*`

const Header = () => {
  const match = useMatch(matchPath)
  const classes = useStyles()

  const isMatch = Boolean(match)
  const [, , formType] = isMatch ? match?.pathname.split('/') ?? [] : [] // /forms/:type/:customerId
  const type = (formType ?? '').toLowerCase() as FormType
  const title = formTypeToTitle[type] ?? ''

  return (
    <AppBar className={classes.appBar}>
      <div className={classes.root}>
        <div className={classes.left}>
          <Home />
        </div>
        <div className={classes.centered}>
          {title ? <Typography className={classes.title}>{title}</Typography> : <Search />}
        </div>
        <div className={classes.userItems}>
          <UserItems />
        </div>
      </div>
    </AppBar>
  )
}

export { Header }
