import { Button } from '@material-ui/core'
import { isDev } from 'config'
import { textConstants } from 'constants/'
import { FC, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { logFactory } from 'utils'
import { useNotification } from '../../../../../hooks'

interface ReleaseInfo {
  commit: string
  message: string
}

const log = logFactory('NewReleaseProvider')

const NewReleaseNotification = () => {
  const onClick = () => window.location.reload()

  return (
    <>
      <span>An update has been released for the {textConstants.appName}. Refresh to take it for a spin.</span>
      <Button
        aria-label="Refresh the page"
        color="inherit"
        onClick={onClick}
        style={{ fontSize: '0.75rem', marginLeft: '1rem' }}
        variant="outlined"
      >
        Refresh
      </Button>
    </>
  )
}

const fetchRelease = async () => {
  log('🐟 Fetching release info...')

  const cacheBust = Date.now()
  const data = await fetch(`/release.json?cb=${cacheBust}`).then(async (r) => (await r.json()) as ReleaseInfo)

  log('🐟 Found release info', data)

  return data.commit
}

const fiveSecondsInMs = 5 * 1_000
const thirtyMinutesInMs = 30 * 60 * 1_000

const NewReleaseProvider: FC = ({ children }) => {
  const previousCommitRef = useRef('')
  const { notify } = useNotification()

  const { data: commit, isFetched } = useQuery<string>('release', fetchRelease, {
    cacheTime: fiveSecondsInMs,
    enabled: !isDev,
    refetchInterval: thirtyMinutesInMs,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  })

  useEffect(() => {
    if (!isFetched || !commit) {
      log('🆕 Not fetched or there is no commit', { commit, isFetched })
      return
    }

    if (!previousCommitRef.current) {
      log('🆕 Initializing commit...', { commit })
      previousCommitRef.current = commit as string

      return
    }

    if (previousCommitRef.current === commit) {
      return
    }

    log('🆕 Commit changed...', { commit, previous: previousCommitRef.current })

    notify(<NewReleaseNotification />, 'info', { action: null, key: 'new-release', persist: true })
  }, [commit, isFetched])

  return <>{children}</>
}

export { NewReleaseProvider }
