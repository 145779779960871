import { defaultPagination, HttpMethod } from 'constants/'
import { useCallback } from 'react'
import { HearingTestServicePureToneTest, Query, QueryResponse, SpeechTestServiceTest } from 'types'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'

enum ApiPathname {
  PURE_TONE_TEST = '/api/v1/pure-tone-test',
  SPEECH_TEST = '/api/v1/speech-test',
  WORD_RECOGNITION_TEST = '/api/v1/word-recognition-test',
}

type ConductionType = 'AC' | 'BC'
type PureToneTestType = 'THRESHOLD' | 'UCL' | 'MCL'

type GetAudiogramQuery = Query<{ customerId: string; conductionType?: ConductionType; type?: PureToneTestType }>
type GetAudiogramResponse = QueryResponse<HearingTestServicePureToneTest[]>

type GetSpeechTestQuery = Query<{ customerId: string; conductionType?: ConductionType }>
type GetSpeechTestResponse = QueryResponse<SpeechTestServiceTest[]>

type GetWordRecognitionTestQuery = Query<{ customerId: string; conductionType?: ConductionType }>
type GetWordRecognitionTestResponse = QueryResponse<SpeechTestServiceTest[]>

interface UseHookContext {
  getAudiograms: (query: GetAudiogramQuery) => Promise<GetAudiogramResponse>
  getSpeechTests: (query: GetSpeechTestQuery) => Promise<GetSpeechTestResponse>
  getWordRecognitionTests: (query: GetWordRecognitionTestQuery) => Promise<GetWordRecognitionTestResponse>
}

const defaultPageSize = 25

const filterAudiogramQueryToSearchParams = ({
  conductionType: conduction_type,
  customerId: customer_id,
  type,
  ...rest
}: GetAudiogramQuery) => ({
  ...rest,
  conduction_type,
  customer_id,
  type: type ?? 'THRESHOLD',
})

const filterSpeechTestQueryToSearchParams = ({
  conductionType: conduction_type,
  customerId: customer_id,
  ...rest
}: GetSpeechTestQuery) => ({
  ...rest,
  conduction_type,
  customer_id,
})

const useHearingTestService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('hearing-test')

  const getAudiogramRequest = useRequest<GetAudiogramQuery, GetAudiogramResponse>({
    baseUrl,
    filterQueryToSearchParams: filterAudiogramQueryToSearchParams,
    method: HttpMethod.GET,
    pathname: ApiPathname.PURE_TONE_TEST,
  })

  const getAudiograms = useCallback(
    (query: GetAudiogramQuery) =>
      getAudiogramRequest({
        ...defaultPagination,
        page_size: defaultPageSize,
        ...query,
      }),
    [getAudiogramRequest]
  )

  const getSpeechTestRequest = useRequest<GetSpeechTestQuery, GetSpeechTestResponse>({
    baseUrl,
    filterQueryToSearchParams: filterSpeechTestQueryToSearchParams,
    method: HttpMethod.GET,
    pathname: ApiPathname.SPEECH_TEST,
  })

  const getSpeechTests = useCallback(
    (query: GetSpeechTestQuery) =>
      getSpeechTestRequest({
        ...defaultPagination,
        page_size: defaultPageSize,
        ...query,
      }),
    [getSpeechTestRequest]
  )

  const getWordRecognitionTestRequest = useRequest<GetWordRecognitionTestQuery, GetWordRecognitionTestResponse>({
    baseUrl,
    filterQueryToSearchParams: filterSpeechTestQueryToSearchParams,
    method: HttpMethod.GET,
    pathname: ApiPathname.WORD_RECOGNITION_TEST,
  })

  const getWordRecognitionTests = useCallback(
    (query: GetWordRecognitionTestQuery) =>
      getWordRecognitionTestRequest({
        ...defaultPagination,
        page_size: defaultPageSize,
        ...query,
      }),
    [getWordRecognitionTestRequest]
  )

  return { getAudiograms, getSpeechTests, getWordRecognitionTests }
}

export { useHearingTestService }
export type { GetAudiogramQuery, GetSpeechTestQuery, GetWordRecognitionTestQuery }
