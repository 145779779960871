import { JourneyStatus, SalesStatus } from 'types'

const statusDisplayText: Record<JourneyStatus | 'UNKNOWN', string> = {
  [JourneyStatus.CLOSED]: 'Closed',
  [JourneyStatus.IN_TRIAL]: 'In Trial',
  [JourneyStatus.OPEN]: 'Open',
  [JourneyStatus.PREPARING_FOR_FITTING_APPOINTMENT]: 'Pre-Appointment',
  UNKNOWN: 'Unknown',
}

const salesStatusDisplayText: Record<SalesStatus | 'UNKNOWN', string> = {
  [SalesStatus.LOST]: 'Lost',
  [SalesStatus.OPEN]: 'Open',
  [SalesStatus.RETURNED]: 'Returned',
  [SalesStatus.WON]: 'Won',
  UNKNOWN: 'Unknown',
}

const opportunityStatusToDisplayText = (status: JourneyStatus) => statusDisplayText[status] ?? statusDisplayText.UNKNOWN
const salesStatusToDisplayText = (status: SalesStatus) => salesStatusDisplayText[status] ?? statusDisplayText.UNKNOWN

export { opportunityStatusToDisplayText, salesStatusToDisplayText }
