enum ReceiverStrength {
  STANDARD = 's',
  MEDIUM = 'm',
  POWER = 'p',
}

enum ReceiverType {
  MINIREC_2 = 'minirec_2',
  MINIREC_3 = 'minirec_3',
}

enum ReceiverLength {
  L_1 = 'length_1',
  L_2 = 'length_2',
  L_3 = 'length_3',
  L_4 = 'length_4',
}

enum SleeveSize {
  EXTRA_SMALL = 'xs',
  SMALL = 's',
  MEDIUM = 'm',
  LARGE = 'l',
}

enum Sleeve_3_Size {
  EXTRA_SMALL = 'sleeve_3_xs',
  SMALL = 'sleeve_3_s',
  MEDIUM = 'sleeve_3_m',
  LARGE = 'sleeve_3_l',
}

enum DomeSize {
  MM_4 = 'dome_4mm',
  MM_6 = 'dome_6mm',
  MM_8 = 'dome_8mm',
  MM_10 = 'dome_10mm',
  MM_8_10 = 'dome_8_10mm',
  MM_10_12 = 'dome_10_12mm',
  ONE_SIZE_FITS_ALL = 'dome_one_size',
}

enum Eartip_3_Size {
  MM_5 = 'eartip_3_5mm',
  MM_7 = 'eartip_3_7mm',
  MM_8 = 'eartip_3_8mm',
  MM_10 = 'eartip_3_10mm',
  MM_12 = 'eartip_3_12mm',
}

enum DeviceStyle {
  CLOSED = 'closed',
  DOUBLE = 'double',
  OPEN = 'open',
  POWER = 'power',
  SEMI_OPEN = 'semi_open',
  TULIP = 'tulip',
  VENTED = 'vented',
}

enum DeviceType {
  DOME = 'dome',
  SLEEVE = 'sleeve',
  EARTIP_3 = 'eartip_3',
  SLEEVE_3 = 'sleeve_3',
}

enum Side {
  LEFT = 'left',
  RIGHT = 'right',
}

enum Highlight {
  CHANGED = 'changed',
  REDACTED = 'redacted',
}

export {
  DeviceStyle,
  DeviceType,
  DomeSize,
  Eartip_3_Size,
  Highlight,
  ReceiverStrength,
  ReceiverLength,
  ReceiverType,
  Side,
  SleeveSize,
  Sleeve_3_Size,
}
