import { Button as MuiButton, Tooltip } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import { useStyles } from './index.styles'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

const Button = ({ isOpen, onClose, onOpen }: Props) => {
  const { icon: iconClass, ...classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Tooltip
      arrow
      enterDelay={1_000}
      leaveDelay={0}
      placement="right"
      title={t('general.navigation.toggle.tooltip_text')}
    >
      <MuiButton
        aria-label={t('general.navigation.toggle.tooltip_aria_label')}
        classes={classes}
        disableRipple
        onClick={isOpen ? onClose : onOpen}
        size="small"
        variant="contained"
      >
        <ChevronLeft aria-expanded={isOpen} className={iconClass} />
      </MuiButton>
    </Tooltip>
  )
}

export { Button }
