import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useMedicalRecordsService } from '../services'

const hookName = 'useNotesRaw'
const log = logFactory(hookName)

const useNotesRaw = (customerGid: string, options: QueryOptions = { enabled: true }) => {
  const { getNotes } = useMedicalRecordsService()
  const params = { customerGid }

  const doQuery = async () => {
    log(`📓 Getting (raw) notes for customer...`, params)

    const data = await getNotes(params)

    log(`📓 Found (raw) notes for customer`, data)

    return data
  }

  return useQuery([hookName], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useNotesRaw }
