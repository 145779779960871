import { useCallback } from 'react'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
}

interface SessionDurationContext {
  readDurationSeconds: () => number
}

const context = createContext<SessionDurationContext>({
  readDurationSeconds: () => 0,
})

const { Provider } = context

const useSessionDuration = (): SessionDurationContext => useContext(context)

const SessionDurationProvider = ({ children }: Props) => {
  const [startTime, setStartTime] = useState<number>(0)

  const readDurationSeconds = useCallback(() => Math.round((Date.now() - startTime) / 1000), [startTime])

  useEffect(() => setStartTime(Date.now()), [])

  const contextValue = { readDurationSeconds }

  return <Provider value={contextValue}>{children}</Provider>
}

export { SessionDurationProvider as Provider, useSessionDuration }
