enum Source {
  INTERNAL = 'INTERNAL',
  KOALYS = 'KOALYS',
}

enum Transducer {
  BONE_CONDUCTOR = 'BONE_CONDUCTOR',
  FREE_FIELD = 'FREE_FIELD',
  IN_SITU = 'IN_SITU',
  INSERTS = 'INSERTS',
  OVER_EAR = 'OVER_EAR',
}

export { Source as HearingTestSource, Transducer as HearingTestTransducer }
