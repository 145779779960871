import { AppointmentType, textConstants } from 'constants/'

const appointmentTypeDisplayText = {
  [AppointmentType.AFTERCARE_FOLLOW_UP]: 'AFU',
  [AppointmentType.FOLLOW_UP]: 'FU',
  [AppointmentType.HEARING_AID_EVALUATION]: 'HAE',
  [AppointmentType.VIDEO_CARE_CALL]: 'VCC',
}

const toAppointmentTypeDisplayAbbreviation = (type: AppointmentType) =>
  appointmentTypeDisplayText[type] ?? textConstants.unknown

export { toAppointmentTypeDisplayAbbreviation }
