import { HearingTestSource, HearingTestTransducer } from 'constants/'
import { HearingTestServiceConductionType, SpeechTestServiceTest, TestEarSide } from 'types'
import { sort } from 'utils'

type UiSpeechTestType = 'SAT' | 'SRT' | 'MCL' | 'UCL'
type EmptyResultObject = Record<string, never>

type TestEarSideDTO = TestEarSide & {
  source: HearingTestSource
  conduction_type: HearingTestServiceConductionType
  transducer_type: HearingTestTransducer
  word_list?: { list: string; voice: string }
}

const SIBLING_CREATED_AT_THRESHOLD_IN_DAYS = 1

const sortTestsByCreatedAtDesc = (a: SpeechTestServiceTest, b: SpeechTestServiceTest) => {
  const aTime = new Date(a.created_at)
  const bTime = new Date(b.created_at)

  const aCompare = aTime.getTime()
  const bCompare = bTime.getTime()

  return bCompare - aCompare
}

const sortTestsByTestedAtDesc = (a: TestEarSide, b: TestEarSide) => {
  const aTime = new Date(a.tested_at)
  const bTime = new Date(b.tested_at)

  const aCompare = aTime.getTime()
  const bCompare = bTime.getTime()

  return bCompare - aCompare
}
const removeEmptyResults = (test: TestEarSide) => Boolean(test.results)

const isValidTest = (speechTest: SpeechTestServiceTest) => {
  const { left_ear, right_ear } = speechTest

  return Boolean(left_ear?.results || right_ear?.results)
}

type EarSideGrouping = Record<string, TestEarSideDTO[]>
type SpeechTestGroup = Record<UiSpeechTestType, EarSideGrouping> | EmptyResultObject

const toGroupedTests = (speechTests: SpeechTestServiceTest[]): SpeechTestGroup => {
  if (!speechTests?.length) {
    return {}
  }

  const groupedByEarAndTestType = speechTests.reduce((acc, test) => {
    const binaural = {
      ...test.binaural,
      conduction_type: test.conduction_type,
      source: test.source,
      transducer_type: test.transducer_type,
      word_list: test.word_list,
    }
    const left_ear = {
      ...test.left_ear,
      conduction_type: test.conduction_type,
      source: test.source,
      transducer_type: test.transducer_type,
      word_list: test.word_list,
    }
    const right_ear = {
      ...test.right_ear,
      conduction_type: test.conduction_type,
      source: test.source,
      transducer_type: test.transducer_type,
      word_list: test.word_list,
    }

    if (!acc[test.type]) {
      acc[test.type] = {
        binaural: [binaural],
        left_ear: [left_ear],
        right_ear: [right_ear],
      }
    } else {
      acc[test.type].left_ear = [...acc[test.type].left_ear, left_ear]
      acc[test.type].right_ear = [...acc[test.type].right_ear, right_ear]
      acc[test.type].binaural = [...acc[test.type].binaural, binaural]
    }

    acc[test.type].binaural = acc[test.type].binaural.sort(sortTestsByTestedAtDesc).filter(removeEmptyResults)
    acc[test.type].left_ear = acc[test.type].left_ear.sort(sortTestsByTestedAtDesc).filter(removeEmptyResults)
    acc[test.type].right_ear = acc[test.type].right_ear.sort(sortTestsByTestedAtDesc).filter(removeEmptyResults)

    return acc
  }, {} as SpeechTestGroup)

  return groupedByEarAndTestType
}

const groupSpeechTests = (allSpeechTests: SpeechTestServiceTest[]): SpeechTestGroup | EmptyResultObject => {
  if (!allSpeechTests?.length) {
    return {}
  }

  const validSpeechTests = allSpeechTests.filter(isValidTest)
  const validSortedSpeechTests = sort(validSpeechTests, sortTestsByCreatedAtDesc)

  return toGroupedTests(validSortedSpeechTests)
}

export { SIBLING_CREATED_AT_THRESHOLD_IN_DAYS, groupSpeechTests }
export type { EarSideGrouping, SpeechTestGroup, TestEarSideDTO, UiSpeechTestType }
