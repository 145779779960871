enum CommonProp {
  ADDENDUM = 'addendum',
  AUTHOR_GID = 'author_gid',
  CONTENT = 'content',
  CREATED_DATE = 'created_at',
  CUSTOMER_GID = 'customer_gid',
  FLAGS = 'flags',
  FORM_TYPE = 'form_type',
  HAS_COUPLING = 'has_coupling',
  ID = '_id',
  IS_COMPLETED = 'is_completed',
  META = 'meta',
  OPPORTUNITY_GID = 'opportunity_gid',
  RESPONSES = 'responses',
  RESPONSE_VALUE = 'response_value',
  QUESTION_LABEL = 'question_label',
  QUESTION_TYPE = 'question_type',
  TEMPLATE_NAME = 'template_name',
}

enum FittingProp {
  CONTENT = 'content',
  COUPLING_EARS_FITTED = 'coupling_ears_fitted',
  COUPLING_LEFT_DEVICE_SIZE = 'coupling_left_ear_device_size',
  COUPLING_LEFT_DEVICE_STYLE = 'coupling_left_ear_device_type',
  COUPLING_LEFT_DEVICE_TYPE = 'coupling_left_ear_device',
  COUPLING_LEFT_IS_CROS_DEVICE = 'coupling_left_ear_cros_device',
  COUPLING_LEFT_RECEIVER_TYPE = 'coupling_left_ear_receiver_type',
  COUPLING_LEFT_RECEIVER_LENGTH = 'coupling_left_ear_receiver_length',
  COUPLING_LEFT_RECEIVER_STRENGTH = 'coupling_left_ear_receiver_power',
  COUPLING_RIGHT_DEVICE_SIZE = 'coupling_right_ear_device_size',
  COUPLING_RIGHT_DEVICE_STYLE = 'coupling_right_ear_device_type',
  COUPLING_RIGHT_DEVICE_TYPE = 'coupling_right_ear_device',
  COUPLING_RIGHT_IS_CROS_DEVICE = 'coupling_right_ear_cros_device',
  COUPLING_RIGHT_RECEIVER_TYPE = 'coupling_right_ear_receiver_type',
  COUPLING_RIGHT_RECEIVER_LENGTH = 'coupling_right_ear_receiver_length',
  COUPLING_RIGHT_RECEIVER_STRENGTH = 'coupling_right_ear_receiver_power',
  DID_APPOINTMENT_TAKE_PLACE = 'did_appointment_happen',
  DID_COUPLING_CHANGE = 'did_coupling_change',
  FORM_TYPE = 'form_type',
  HAD_TECHNICAL_ISSUES = 'had_technical_issues',
  IS_CUSTOMER_KEEPING_DEVICES = 'is_customer_keeping_devices',
  IS_FITKIT_READY_FOR_PICKUP = 'is_fitkit_ready_for_pickup',
  IS_PDR_SIGNED = 'is_pdr_signed',
  MOBILE_OUTCOME_SETUP = 'mobile_app_setup_outcome',
  NEXT_APPOINTMENT = 'next_appointment',
  TECHNICAL_ISSUES = 'technical_issues',
  TECHNICAL_ISSUES_COMMENT = 'technical_issues_comments',
  LEFT = 'left',
  RIGHT = 'right',
  BOTH = 'both',
}

enum TechnicalIssueType {
  AUDIO = 'audio',
  COULD_NOT_SIGN_PDR = 'could_not_sign_pdr',
  FITTING_SOFTWARE = 'fitting_software',
  INTERNET_CONNECTION = 'internet_connection',
  NOAH_WIRELESS_LINK = 'noah_wireless_link',
  OTHER = 'other',
}

enum MobileAppSetupOutcomeType {
  YES_WITH_TELECARE = 'yes_with_telecare',
  YES_WITHOUT_TELECARE = 'yes_without_telecare',
  NO = 'no',
}

export { CommonProp, FittingProp, MobileAppSetupOutcomeType, TechnicalIssueType }
