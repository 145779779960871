import { RFS_URL } from 'config'
import { HttpMethod, HttpStatus } from 'constants/'
import { useCallback } from 'react'
import { InvalidateQueryFilters, QueryClient } from 'react-query'
import { applySearchParametersToUrl, hydrateRoute, isEmpty, merge, pick } from 'utils'
import { useHttpClientConfig } from '../useHttpClientConfig'

type PromisableRequestInit = Promise<Partial<RequestInit>> | Partial<RequestInit>

type TransformFunctionArgs<QueryType> = {
  query: QueryType
}

type BaseRequestFilters<QueryType> = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  filterQueryToRequestBody: (query: QueryType) => any
  filterQueryToSearchParams: (query: QueryType) => any
  filterQueryToRouteParams: (query: QueryType) => any
  getConfig: () => PromisableRequestInit
  transformResponse: (response: Response, requestInfo: TransformFunctionArgs<QueryType>) => Promise<any>
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

type RequestFilters<Q> = Partial<BaseRequestFilters<Q>>

type UseRequestOptions<Q> = RequestFilters<Q> & {
  baseUrl: URL | string
  client?: QueryClient
  getConfig?: () => PromisableRequestInit
  method?: HttpMethod
  pathname: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryFiltersToInvalidate?: InvalidateQueryFilters[]
}

function mergeDefaults<QueryType>(options: UseRequestOptions<QueryType>) {
  const defaults = {
    filterQueryToRequestBody: () => undefined,
    filterQueryToRouteParams: () => undefined,
    filterQueryToSearchParams: () => undefined,
    getConfig: () => ({}),
    transformResponse: (response: Response) => response.json(),
  }

  return merge(
    defaults,
    pick(options, [
      'filterQueryToRequestBody',
      'filterQueryToRouteParams',
      'filterQueryToSearchParams',
      'getConfig',
      'transformResponse',
    ])
  )
}

const isSuccessfulResponse = (response: Response, href: string) =>
  Boolean(response.ok || (response.status === HttpStatus.NOT_FOUND && !href.startsWith(RFS_URL)))

const useRequest = <QueryType, ResponseType>(options: UseRequestOptions<QueryType>) => {
  const { getConfig: getDefaultConfig } = useHttpClientConfig()

  const { baseUrl, client, method, pathname, queryFiltersToInvalidate } = options

  const {
    filterQueryToRequestBody,
    filterQueryToRouteParams,
    filterQueryToSearchParams,
    getConfig: getCustomConfig,
    transformResponse,
  } = mergeDefaults(options)

  const request = async (query: QueryType): Promise<ResponseType> => {
    const routeParams = filterQueryToRouteParams(query)
    const url = new URL(hydrateRoute(pathname, routeParams), baseUrl)
    const searchParams = filterQueryToSearchParams(query)
    const hydratedUrl = applySearchParametersToUrl(url, searchParams)
    const body = filterQueryToRequestBody(query)
    const [defaultConfig, customConfig] = await Promise.all([getDefaultConfig(), getCustomConfig()])
    const config = merge(defaultConfig, customConfig)

    const requestInit: RequestInit = {
      ...config,
      body: isEmpty(body) ? undefined : JSON.stringify(body),
      method: method ?? HttpMethod.GET,
    }

    const response = await fetch(hydratedUrl.href, requestInit)

    if (!isSuccessfulResponse(response, hydratedUrl.href)) {
      throw new Error(`Request failed: ${await response.text()}`)
    }

    if (client && queryFiltersToInvalidate?.length) {
      queryFiltersToInvalidate.forEach((filter) => client.invalidateQueries(filter))
    }

    const transformed = await transformResponse(response, { query })

    return transformed as ResponseType
  }

  return useCallback(request, Object.values(options))
}

export { useRequest }
