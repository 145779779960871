import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useTimeService } from '../services'

const hookName = 'useNow'
const log = logFactory(hookName)
const oneMinuteInMilliseconds = 60 * 1_000

const useNow = (options: QueryOptions = { enabled: true }) => {
  const { getNow } = useTimeService()

  const doQuery = async () => {
    log(`🕛 Getting now...`)

    const data = await getNow()

    log(`🕛 Found now`, data)

    return data
  }

  return useQuery([hookName], doQuery, {
    ...defaultQueryOptions,
    refetchInterval: oneMinuteInMilliseconds,
    refetchIntervalInBackground: true,
    ...options,
  })
}

export { useNow }
