import { blue, green, red } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px',
      },
    },
    MuiButton: {
      sizeLarge: {
        height: '48px',
      },
      containedSizeLarge: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: '#fff',
        backgroundColor: '#265da5',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1200,
      lg: 1400,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      dark: '#050921',
      light: '#265DA5',
      main: '#1d477f',
    },
    action: {
      disabledBackground: '#cdced3',
      disabled: '#717381',
    },
    secondary: {
      main: '#0C2C53',
      light: '#748AAF',
      contrastText: '#fff',
    },
    error: {
      light: '#FF3B30',
      main: red.A400,
    },
    info: {
      dark: '#282828',
      main: blue.A400,
    },
    success: {
      light: '#34C759',
      main: green[800],
    },
    warning: {
      main: '#ff9500',
    },
    grey: {
      50: '#fafafa',
      100: '#f2f2f2',
      200: '#E5E6E8',
      300: '#A3A3A3',
      400: '#717381',
      600: '#757575',
      700: '#4a4a4a',
      900: '#050921',
    },
  },
  typography: {
    h1: {
      fontSize: '1.5rem',
      fontWeight: 500,
      letterSpacing: 0,
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 400,
      letterSpacing: 0,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
    },
    h4: {
      fontSize: '0.875rem',
      fontWeight: 100,
      letterSpacing: 0,
    },
    h5: {
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: 0,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 100,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
})

const customGrey = {
  '3%': 'rgba(0,0,0, 0.03)',
  '8%': 'rgba(0,0,0, 0.08)',
}

export { customGrey, theme }
