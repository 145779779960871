import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useRemoteFittingService } from '../services'

const hookName = 'useActiveFitting'
const log = logFactory(hookName)

const useActiveFitting = (customerId?: string, options: QueryOptions = { enabled: true }) => {
  const { getActiveFitting } = useRemoteFittingService()

  const doQuery = async () => {
    const params = { customerId: customerId as string }

    log(`👕 Fetching customer active fitting...`, params)

    const fitting = await getActiveFitting(params)

    log(`👕 Returned customer active fitting...`, fitting)

    return fitting
  }

  return useQuery([hookName, customerId], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && customerId),
  })
}

export { useActiveFitting }
