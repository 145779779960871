import { SCHEDULING_UI_URL } from 'config/'

enum SearchParams {
  ACTION = 'action',
  APPOINTMENT_ID = 'id',
  UTM_SOURCE = 'utm_source',
}

const PARTNER_PORTAL_CLIENT_ID = 'partner-portal'
const REDIRECT_ACTION_RESCHEDULE = 'reschedule'
const REDIRECT_PATHNAME = '/r'
const baseUrl = SCHEDULING_UI_URL

const useAppointmentRescheduleLink = (appointmentId: string) => {
  if (!appointmentId) {
    return '#'
  }

  const url = new URL(REDIRECT_PATHNAME, baseUrl)

  url.searchParams.set(SearchParams.ACTION, REDIRECT_ACTION_RESCHEDULE)
  url.searchParams.set(SearchParams.APPOINTMENT_ID, appointmentId)
  url.searchParams.set(SearchParams.UTM_SOURCE, PARTNER_PORTAL_CLIENT_ID)

  return url.href
}

export { useAppointmentRescheduleLink }
