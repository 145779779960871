import { sortBy } from 'remeda'
import { Opportunity, SalesStatus } from 'types'

/**
 * Possibly still error-prone
 * more robust than only using createdAt or updatedAt date to determine actual latest opportunity
 * best option is to add a way to flag the opportunity as the active/latest one in data.
 * */

type SortRule = 'asc' | 'desc'

const findMostLikelyLatestOpportunity = (opportunity: Opportunity) =>
  Boolean(opportunity.trialEndDate) || Boolean(opportunity.salesStatus.status === SalesStatus.WON)

const toOriginalSort = (opportunities: Opportunity[], originalSort: SortRule = 'asc') =>
  sortBy(opportunities, [(opportunity: Opportunity) => new Date(opportunity.trialEndDate), originalSort])

const findLatestOpportunities = (opportunities: Opportunity[], shouldFilter = true, originalSort?: SortRule) => {
  const sorted = sortBy(opportunities, [(opportunity: Opportunity) => new Date(opportunity.updatedAt), 'desc'])

  if (!shouldFilter) return toOriginalSort(sorted, originalSort)

  const output = sorted.filter(findMostLikelyLatestOpportunity)

  return output.length ? output : sorted // We need to not change the original sorting
}

export { findLatestOpportunities }
