import { useCallback } from 'react'
import { Query, Shipment } from 'types'
import { identity } from 'utils'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'

const defaultPagination = {
  page: 0,
  page_size: 100,
}

enum ApiPathname {
  SHIPMENTS = '/api/v2/shipments',
}

type GetShipmentsQuery = Query<{
  ids?: string[]
}>

type QueryResponse<T> = {
  data: T
  meta: {
    item_total: number
    page: number
    page_size: number
    pages_total: number
  }
}

type GetShipmentsResponse = QueryResponse<Shipment[]>

interface UseHookContext {
  getShipments: (query: GetShipmentsQuery) => Promise<GetShipmentsResponse>
}

const useShippingService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('shipping')

  const getShipmentsRequest = useRequest<GetShipmentsQuery, GetShipmentsResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.SHIPMENTS,
  })

  const getShipments = useCallback(
    (query: GetShipmentsQuery) =>
      getShipmentsRequest({
        ...defaultPagination,
        ...query,
      }),
    [getShipmentsRequest]
  )

  return { getShipments }
}

export { useShippingService }
