import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import type { DownloadableDocument, QueryOptions, RfsDocument } from 'types'
import { logFactory } from 'utils'
import { useRemoteFittingService } from '../services'

interface UseDownloadableDocumentsProps {
  documents?: RfsDocument[]
  fittingId?: string
}

const hookName = 'useRfsDownloadableDocuments'
const log = logFactory(hookName)
const base64RegEx = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/

const onlyBase64Documents = (document: DownloadableDocument) => base64RegEx.test(document.contents)

const useRfsDownloadableDocuments = (
  { documents, fittingId }: UseDownloadableDocumentsProps,
  options: QueryOptions = { enabled: true, refetchOnMount: false }
) => {
  const { getDownloadableDocument } = useRemoteFittingService()

  const fetchDocuments = async () => {
    const documentsWithIds = documents?.filter(({ documentId }) => Boolean(documentId))

    const params = { documents: documentsWithIds, fittingId }

    log(`📂 Fetching RFS downloadable documents...`, params)

    const data = (
      await Promise.all(
        // @ts-expect-error we are certain that documents is an array here
        documentsWithIds.map((document) =>
          getDownloadableDocument({
            document,
            fittingId: fittingId as string,
          }).catch(() => undefined)
        )
      )
    ).filter(Boolean) as DownloadableDocument[]

    log(`📂 Found ${data.length} RFS downloadable documents`, data)

    const base64Documents = data.filter(onlyBase64Documents)

    log(`📂 Filtered to ${base64Documents.length} valid base64 RFS downloadable documents`, data)

    return base64Documents
  }

  return useQuery([hookName, fittingId], fetchDocuments, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(fittingId && documents?.length),
  })
}

export { useRfsDownloadableDocuments }
