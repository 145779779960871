import { lazy } from 'react'
import { namedExportToDefaultExport } from 'utils'

const componentName = 'Notifications'

const Notifications = lazy(() =>
  import(
    /* webpackChunkName: "Notifications" */
    'components/Notifications'
  ).then(namedExportToDefaultExport(componentName))
)

export { Notifications }
