import { ConsentDocumentType, DocumentType } from 'constants/'
import { useLocale } from 'hooks'
import { identity } from 'utils'
import { useSelectedCountry } from '../../useSelectedCountry'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'
import { ConsentDocument, Document, DownloadableDocument } from './types'

const ApiPathname = {
  CONSENTS: '/api/v1/consent',
  DOCUMENTS: '/api/v1/document',
  DOWNLOADABLE_DOCUMENT: '/api/v1/document/:id/:version/file',
} as const

type GetConsentDocumentQuery = {
  customerSalesforceId: string
  type: ConsentDocumentType
}

type GetConsentDocumentResponseError = {
  error_code: string
  error_message: string
  type: 'error'
}

type GetConsentDocumentResponse =
  | {
      consent: ConsentDocument
      type: 'success'
    }
  | GetConsentDocumentResponseError

type GetDocumentsQuery = {
  customerSalesforceId?: string[]
  isSigned?: boolean[]
  opportunitySalesforceId?: string[]
  type?: DocumentType[]
}

type GetDocumentsResponse = {
  documents: Document[]
  status: LooseAutocomplete<'success' | 'error'>
}

type GetDownloadableDocumentQuery = {
  id: string
  version: string
}

type GetDownloadableDocumentResponse = {
  fileBase64: string
  id: string
  status: LooseAutocomplete<'success' | 'error'>
}

interface UseHookContext {
  getConsentDocument: (query: GetConsentDocumentQuery) => Promise<GetConsentDocumentResponse>
  getDownloadableDocument: (query: GetDownloadableDocumentQuery) => Promise<GetDownloadableDocumentResponse>
  getDocuments: (query: GetDocumentsQuery) => Promise<GetDocumentsResponse>
}

const useDocumentService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('document')
  const { selectedCountry } = useSelectedCountry()
  const locale = useLocale()
  const language = locale.split('-')[0]

  const getDocuments = useRequest<GetDocumentsQuery, GetDocumentsResponse>({
    baseUrl,
    filterQueryToSearchParams: ({
      customerSalesforceId: customer_id,
      isSigned: is_signed,
      opportunitySalesforceId: opportunity_id,
      ...query
    }) => ({
      ...query,
      country_id: selectedCountry,
      customer_id,
      is_signed,
      opportunity_id,
    }),
    pathname: ApiPathname.DOCUMENTS,
  })

  const getConsentDocument = useRequest<GetConsentDocumentQuery, GetConsentDocumentResponse>({
    baseUrl,
    filterQueryToSearchParams: ({ customerSalesforceId: customer_id, ...query }) => ({
      ...query,
      country_id: selectedCountry,
      customer_id,
      language,
    }),
    pathname: ApiPathname.CONSENTS,
    transformResponse: async (response) => {
      const consentDocumentOrError = await response.json()

      if (consentDocumentOrError.error_code) {
        return {
          error_code: consentDocumentOrError.error_code,
          error_message: consentDocumentOrError.error_message,
          type: 'error',
        } as GetConsentDocumentResponseError
      }

      return {
        consent: consentDocumentOrError as ConsentDocument,
        type: 'success',
      }
    },
  })

  const getDownloadableDocument = useRequest<GetDownloadableDocumentQuery, GetDownloadableDocumentResponse>({
    baseUrl,
    filterQueryToRouteParams: identity,
    pathname: ApiPathname.DOWNLOADABLE_DOCUMENT,
    transformResponse: async (response, { query }) => ({
      ...(await response.json()),
      id: query.id,
    }),
  })

  return { getConsentDocument, getDocuments, getDownloadableDocument }
}

export { useDocumentService }
export type { ConsentDocument, Document, DownloadableDocument, GetConsentDocumentResponseError }
