import { JourneyStatus } from 'types/'

enum RelatedEntityType {
  APPOINTMENT = 'APPOINTMENT',
  DOCUMENT = 'DOCUMENT',
  SALES_ORDER = 'SALES_ORDER',
}

type RelatedEntity = {
  id: string
  type: RelatedEntityType
}

enum AccountType {
  MIGRATION = 'MIGRATION',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

enum JourneyActivityType {
  DEVICES_DELIVERED = 'DEVICES_DELIVERED',
  DEVICES_ORDERED = 'DEVICES_ORDERED',
  FIRST_FOLLOW_UP_COMPLETED = 'FIRST_FOLLOW_UP_COMPLETED',
  FOLLOW_UP_SCHEDULED = 'FOLLOW_UP_SCHEDULED',
  HEARING_AIDS_FITTED = 'HEARING_AIDS_FITTED',
  HEARING_AIDS_WORN_30_HOURS = 'HEARING_AIDS_WORN_30_HOURS',
  HEARING_AIDS_WORN_60_HOURS = 'HEARING_AIDS_WORN_60_HOURS',
  HYPE_CALL_COMPLETED = 'HYPE_CALL_COMPLETED',
  INITIAL_APPOINTMENT_SCHEDULED = 'INITIAL_APPOINTMENT_SCHEDULED',
  INSURANCE_CHECKED = 'INSURANCE_CHECKED',
  PAYMENT_COLLECTED = 'PAYMENT_COLLECTED',
  PAYMENT_PREPARED = 'PAYMENT_PREPARED',
  PDR_SIGNED = 'PDR_SIGNED',
  PRICING_FINALIZED = 'PRICING_FINALIZED',
  TECH_SETUP_COMPLETED = 'TECH_SETUP_COMPLETED',
}

enum System {
  APPOINTMENT_SERVICE = 'APPOINTMENT_SERVICE',
  DOCUMENT_SERVICE = 'DOCUMENT_SERVICE',
  OPPORTUNITY_SERVICE_JOB_CLOSE_ENDED_OPPORTUNITIES = 'OPPORTUNITY_SERVICE_JOB_CLOSE_ENDED_OPPORTUNITIES',
  PULSAR = 'PULSAR',
  SALESFORCE = 'SALESFORCE',
}

type SystemAccount = {
  system: System
  type: AccountType.SYSTEM | AccountType.MIGRATION
}

type UserAccount = {
  email?: string
  id?: string
  type: AccountType.USER
}

type Account = SystemAccount | UserAccount

interface JourneyActivity {
  completedAt?: Date | null
  completedBy?: Account | null
  completedInJourneyStatus?: JourneyStatus | null
  createdAt: Date
  createdBy: Account
  expectedJourneyStatus: JourneyStatus
  id: string
  isCompleted: boolean
  opportunityId: string
  relatedEntities?: RelatedEntity[] | null
  type: JourneyActivityType
  updatedAt: Date
  updatedBy: Account
}

export { AccountType, JourneyActivityType, System }
export type { JourneyActivity }
