import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { AppointmentResourceType as TranslationKey } from 'constants/'
import { makeTranslationProxy } from '../util'

const useProviderTypeTranslation = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      CABIN: (options) => t('provider_type.CABIN', options),
      CONSULTANT: (options) => t('provider_type.CONSULTANT', options),
      FITTER: (options) => t('provider_type.FITTER', options),
      FITTER_INTERNAL: (options) => t('provider_type.FITTER_INTERNAL', options),
      VIDEO_CARE_CALL_SPECIALIST: (options) => t('provider_type.VIDEO_CARE_CALL_SPECIALIST', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useProviderTypeTranslation }
