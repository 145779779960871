import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip as MuiTooltip,
  SvgIconTypeMap,
  styled,
} from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { Group, Home } from '@material-ui/icons'
import { Route } from 'constants/'
import { useFeatureFlags, useKeyBindings, useUserSettings } from 'hooks'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Link } from '../../../Link'
import { Button } from './Button'
import { useListIconStyles, useListItemStyles, useListItemTextStyles, useListStyles, useStyles } from './index.styles'

interface TopLevelRoute {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>
  name: string
  route: Route
}

interface ConditionalTooltipProps {
  children: ReactElement
  hasTooltip?: boolean
  title: string
}

const drawerWidth = '12rem'

const Container = styled('div')(() => ({
  position: 'sticky',
  top: '5.75rem',
  zIndex: 1_000,

  /* eslint-disable sort-keys */
  '&:hover > button': {
    display: 'flex',
    opacity: 1,
  },

  '&:focus-within > button': {
    display: 'flex',
    opacity: 1,
  },
  /* eslint-enable sort-keys */
}))

// First match found is considered active
const activeRouteOrder: Route[] = [Route.CUSTOMERS, Route.HOME]

const ConditionalTooltip = ({ children, hasTooltip, title }: ConditionalTooltipProps) => {
  if (!hasTooltip) {
    return <>{children}</>
  }

  return (
    <MuiTooltip arrow enterDelay={1_000} placement="right" title={title}>
      {children}
    </MuiTooltip>
  )
}

const Sidebar = () => {
  const { read: readUserSetting, write: writeUserSetting } = useUserSettings()
  const [isOpen, beOpen] = useState(Boolean(readUserSetting('isSidebarOpen')))
  const width = isOpen ? drawerWidth : '3.5rem'
  const classes = useStyles({ width })
  const { t } = useTranslation()
  const listClasses = useListStyles()
  const listItemClasses = useListItemStyles()
  const listIconClasses = useListIconStyles()
  const listItemTextClasses = useListItemTextStyles({ isOpen })
  const location = useLocation()

  const [isNewCustomerProgressBarFlagEnabled] = useFeatureFlags(['PROGRESS_BAR_V2'])
  const customersTitle = isNewCustomerProgressBarFlagEnabled
    ? t('general.navigation.links.active_trials')
    : t('general.navigation.links.customer_search')

  // This is the display order
  const topLevelRoutes: TopLevelRoute[] = [
    { icon: Home, name: t('general.navigation.links.home'), route: Route.HOME },
    { icon: Group, name: customersTitle, route: Route.CUSTOMERS },
  ]
  const onToggleDrawer = () => {
    const newIsOpen = !isOpen

    beOpen(newIsOpen)
    writeUserSetting('isSidebarOpen', newIsOpen)
  }

  useKeyBindings({
    '{': onToggleDrawer,
  })

  return (
    <Container>
      <Button isOpen={isOpen} onClose={onToggleDrawer} onOpen={onToggleDrawer} />
      <Drawer classes={classes} open={isOpen} variant="permanent">
        <List classes={listClasses}>
          {topLevelRoutes.map(({ icon: Icon, name, route }) => {
            const activeRoute = activeRouteOrder.find((route) => location.pathname.toLowerCase().startsWith(route))
            const isActive = activeRoute === route

            return (
              <ListItem aria-selected={isActive} classes={listItemClasses} component={Link} key={route} to={route}>
                <ConditionalTooltip hasTooltip={!isOpen} title={name}>
                  <ListItemIcon classes={listIconClasses}>
                    <Icon />
                  </ListItemIcon>
                </ConditionalTooltip>
                <ListItemText classes={listItemTextClasses} primary={name} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    </Container>
  )
}

export { Sidebar }
