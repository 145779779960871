interface Customerish {
  firstname?: string
  lastname?: string
  givenName?: string
  familyName?: string
  firstName?: string
  lastName?: string
  salutation?: string
}

const customerToDisplayName = (customer?: Customerish): string => {
  if (!customer) {
    return ``
  }

  const { firstname, lastname, givenName = firstname, familyName = lastname, salutation } = customer

  if (salutation) {
    return `${familyName}, ${givenName} (${salutation})`
  }

  return `${familyName}, ${givenName}`
}

export { customerToDisplayName }
