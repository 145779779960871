import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    menu: {
      borderRadius: '0.25rem',
      boxShadow:
        '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      color: '#000000de',
      display: 'grid',
      lineHeight: '1.5rem',
      padding: '1rem 0',
      rowGap: '1rem',

      '& > * [class*="--active"]:not[aria-disabled]': {
        background: '#dedede',
        color: '#000000de',
      },
    },
    subMenu: {
      '& > .szh-menu__item--active': {
        background: '#dedede',
        color: '#000000de',
      },
    },
    item: {
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
      columnGap: '0.5rem',
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1.5rem 1fr',

      '&:[aria-disabled]': {
        color: '#000000de',
      },
    },
    linkItem: {
      display: 'grid',
      padding: '0.375rem 1.5rem',
      width: '100%',
    },
    dropdownButton: {
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
      boxShadow: '0',
      cursor: 'pointer',
      color: theme.palette.common.white,
      display: 'flex',
    },
    dropdownButtonText: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      paddingLeft: '0.5rem',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    flag: {
      paddingLeft: '0.5rem',
    },
    hidden: {
      display: 'none',
    },
  })
) as unknown as UseStylesFunction

export { useStyles }
