/* eslint-disable @typescript-eslint/no-explicit-any */
import { LogTypes } from '@audibene/ta-browser-tracker'
import { Environment } from 'constants/'
import { Component, ReactNode } from 'react'
import { logRocket } from 'startup'
import { logFactory } from 'utils'

interface State {
  error?: any
  location: string
}

interface Props {
  children: ReactNode
}

const log = logFactory('ErrorBoundary')
const isProduction = process.env.NODE_ENV === Environment.PRODUCTION

const styles = {
  display: 'grid',
  placeContent: 'center',
  placeItems: 'center',
}

class ErrorBoundary extends Component {
  constructor(props: Props) {
    super(props)
  }

  state: State = {
    error: undefined,
    location: window.location.href,
  }

  componentDidCatch(error: any) {
    try {
      log('💥 Sending error to LogRocket...')
      console.error('Error sent to LogRocket:', error)
      logRocket?.log(LogTypes.ERROR, error.message, { error })
      log('💥 Successfully sent error to LogRocket')
    } catch (e) {
      console.error('Failed to send error to LogRocket:', e)
    } finally {
      this.setState({ error })
    }
  }

  componentDidUpdate() {
    if (this.state.error && window.location.href !== this.state.location) {
      // On location change clear our error
      this.setState({ error: false })
    }
  }

  render() {
    const { error } = this.state

    if (error) {
      return (
        <div style={styles}>
          <h1 style={{ marginBottom: '1rem' }}>{`We're sorry, but something went wrong`}</h1>
          <h2 style={{ marginBottom: '1rem' }}>¯\_(ツ)_/¯</h2>
          <p>{`This error has been logged and our team has been notified.`}</p>
          <a href={window.location.href}>Refresh the page and try again</a>
          {!isProduction && (
            <pre
              style={{
                background: '#ff00000d',
                border: '1px solid #b72b2b',
                borderRadius: '0.125rem',
                color: '#9f2929',
                fontFamily: 'Courier New, sans-serif',
                margin: '1.5rem 0 0 0',
                maxWidth: 'calc(100% - 4rem)',
                overflowX: 'auto',
                padding: '0.5rem',
                width: '100%',
                wordBreak: 'break-all',
              }}
            >
              {error?.stack.toString()}
            </pre>
          )}
        </div>
      )
    }

    // eslint-disable-next-line react/prop-types
    const { children } = this.props

    return children
  }
}

export { ErrorBoundary }
