import { CircularProgress } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { Route } from 'constants/'
import { useCustomerOpportunitySearch, useKeyBindings, useSelectedCountry } from 'hooks'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { CustomerOpportunityDTO, SalesStatus } from 'types'
import { clamp, customerV2ToDisplayName, hydrateRoute, take } from 'utils'
import { TextInput } from '../../../forms'
import { Result } from './Result'
import { Results } from './Results'
import { useStyles } from './index.styles'
import type { SearchResult } from './types'

type Option = SearchResult

const MAX_SEARCH_RESULTS = 5
const MINIMUM_SEARCH_STRING_LENGTH = 2

const customerToOption = (data: CustomerOpportunityDTO): Option => ({
  adminOnly: !data.opportunity.trialEndDate && data.opportunity.salesStatus.status !== SalesStatus.WON,
  customer: data.customer,
  id: data.customer.id,
  label: customerV2ToDisplayName(data.customer),
  opportunity: data.opportunity,
})

const optionToLabel = ({ label }: Option) => label
const INPUT_ID = 'customer-search'

const Search = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [value, setValue] = useState<Option | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [search, setSearch] = useState('')
  const [tempSearch, setTempSearch] = useState('')
  const [isOpen, beOpen] = useState(false)
  const [options, setOptions] = useState<Option[]>([])
  const inputRef = useRef<HTMLInputElement>()
  const { selectedCountry } = useSelectedCountry()

  const isSearchQueryEnabled = Boolean(search)

  const {
    data: customers,
    isFetched,
    isFetching: isSearching,
  } = useCustomerOpportunitySearch(
    { countryCode: selectedCountry, page_size: MAX_SEARCH_RESULTS + 1, search, sort: 'DESC', sortBy: 'createdAt' },
    isSearchQueryEnabled
  )

  // @ts-expect-error Safe nav to input element
  const onKeyboardShortcutSearchFocus = () => inputRef.current?.querySelector(`#${INPUT_ID}`)?.focus()

  useKeyBindings({
    'meta+/': onKeyboardShortcutSearchFocus,
  })

  const hasCustomers = Boolean(customers?.length)
  const additionalCharactersNeeded = clamp(MINIMUM_SEARCH_STRING_LENGTH - inputValue.length, { min: 0 })

  const isLoading =
    isOpen &&
    ((isSearchQueryEnabled && (isSearching || !isFetched)) || (additionalCharactersNeeded === 0 && !isFetched))

  const onClose = () => {
    beOpen(false)
    setSearch('')
    setTempSearch('')
  }

  const onOpen = () => beOpen(true)

  const onChange = (_event: ChangeEvent<Record<string, unknown>>, value: Option | null) => {
    setValue(value)

    if (!value) {
      return
    }

    setValue(null)
    setInputValue('')

    navigate(
      hydrateRoute(Route.CUSTOMER_PROFILE, {
        customerId: (value as Option).customer.id,
      })
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSearchChange = (_event: any, value: string) => {
    const normalized = (value || '').trim()

    if (normalized.length >= MINIMUM_SEARCH_STRING_LENGTH) {
      setTempSearch(normalized)
    } else {
      setSearch('')
      setTempSearch('')
    }

    setInputValue(value)
  }

  useEffect(() => {
    if (!tempSearch) {
      setSearch('')

      return
    }

    const timeout = setTimeout(() => setSearch(tempSearch), 750)

    return () => clearTimeout(timeout)
  }, [setSearch, tempSearch])

  useEffect(() => {
    if (!customers) {
      setOptions([])

      return
    }

    const customerOptions = take(customers.map(customerToOption), MAX_SEARCH_RESULTS)

    setOptions(customerOptions)
  }, [hasCustomers])

  const renderOption = (result: Option) => <Result adminOnly={result.adminOnly} classes={classes} result={result} />

  let noOptionsText = ''

  switch (true) {
    case inputValue.length === 0:
      noOptionsText = t('general.search.enter_name_or_email_to_search')
      break
    case additionalCharactersNeeded > 0:
      noOptionsText = t('general.search.additional_characters', { count: additionalCharactersNeeded })
      break
    case isLoading:
      noOptionsText = t('general.search.loading_text')
      break
    case options.length === 0 && isFetched:
      noOptionsText = t('general.search.no_results_found')
      break
    default:
      break
  }

  const Container = (props: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => (
    <Results {...props} customers={customers ?? []} maxResults={MAX_SEARCH_RESULTS} search={search} />
  )

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextInput
      {...params}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
        startAdornment: <SearchIcon />,
      }}
      placeholder={t('general.search.placeholder')}
      type="search"
      variant="outlined"
    />
  )

  return (
    <Autocomplete
      PaperComponent={Container}
      autoHighlight
      className={classes.root}
      filterOptions={(x) => x}
      getOptionLabel={optionToLabel}
      id={INPUT_ID}
      inputValue={inputValue}
      loading={isLoading}
      loadingText={t('general.search.loading_text')}
      noOptionsText={noOptionsText}
      onChange={onChange}
      onClose={onClose}
      onInputChange={onSearchChange}
      onOpen={onOpen}
      open={isOpen}
      options={options}
      ref={inputRef}
      renderInput={renderInput}
      renderOption={renderOption}
      value={value}
    />
  )
}

export { Search }
