import { createStyles, makeStyles } from '@material-ui/core/styles'

const useGlobalOverrides = makeStyles(() =>
  createStyles({
    '@global': {
      '#root': {
        gridTemplateAreas: "'header' 'main'",
        gridTemplateColumns: 'auto',
        padding: '1rem',
      },
      main: {
        padding: '1rem !important',
      },
    },
  })
)

const GlobalStyleOverrides = () => {
  useGlobalOverrides()

  return null
}

export { GlobalStyleOverrides }
