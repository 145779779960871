import { ENVIRONMENT, LOGROCKET_PROJECT_ID } from 'config'
import { Environment } from 'constants/'
import { useUser } from 'hooks'
import { FC, useEffect } from 'react'
import { logRocket } from 'startup'
import { logFactory } from 'utils'

const liveEnvironments = [Environment.DEVELOPMENT, Environment.PRODUCTION, Environment.STAGING, Environment.TESTING]

const isLiveEnvironment = liveEnvironments.includes(ENVIRONMENT as Environment) && Boolean(LOGROCKET_PROJECT_ID)

const log = logFactory('LogRocketProvider')

let isInitializing = false
let isInitialized = false

const LogRocketProvider: FC = ({ children }) => {
  const user = useUser()

  useEffect(() => {
    if (!user?.accountId || !isLiveEnvironment || isInitialized || isInitializing) return

    isInitializing = true

    const extraInfo = {
      email: user.email as string,
      environment: ENVIRONMENT,
      name: user.name as string,
    }

    const sessionInfo = { extraInfo, id: user.accountId as string }

    try {
      log('🚀 Setting LogRocket sessionId...', sessionInfo)
      logRocket?.setSessionId(sessionInfo)
      log('🚀 LogRocket sessionId set')
      isInitialized = true
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('🟥 Could not establish LogRocket session for the current user', error)
    } finally {
      isInitializing = false
    }
  }, [user])

  return <>{children}</>
}

export { LogRocketProvider }
