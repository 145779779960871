import { ReactNode, createContext, useContext } from 'react'

interface Context {
  hideEmpty: boolean
}

interface ProviderProps {
  hideEmpty?: boolean
  children: ReactNode
}

const context = createContext<Context>({ hideEmpty: false })

const { Provider } = context

const DeviceDisplayConfigurationProvider = ({ children, hideEmpty = false }: ProviderProps) => {
  const contextValue: Context = {
    hideEmpty,
  }

  return <Provider value={contextValue}>{children}</Provider>
}

const useDeviceDisplayConfiguration = () => useContext(context)

export { DeviceDisplayConfigurationProvider, useDeviceDisplayConfiguration }
