import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { findLatestOpportunities, logFactory } from 'utils'
import { useOpportunityService } from '../services'

const hookName = 'useLatestOpportunity'
const log = logFactory(hookName)

const useLatestOpportunity = (customerGid?: string, options: QueryOptions = { enabled: Boolean(customerGid) }) => {
  const { getLatestOpportunities } = useOpportunityService()

  const doQuery = async () => {
    const params = {
      customerSalesforceId: [customerGid as string],
    }

    log(`🕰️ Fetching latest opportunities...`, params)

    const { data } = await getLatestOpportunities(params)

    const [found] = findLatestOpportunities(data)

    log(`🕰️ Found opportunities`, found)
    return found
  }

  return useQuery([hookName, customerGid], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useLatestOpportunity }
