import { ReactNode, createContext, useContext } from 'react'
import { Opportunity } from 'types'

interface Context {
  opportunity?: Opportunity
}

interface Props {
  children: ReactNode
  opportunity?: Opportunity
}

const context = createContext<Context>({ opportunity: {} as Opportunity })

const { Provider } = context

const OpportunityProvider = ({ children, opportunity }: Props) => (
  <Provider value={{ opportunity }}>{children}</Provider>
)

const useOpportunity = () => useContext(context)

export { OpportunityProvider, useOpportunity }
