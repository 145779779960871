import { ACCOUNT_SERVICE_URL } from 'config'
import { defaultPagination, HttpMethod } from 'constants/'
import { useCallback } from 'react'
import { Account, FitterAccount, Query, QueryResponse } from 'types'
import { identity, pick, prop } from 'utils'
import { useRequest } from '../useRequest'

enum ApiPathname {
  ACCOUNTS = '/accounts',
  ACCOUNT = '/accounts/:accountId',
  ACCOUNT_SEARCH = '/accounts/search',
  MY_ACCOUNT = '/accounts/me',
}

type GetAccountQuery = { accountId?: string; id?: string[] }

type GetMyAccountQuery = undefined
type GetMyAccountResponse = FitterAccount

type SearchAccountsQuery = Query<{
  accountGids?: string[]
  id?: string[]
  onlySearchCustomers?: boolean
  searchTerm?: string
}>

type SearchAccountsQueryAll = Omit<SearchAccountsQuery, 'searchTerm'> & {
  q?: string
  type?: string
}

type SearchAccountsResponse = QueryResponse<Account[]>

interface UseHookContext {
  getMyAccount: (query: GetMyAccountQuery) => Promise<GetMyAccountResponse>
  searchAccounts: (query: SearchAccountsQuery) => Promise<SearchAccountsResponse>
  getAccounts: (query: GetAccountQuery) => Promise<SearchAccountsResponse>
}

const baseUrl = new URL(ACCOUNT_SERVICE_URL)
const accountSearchCustomerOnlyType = 'customer'

const useAccountService = (): UseHookContext => {
  const getAccountRequest = useRequest<GetAccountQuery, SearchAccountsResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.ACCOUNTS,
  })

  const getMyAccount = useRequest<GetMyAccountQuery, GetMyAccountResponse>({
    baseUrl,
    pathname: ApiPathname.MY_ACCOUNT,
  })

  const searchAccountsRequest = useRequest<SearchAccountsQueryAll, SearchAccountsResponse>({
    baseUrl,
    filterQueryToRequestBody: prop('accountGids'),
    filterQueryToSearchParams: pick(['id']),
    method: HttpMethod.POST,
    pathname: ApiPathname.ACCOUNT_SEARCH,
  })

  const searchAccounts = useCallback(
    (query: SearchAccountsQuery) => {
      const { onlySearchCustomers, searchTerm: q, ...rest } = query

      const typeParams = onlySearchCustomers === false ? {} : { type: accountSearchCustomerOnlyType }

      return searchAccountsRequest({
        ...defaultPagination,
        ...rest,
        q,
        ...typeParams,
      })
    },
    [searchAccountsRequest]
  )
  const getAccounts = useCallback(
    (query: GetAccountQuery) => {
      return getAccountRequest({
        ...defaultPagination,
        ...query,
      })
    },
    [getAccountRequest]
  )

  return { getAccounts, getMyAccount, searchAccounts }
}

export { useAccountService }
