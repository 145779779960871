import { RoleFlagsMap, useMyAccount, useOpportunities, useUser } from 'hooks'
import { useMemo } from 'react'
import { CustomerOpportunityDTO, PartnerPortalDeliveryModel } from 'types'
import { arrayToMapByPropName, take } from 'utils'
import { CustomerSearchQuery } from '../services'
import type { Customer } from '../services/useCustomersService/types'
import { useCustomerSearch } from '../useCustomerSearch'

interface UseHookContext {
  data?: CustomerOpportunityDTO[]
  hasMore: boolean
  isError: boolean
  isFetched: boolean
  isFetching: boolean
  isLoading: boolean
  maxResultLimit: number
}

const UI_MAX_RESULTS_DISPLAY = 25

const useCustomerOpportunitySearch = (query: CustomerSearchQuery, isEnabled = true): UseHookContext => {
  const pageSize = query.page_size ?? UI_MAX_RESULTS_DISPLAY
  const customersQuery = {
    ...query,
    page_size: pageSize,
  }
  const { data: fitter } = useMyAccount()
  const { roleFlags } = useUser()

  const { isAdmin, isEmployeeFitter, isOperations } = roleFlags as RoleFlagsMap
  const canSearchAllOpportunities = Boolean(isAdmin || isEmployeeFitter || isOperations)
  const fitterSalesforceId = canSearchAllOpportunities ? undefined : ([fitter?.gid] as string[])

  const {
    data: customersResponse,
    isError: haveAccountsErrored,
    isFetched: haveAccountsFetched,
    isFetching: areAccountsFetching,
    isLoading: areAccountsLoading,
  } = useCustomerSearch(customersQuery, { enabled: isEnabled })

  const customers = customersResponse?.data

  const opportunityServiceQuery = {
    customerSalesforceId: customers?.map((customers) => customers.salesforceId)?.filter(Boolean) ?? [],
    deliveryModel: [PartnerPortalDeliveryModel.TELEAUDIOLOGY],
    fitterSalesforceId,
  }

  const isOpportunityServiceQueryEnabled = Boolean(!areAccountsLoading && customers?.length && isEnabled)

  const {
    data: opportunityServiceResponse,
    isError: hasOpportunityServiceErrored,
    isFetched: hasOpportunityServiceFetched,
    isFetching: isOpportunityServiceFetching,
    isLoading: isOpportunityServiceLoading,
  } = useOpportunities(opportunityServiceQuery, { enabled: isOpportunityServiceQueryEnabled })

  const isError = haveAccountsErrored || hasOpportunityServiceErrored
  const isFetched = haveAccountsFetched || hasOpportunityServiceFetched
  const isFetching = areAccountsFetching || isOpportunityServiceFetching
  const isLoading = areAccountsLoading || isOpportunityServiceLoading

  const opportunityServiceOpportunities = opportunityServiceResponse?.data ?? []
  const opportunityServiceMapByCustomerGid = arrayToMapByPropName(
    opportunityServiceOpportunities,
    'customerSalesforceId'
  )

  const data = useMemo(() => {
    const filtered =
      customers
        ?.filter((customer) => opportunityServiceMapByCustomerGid[customer.salesforceId])
        .map<CustomerOpportunityDTO>((customer: Customer) => {
          return {
            customer: customer,
            opportunity: opportunityServiceMapByCustomerGid[customer.salesforceId],
          }
        }) ?? ([] as CustomerOpportunityDTO[])

    const results = take(filtered, pageSize)
    const hasMore = filtered.length > pageSize

    return { hasMore, results }
  }, [customers, opportunityServiceMapByCustomerGid])

  return {
    data: data.results,
    hasMore: data.hasMore,
    isError,
    isFetched,
    isFetching,
    isLoading,
    maxResultLimit: pageSize,
  }
}

export { useCustomerOpportunitySearch }
