import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useRemoteFittingService } from '../services'

const hookName = 'useFittings'
const log = logFactory(hookName)

const useFittings = (customerId?: string, options: QueryOptions = { enabled: true }) => {
  const { getFittings } = useRemoteFittingService()

  const doQuery = async () => {
    const params = { accountId: customerId as string }

    log(`👗 Fetching fittings...`, params)

    const fittings = await getFittings(params)

    log(`👗 Returned ${fittings.length} fittings...`, fittings)

    return fittings
  }

  return useQuery([hookName, customerId], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && customerId),
  })
}

export { useFittings }
