import { useCallback } from 'react'

type GetNowQuery = undefined
type GetNowResponse = Date

interface UseHookContext {
  getNow: (query?: GetNowQuery) => Promise<GetNowResponse>
}

const useTimeService = (): UseHookContext => {
  const getNow = useCallback(() => Promise.resolve(new Date()), [])

  return { getNow }
}

export { useTimeService }
