import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      color: '#ffffff',
      padding: '0.75rem',
    },
  })
) as unknown as UseStylesFunction
