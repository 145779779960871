import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetShopsQuery, useAppointmentsService } from '../services'

const hookName = 'useShops'
const log = logFactory(hookName)

const useShops = (params: GetShopsQuery, options: QueryOptions = { enabled: true }) => {
  const { getShops } = useAppointmentsService()

  const doQuery = async () => {
    log(`🏛️ Fetching shops...`, params)

    const { data } = await getShops(params)

    log(`🏛️ Returned shops...`, data)

    return data
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && Object.keys(params).length),
  })
}

export { useShops }
