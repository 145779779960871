import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '20vw 1fr 20vw',
      height: '100%',
      justifyContent: 'space-between',
      padding: '0 2rem',
    },
    appBar: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary.dark,
      display: 'grid',
      gridArea: 'header',
      maxWidth: '100%',
      minHeight: '5.25rem',
      position: 'sticky',
      top: 0,
    },
    left: {
      alignItems: 'center',
      display: 'grid',
      height: '100%',
    },
    title: {
      margin: 0,
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    centered: {
      display: 'grid',
      height: '100%',
      justifyContent: 'center',
      placeItems: 'center',
    },
    userItems: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'end',
      gap: '0.5rem',
      overflowX: 'visible',
      whiteSpace: 'nowrap',
    },
  })
) as unknown as UseStylesFunction
