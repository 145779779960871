import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { TimelineAction as TranslationKey } from 'constants/'
import { makeTranslationProxy } from '../util'

const useTimelineActionTranslation = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      ADD: (options) => t('timeline_actions.ADD', options),
      ADD_APPOINTMENT: (options) => t('timeline_actions.ADD_APPOINTMENT', options),
      EDIT: (options) => t('timeline_actions.EDIT', options),
      EDIT_APPOINTMENT: (options) => t('timeline_actions.EDIT_APPOINTMENT', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useTimelineActionTranslation }
