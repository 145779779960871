import { CommonProp } from 'constants/'
import { NoteResponse } from 'types'
import { makeReadResponseValue } from '../util'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toSimpleNote = (note: NoteResponse): Record<string, any> => {
  const read = makeReadResponseValue(note)

  return {
    content: read(CommonProp.CONTENT),
  }
}

export { toSimpleNote }
