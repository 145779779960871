import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  nav: {
    gridArea: 'nav',
    padding: '0.5rem 0 0.5rem 0.675rem',
  },
  main: {
    gridArea: 'main',
    margin: '0 1rem 0.5rem',
    maxWidth: '100%',
    padding: '0.5rem 0 0 0',
  },
})

export { useStyles }
