import { CommonProp, FittingProp, NoteTemplateName, NoteType } from 'constants/'
import { AnyNote, NoteResponse } from 'types'
import { toFittingNoteProps } from './fittingNote'
import { toSimpleNote } from './simpleNote'
import { makeReadResponseValue } from './util'

const allowedNoteTypes = [
  NoteType.APPOINTMENT_CANCELLATION,
  NoteType.CONSULTATION_EVALUATION,
  NoteType.CUSTOMER_INTERACTION,
  NoteType.FOLLOW_UP,
  NoteType.GENERAL,
  NoteType.HEAR_INTERACTION,
  NoteType.INITIAL_FITTING,
  NoteType.INITIAL_FITTING_WITH_SHOEBOX,
]

const templateNameNoteType: Record<string, NoteType> = {
  [NoteTemplateName.POST_APPOINTMENT_OUTCOME_CAN]: NoteType.CONSULTATION_EVALUATION,
  [NoteTemplateName.CUSTOMER_INTERACTION]: NoteType.CUSTOMER_INTERACTION,
  [NoteTemplateName.GENERAL]: NoteType.GENERAL,
  [NoteTemplateName.HEAR_INTERACTION]: NoteType.HEAR_INTERACTION,
}

const noteResponseToNoteType = (note: NoteResponse): NoteType => {
  const read = makeReadResponseValue(note)
  const appointmentHeld = read(FittingProp.DID_APPOINTMENT_TAKE_PLACE) as boolean

  if (appointmentHeld === false) {
    return NoteType.APPOINTMENT_CANCELLATION
  }

  const noteType = read(CommonProp.FORM_TYPE) as NoteType

  if (noteType) return noteType

  const templateName = note[CommonProp.TEMPLATE_NAME] as NoteTemplateName

  return templateNameNoteType[templateName] ?? NoteType.GENERAL
}

type NoteResponseMapper = (note: NoteResponse) => Record<string, unknown>

const specificNotePropsMappers: Record<string, NoteResponseMapper> = {
  [NoteType.CONSULTATION_EVALUATION]: toFittingNoteProps,
  [NoteType.CUSTOMER_INTERACTION]: toSimpleNote,
  [NoteType.GENERAL]: toSimpleNote,
  [NoteType.HEAR_INTERACTION]: toSimpleNote,
  [NoteType.APPOINTMENT_CANCELLATION]: toSimpleNote,
  [NoteType.FOLLOW_UP]: toFittingNoteProps,
  [NoteType.INITIAL_FITTING]: toFittingNoteProps,
  [NoteType.INITIAL_FITTING_WITH_SHOEBOX]: toFittingNoteProps,
}

const toSpecificNoteProps = (note: NoteResponse, type: NoteType) =>
  specificNotePropsMappers[type] ? specificNotePropsMappers[type](note) : undefined

const toCanonicalNote = (note: NoteResponse): AnyNote | undefined => {
  const type = noteResponseToNoteType(note)

  if (!type) return // Unknown type

  // Prevent showing note types we know about but don't want to display yet
  if (!allowedNoteTypes.includes(type)) {
    // eslint-disable-next-line no-console
    console.warn('Note type is not supported', { note, type })

    return
  }

  // Guard against any misc transformation failures
  try {
    return {
      authoredBy: { id: note[CommonProp.AUTHOR_GID] },
      createdAt: new Date(note[CommonProp.CREATED_DATE]),
      id: note[CommonProp.ID],
      type,
      ...toSpecificNoteProps(note, type),
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Could not convert note response', { error, note })
  }
}

export { toCanonicalNote }
