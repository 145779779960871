import { useCallback } from 'react'
import { Query, QueryResponse } from 'types'
import { identity, toSafeDate } from 'utils'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'
import { defaultPagination } from './defaultPagination'
import type { License, PartnerEmployee } from './types'

enum ApiPathname {
  LICENSES = '/api/v1/licenses',
  PARTNER_EMPLOYEES = '/api/v1/partner-employees',
}

type GetManyQuery = Query<Partial<Pick<PartnerEmployee, 'id' | 'gid' | 'salesforceId' | 'userId'>>>
type GetLicenseQuery = Query<Partial<Pick<License, 'partnerEmployeeId' | 'state'>>>

const defaultQuery: Partial<GetManyQuery> = {
  ...defaultPagination,
  sort: 'DESC',
  sortBy: 'createdAt',
}

type GetManyResponse = QueryResponse<PartnerEmployee[]>
type GetLicensesResponse = QueryResponse<License[]>

interface ServiceContext {
  getMany: (query: GetManyQuery) => Promise<GetManyResponse>
  getLicenses: (query: GetLicenseQuery) => Promise<GetLicensesResponse>
}

const usePartnersService = (): ServiceContext => {
  const { url: baseUrl } = useServiceCountryUrl('partner')

  const getRequest = useRequest<GetManyQuery, GetManyResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.PARTNER_EMPLOYEES,
    transformResponse: async (response: Response) => {
      const { data, meta } = await response.json()

      const items = data.map((data: PartnerEmployee) => ({
        ...data,
        createdAt: toSafeDate(data?.createdAt),
        dateOfBirth: toSafeDate(data?.dateOfBirth),
        updatedAt: toSafeDate(data?.updatedAt),
      }))

      return {
        data: items,
        meta,
      }
    },
  })

  const getMany = useCallback(
    (query: GetManyQuery) =>
      getRequest({
        ...defaultQuery,
        ...query,
      }),
    [getRequest]
  )

  const getLicenseRequest = useRequest<GetManyQuery, GetLicensesResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.LICENSES,
    transformResponse: async (response: Response) => {
      const { data, meta } = await response.json()

      const items = data.map((data: License) => ({
        ...data,
        createdAt: toSafeDate(data?.createdAt),
        updatedAt: toSafeDate(data?.updatedAt),
        validFrom: toSafeDate(data?.updatedAt),
        validTo: toSafeDate(data?.createdAt),
      }))

      return {
        data: items,
        meta,
      }
    },
  })

  const getLicenses = useCallback(
    (query: GetLicenseQuery) =>
      getLicenseRequest({
        ...defaultQuery,
        ...query,
      }),
    [getLicenseRequest]
  )

  return { getLicenses, getMany }
}

export { usePartnersService }
export type { GetLicenseQuery, GetManyQuery as GetManyPartnersQuery, License }
