import { DocumentType } from 'constants/'

const documentTypeToDisplayTextMap: Record<DocumentType, string> = {
  [DocumentType.HIPAA_NOTICE]: 'HIPAA Notice of Privacy Practices',
  [DocumentType.MEDICAL_WAIVER]: 'Statement of Medical Waiver',
  [DocumentType.PURCHASE_DELIVERY_RECEIPT]: 'Purchase Delivery Receipt',
}

const documentTypeToDisplayText = (type: DocumentType) => documentTypeToDisplayTextMap[type] ?? 'Unknown'

export { documentTypeToDisplayText }
