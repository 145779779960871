enum EventType {
  JOIN_CALL_BUTTON_DISABLED_ON_APPOINTMENT_DASHBOARD_QUICK_VIEW = 'Join call button disabled on appointment dashboard quick view',
  ORDERS_TAB_CANCEL_SALES_ORDER_BUTTON_CLICKED = 'Orders Tab Cancel Sales Order Button Clicked',
  COMPLETE_OUTCOME_BUTTON_CLICKED = 'Complete Outcome Button Clicked',
  COMPLETE_OUTCOME_BUTTON_OUTCOME_COMPLETED = 'Complete Outcome Button Outcome Completed',
  COUPLING_EDITED = 'Coupling Edited',
  CUSTOMER_FOCUS_REASONS_ADDRESSED = 'Customer Focus Reasons Addressed',
  EDIT_COUPLING_BUTTON_CLICKED = 'Edit Coupling Button Clicked',
  EDIT_NOTE_SERVICE_NOTE_WITH_RELATED_APPOINTMENT = 'Edit Note Service Note With A Related Appointment',
  EXTERNAL_REDIRECT = 'External Redirect',
  FORM_ABANDONED = 'Form Abandoned',
  FORM_COMPLETED = 'Form Completed',
  FORM_SUBMISSION_FAILED = 'Form Submission Failed',
  INLINE_ADD_NOTE_BUTTON_CLICKED = 'Add Note Button Clicked',
  INLINE_EDIT_NOTE_BUTTON_CLICKED = 'Edit Note Button Clicked',
  NOTE_ADDED = 'Note Added',
  PAGE_VIEW = 'Page View',
}

export { EventType }
