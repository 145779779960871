import { EventType, Route } from 'constants/'
import { useEventTracking } from 'hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { DeliveryModel, ExternalRedirectData } from 'types'

enum KnownSearchParameter {
  DELIVERY_MODEL = 'delivery_model',
  DELIVERY_MODEL_OBFUSCATED = 'dm',
  UTM_SOURCE = 'utm_source',
}

// INFO: Directly tied to https://github.com/Audibene-GMBH/ta-appointment-service/blob/develop/src/spi/services/util/make-appointment-links/index.ts#L32
const searchDeliveryModelToDeliveryModel: Record<string, DeliveryModel> = {
  d: DeliveryModel.DIRECT_TO_CONSUMER,
  h: DeliveryModel.NETWORK_HOME_DELIVERY,
  n: DeliveryModel.NETWORK,
  t: DeliveryModel.TELEAUDIOLOGY,
} as const

enum ExternalUrl {
  MY_HEAR_COM = 'https://www.myhear.com',
}

const UNKNOWN_SOURCE = 'unknown'

const AnonymousDirector = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { track } = useEventTracking()

  const qsDeliveryModel =
    searchParams.get(KnownSearchParameter.DELIVERY_MODEL) ??
    searchParams.get(KnownSearchParameter.DELIVERY_MODEL_OBFUSCATED)

  const utmSource = searchParams.get(KnownSearchParameter.UTM_SOURCE) ?? UNKNOWN_SOURCE

  useEffect(() => {
    if (!utmSource) return

    const data: ExternalRedirectData = {
      redirect: 'anonymous',
      source: (utmSource ?? UNKNOWN_SOURCE) as string,
    }

    track(EventType.EXTERNAL_REDIRECT, {
      data,
      options: {
        transport: 'sendBeacon',
      },
    })
  }, [])

  useEffect(() => {
    const deliveryModel = searchDeliveryModelToDeliveryModel[qsDeliveryModel as string] ?? qsDeliveryModel

    if (deliveryModel === DeliveryModel.NETWORK) {
      const url = new URL(ExternalUrl.MY_HEAR_COM)

      url.searchParams.set('utm_source', window.location.origin)
      window.location.href = url.href

      return
    }

    // Fallback to normal authenticated redirect
    const url = new URL(Route.REDIRECT, window.location.origin)

    url.search = window.location.search

    const pathWithSearchParams = url.href.replace(url.origin, '')

    navigate(pathWithSearchParams)
  }, [qsDeliveryModel])

  return null
}

export { AnonymousDirector }
