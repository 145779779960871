import { useTranslation } from 'react-i18next'

const enumTranslations = 'enums'

type TranslationHandler = (options?: Record<string, string | number> | undefined) => string

const useEnumTranslation = () => {
  const { t } = useTranslation(enumTranslations)

  return t
}

export type { TranslationHandler }
export { useEnumTranslation }
