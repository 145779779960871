import { defaultPagination, defaultQueryOptions } from 'constants/'
import { useCallback } from 'react'
import { useInfiniteQuery } from 'react-query'
import { QueryOptions, QueryResponse } from 'types'
import { logFactory } from 'utils'
import {
  CreateNoteRequest,
  CreateNoteResponse,
  GetManyNotesQuery,
  UpdateNoteRequest,
  UpdateNoteResponse,
  useNoteService,
} from '../services'
import { Note } from '../services/useNoteService/types'

type NotesPage = QueryResponse<Note[]>

interface NotesContext {
  createNote: (request: CreateNoteRequest) => Promise<CreateNoteResponse>
  updateNote: (request: UpdateNoteRequest) => Promise<UpdateNoteResponse>
  error?: string
  hasMore: boolean
  fetchNotes: () => void
  isFetchingMore: boolean
  isLoading: boolean
  isError: boolean
  notes?: Note[]
  refetchNotes: () => void
}

interface InfiniteScrollPageParams {
  pageParam?: number
}

const hookName = 'useNotes'
const log = logFactory(hookName)

const useNotes = (
  query: GetManyNotesQuery = {} as GetManyNotesQuery,
  options: QueryOptions = { enabled: true }
): NotesContext => {
  const { createNote, getNote, updateNote } = useNoteService()
  const isEnabled = Boolean(options.enabled)

  const fetchNotesPage = useCallback(
    async ({ pageParam }: InfiniteScrollPageParams = {}) => {
      const actualQuery = { offset: pageParam ?? defaultPagination.page, ...query }

      log('🗒️ Fetching notes...', actualQuery)

      const data = await getNote(actualQuery)

      log(`🗒️ Found ${data?.data?.length ?? 0} notes`, data)

      return data
    },
    [getNote, query]
  )

  const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, ...rest } = useInfiniteQuery<NotesPage>(
    [hookName, query],
    fetchNotesPage,
    {
      ...defaultQueryOptions,
      enabled: isEnabled,
      ...options,
      getNextPageParam: (lastNotesPage) => {
        const page = lastNotesPage?.meta?.page
        const pages = lastNotesPage?.meta?.pages_total
        const nextPage = page + 1

        return nextPage < pages ? nextPage : undefined
      },
    }
  )

  const notes = data?.pages
    ?.map(({ data }) => data)
    ?.flat(1)
    ?.filter(Boolean) as Note[]

  return {
    ...rest,
    createNote,
    error: error as string | undefined,
    fetchNotes: fetchNextPage,
    hasMore: Boolean(hasNextPage),
    isFetchingMore: isFetchingNextPage,
    notes,
    refetchNotes: refetch,
    updateNote,
  }
}

export { useNotes }
