import { defaultQueryOptions } from 'constants/'
import { logFactory } from 'utils'
import { useDeviceService } from '../services'
import { useQuery } from 'react-query'
import { SingleDeviceCoupling, useCouplingFromDeviceService } from 'hooks/useCoupling'
import { DeviceCouplingSlotType, QueryOptions } from 'types'

interface ResponseStatusContext {
  isError: boolean
  isLoading: boolean
}

interface AppConfigurationContext extends ResponseStatusContext {
  data?: { isPaired: boolean; hasTelecareSetup: boolean; isLoadingCoupling: boolean }
}

const appConfigurationHookName = 'useAppConfiguration'
const logAppConfiguration = logFactory(appConfigurationHookName)

const useAppConfiguration = (
  opportunityGid: string,
  customerSalesforceId: string,
  options: QueryOptions
): AppConfigurationContext => {
  const { getAppConfiguration } = useDeviceService()
  const hasRequiredIdsForApiRequest = Boolean(customerSalesforceId && opportunityGid)

  const { data: couplingFromDeviceService, isLoading: isLoadingCoupling } = useCouplingFromDeviceService(
    customerSalesforceId as string,
    opportunityGid as string,
    {
      enabled: hasRequiredIdsForApiRequest && options.enabled,
    }
  )

  const getLeftAndRightHearingAids = (coupling: SingleDeviceCoupling) => {
    const leftHearingAid = coupling?.left?.slots?.filter((slot) => slot.type === DeviceCouplingSlotType.HEARING_DEVICE)
    const rightHearingAid = coupling?.right?.slots?.filter(
      (slot) => slot.type === DeviceCouplingSlotType.HEARING_DEVICE
    )

    const serialNumbers = []

    if (leftHearingAid?.[0]?.serialNumber) {
      serialNumbers.push(leftHearingAid[0].serialNumber)
    }

    if (rightHearingAid?.[0]?.serialNumber) {
      serialNumbers.push(rightHearingAid[0].serialNumber)
    }

    return serialNumbers
  }

  const fetchAppConfiguration = async () => {
    logAppConfiguration(`🦻 Fetching app configuration...`, opportunityGid)

    const serialNumbers = getLeftAndRightHearingAids(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      couplingFromDeviceService?.latestCoupling!
    )

    if (!serialNumbers.length) {
      return {
        hasTelecareSetup: false,
        isLoadingCoupling,
        isPaired: false,
      }
    }

    const response = await getAppConfiguration({
      opportunitySalesforceId: opportunityGid,
      serialNumbers,
    })

    logAppConfiguration(`🦻 Found the following:`, response)

    if (response.itemsTotal === 0) {
      return {
        hasTelecareSetup: false,
        isLoadingCoupling,
        isPaired: false,
      }
    }

    return {
      hasTelecareSetup: response.items[0].hasTelecareSetup,
      isLoadingCoupling,
      isPaired: response.items[0].isPaired,
    }
  }

  return useQuery([appConfigurationHookName, couplingFromDeviceService], fetchAppConfiguration, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useAppConfiguration }
