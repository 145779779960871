import { OptionsObject, useSnackbar, VariantType } from 'notistack'
import { ReactNode, useCallback } from 'react'
import { CloseButton } from './CloseButton'
import { Provider } from './Provider'

interface NotificationContext {
  notify: (message: string | ReactNode, variant?: VariantType, overrides?: Partial<OptionsObject>) => void
}

const seconds = (count: number) => count * 1000

const defaults = {
  action: CloseButton,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  autoHideDuration: seconds(3),
  persist: false,
} as OptionsObject

const useNotification = (options: OptionsObject = {}): NotificationContext => {
  const { enqueueSnackbar } = useSnackbar()

  const actualOptions = { ...defaults, 'data-testid': 'notification', ...options }

  const notify = useCallback(
    (message: string | ReactNode, variant: VariantType = 'info', overrides?: Partial<OptionsObject>) => {
      enqueueSnackbar(message, {
        ...actualOptions,
        ...(overrides ?? {}),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-variant': variant,
        variant,
      })
    },
    [actualOptions]
  )

  return { notify }
}

export { Provider as NotificationProvider, useNotification }
