import { useCallback } from 'react'
import { Query, SalesOrder, SalesOrderAction, SalesOrderRelation, SalesOrderRelationType } from 'types'
import { identity, pick } from 'utils'
import { HttpMethod } from 'constants/'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'

const defaultPagination = {
  page: 0,
  pageSize: 25,
}

enum ApiPathname {
  SALES_ORDER_RELATIONS = '/api/v1/sales-order-relations',
  SALES_ORDERS = '/api/v1/sales-orders',
  SALES_ORDERS_ACTION_V2 = '/api/v2/sales-orders/:salesOrderId/action',
}

type GetSalesOrderRelationsQuery = Query<{
  salesOrderIds?: string[]
  types?: SalesOrderRelationType[]
}>

type GetSalesOrdersQuery = Query<{
  customerGids?: string[]
}>

type QueryResponse<T> = {
  data: T
  meta: {
    itemsTotal: number
    page: number
    pageSize: number
    pageTotal: number
  }
}

type GetSalesOrdersResponse = QueryResponse<SalesOrder[]>
type GetSalesOrderRelationsResponse = QueryResponse<SalesOrderRelation[]>
type PatchSalesOrderResponse = QueryResponse<SalesOrder>

type SalesOrderActionRequest = {
  id: string
  action: SalesOrderAction
}

interface UseHookContext {
  doSalesOrderAction: (query: SalesOrderActionRequest) => Promise<PatchSalesOrderResponse>
  getSalesOrders: (query: GetSalesOrdersQuery) => Promise<GetSalesOrdersResponse>
  getSalesOrderRelations: (query: GetSalesOrderRelationsQuery) => Promise<GetSalesOrderRelationsResponse>
}

const useOrdersService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('order')

  const getSalesOrderRelationsRequest = useRequest<GetSalesOrderRelationsQuery, GetSalesOrderRelationsResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.SALES_ORDER_RELATIONS,
  })

  const getSalesOrderRelations = useCallback(
    (query: GetSalesOrderRelationsQuery) =>
      getSalesOrderRelationsRequest({
        ...defaultPagination,
        ...query,
      }),
    [getSalesOrderRelationsRequest]
  )

  const getSalesOrdersRequest = useRequest<GetSalesOrdersQuery, GetSalesOrdersResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.SALES_ORDERS,
  })

  const getSalesOrders = useCallback(
    (query: GetSalesOrdersQuery) =>
      getSalesOrdersRequest({
        ...defaultPagination,
        ...query,
      }),
    [getSalesOrdersRequest]
  )

  const doSalesOrderActionRequest = useRequest<SalesOrderActionRequest, PatchSalesOrderResponse>({
    baseUrl,
    filterQueryToRequestBody: pick(['action']),
    filterQueryToRouteParams: ({ id: salesOrderId }: SalesOrderActionRequest) => ({ salesOrderId }),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.SALES_ORDERS_ACTION_V2,
  })

  const doSalesOrderAction = useCallback(
    (request: SalesOrderActionRequest) => {
      return doSalesOrderActionRequest(request)
    },
    [doSalesOrderActionRequest]
  )

  return { doSalesOrderAction, getSalesOrderRelations, getSalesOrders }
}

export { useOrdersService }
