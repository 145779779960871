import { Paper, PaperProps } from '@material-ui/core'
import { Route } from 'constants/'
import { useFeatureFlags } from 'hooks'
import { CustomerOpportunityDTO } from 'types'
import { Link } from '../../../../Link'
import { useStyles } from './index.styles'

type Props = Omit<PaperProps, 'results'> & {
  customers: CustomerOpportunityDTO[]
  maxResults: number
  search: string
}

const Results = ({ children, customers, maxResults, search, ...paperProps }: Props) => {
  const classes = useStyles()
  const [isNewCustomerProgressBarFlagEnabled] = useFeatureFlags(['PROGRESS_BAR_V2'])

  const customerRoute = isNewCustomerProgressBarFlagEnabled ? Route.GLOBAL_CUSTOMERS : Route.CUSTOMERS

  return (
    <Paper {...paperProps} className={classes.root}>
      {children}
      {customers.length > maxResults && (
        <div className={classes.link}>
          <Link
            onClick={() => (document?.activeElement as HTMLElement)?.blur()}
            onMouseDown={(event: MouseEvent) => {
              event.preventDefault()
            }}
            to={`${customerRoute}?q=${encodeURIComponent(search)}`}
          >
            View all results
          </Link>
        </div>
      )}
    </Paper>
  )
}

export { Results }
