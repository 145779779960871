import { DeviceStyle, DeviceType, ReceiverLength, ReceiverStrength, ReceiverType, Side } from 'constants/'
import {
  AccountType,
  Coupling,
  CouplingEar,
  CouplingInfo,
  DeviceCouplingDTO,
  DeviceCouplingLeftAndRight,
  DeviceSize,
  EarCoupling,
  LatestDeviceCouplingInfo,
} from 'types'

const toEarCoupling = (ear?: CouplingEar, isCROSDevice?: boolean) => {
  let newEar: EarCoupling | undefined

  if (ear?.coupler) {
    newEar = {
      ...newEar,
      device: {
        size: ear?.coupler?.size as DeviceSize,
        style: ear?.coupler?.style as DeviceStyle,
        type: ear?.coupler?.type as DeviceType,
      },
    }
  }

  if (ear?.receiver) {
    newEar = {
      ...newEar,
      receiver: {
        length: ear?.receiver?.length as ReceiverLength,
        strength: ear?.receiver?.strength as ReceiverStrength,
        type: ear?.receiver?.type as ReceiverType,
      },
    }
  }

  if (newEar?.device || newEar?.receiver) {
    newEar.isCROSDevice = isCROSDevice
  }

  if (ear?.hearing_aid) {
    newEar = {
      ...newEar,
      hearingAid: {
        name: ear.hearing_aid.name,
        // @ts-expect-error product_id vs productId
        productId: ear.hearing_aid.product_id ?? ear.hearing_aid.productId,
      },
    }
  }

  return newEar
}

export const transformCouplingInfo = (couplingInfo: CouplingInfo): Coupling => {
  if (!couplingInfo.left_ear && !couplingInfo.right_ear) return { hasCoupling: false }

  const coupling: Coupling = { createdAt: couplingInfo.created_at, hasCoupling: true }

  coupling.left = toEarCoupling(couplingInfo?.left_ear, couplingInfo.cros_device === Side.LEFT)
  coupling.right = toEarCoupling(couplingInfo?.right_ear, couplingInfo.cros_device === Side.RIGHT)

  return coupling
}

export const transformCouplingInfoFromDeviceService = (
  couplingInfo: LatestDeviceCouplingInfo
): DeviceCouplingLeftAndRight & DeviceCouplingDTO => {
  if (!couplingInfo.coupling || (!couplingInfo.coupling.left && !couplingInfo.coupling.right)) {
    return { hasCoupling: false }
  }

  const isDefaultCoupling =
    couplingInfo.createdBy.type === AccountType.SYSTEM || couplingInfo.createdBy.type === AccountType.MIGRATION

  return {
    appointmentGid: couplingInfo.appointmentGid,
    createdAt: couplingInfo.createdAt,
    createdBy: couplingInfo.createdBy,
    hasCoupling: true,
    id: couplingInfo.id,
    isDefaultCoupling,
    left: couplingInfo.coupling.left,
    right: couplingInfo.coupling.right,
  }
}
