import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        overflow: 'unset',
        maxHeight: 'unset',
      },
    },
    link: {
      alignItems: 'center',
      display: 'grid',
      lineHeight: 2,
      padding: '0 1rem 1rem',
    },
  })
) as unknown as UseStylesFunction

export { useStyles }
