import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useRemoteFittingService } from '../services'

const hookName = 'useCustomerImages'
const log = logFactory(hookName)

const useCustomerImages = (customerId?: string, options: QueryOptions = {}) => {
  const { getCustomerImages } = useRemoteFittingService()

  const doQuery = async () => {
    log(`📸 Fetching customer images...`, { customerId })

    const data = await getCustomerImages({
      customerId: customerId as string,
    })

    if (data) {
      log(`📸 Found a customer images`, data)
    } else {
      log(`🚫📸 No customer images found`, data)
    }

    return data
  }

  return useQuery([hookName, customerId], doQuery, {
    ...defaultQueryOptions,
    refetchOnMount: false,
    ...options,
    enabled: Boolean(customerId),
  })
}

export { useCustomerImages }
