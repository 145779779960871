import { Item, Query, QueryResponse } from 'types'
import { identity } from 'utils'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'

const apiVersionSuffix = '/v2'

enum ApiPathname {
  GET_ITEMS = '/item',
}

type GetItemsQuery = Query<{ gids: string[] }>
type GetItemsResponse = QueryResponse<Item[]>

interface UseHookContext {
  getItems: (query: GetItemsQuery) => Promise<GetItemsResponse>
}

const useItemService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('item')

  const getItemsRequest = useRequest<GetItemsQuery, GetItemsResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: `${apiVersionSuffix}${ApiPathname.GET_ITEMS}`,
  })

  return {
    getItems: getItemsRequest,
  }
}

export { useItemService }
