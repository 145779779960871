import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { Query, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useNotificationsService } from '../services'

type MyNotificationsQuery = Query<{
  is_read?: boolean
}>

const hookName = 'useMyNotifications'
const log = logFactory(hookName)

const useMyNotifications = (query: MyNotificationsQuery, options: QueryOptions = { enabled: true }) => {
  const { getMyNotifications, markAllRead, markOneRead } = useNotificationsService()

  const doQuery = async () => {
    log(`🔔 Getting my notifications...`)

    const data = await getMyNotifications(query)

    log(`🔔 Found my notifications`, data)

    return data
  }

  const { data: response, ...rest } = useQuery([hookName], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })

  return {
    ...rest,
    ...response,
    markAllRead,
    markOneRead,
  }
}

export { hookName as useMyNotificationsHookName, useMyNotifications }
