import { AppointmentStatus } from 'constants/'
import { useInfiniteQuery } from 'react-query'
import { Appointment, Customer, CustomerAppointment, Query, QueryOptions } from 'types'
import { indexBy, logFactory, prop } from 'utils'
import { useAppointmentsService, useCustomersService } from '../services'

type UseHooksProps = Query<{
  dateEnd?: Date
  dateStart?: Date
  customerGid?: string
  fitterGid?: string
  opportunityGid?: string[]
  status?: AppointmentStatus[]
}>

const hookName = 'useDashboardAppointments'
const log = logFactory(hookName)

const useDashboardAppointments = (
  { customerGid, fitterGid, opportunityGid, ...rest }: UseHooksProps,
  options: QueryOptions = { enabled: true }
) => {
  const { getAppointments } = useAppointmentsService()
  const { searchCustomers } = useCustomersService()

  const params = {
    ...rest,
    customer_gid: customerGid ? [customerGid] : undefined,
    opportunity_gid: opportunityGid,
    resourceGids: fitterGid ? [fitterGid] : undefined,
  }

  const doQuery = async ({ pageParam = 0 }) => {
    log(`🕧📆 Fetching dashboard appointments...`, params)

    const { data: rawAppointments = [], meta } = await getAppointments({ ...params, page: pageParam })
    const rawAppointmentsBySalesforceId = indexBy(rawAppointments, ({ customer }) => customer.gid)
    const appointmentCustomerSalesforceIds = Object.keys(rawAppointmentsBySalesforceId)
    let customers: Customer[] = []

    if (appointmentCustomerSalesforceIds.length > 0) {
      log(`📆👤 Fetching appointment customers...`, appointmentCustomerSalesforceIds)
      const { data: rawCustomers = [] } = await searchCustomers({ salesforceId: appointmentCustomerSalesforceIds })

      log(`📆👤 Found ${rawCustomers.length} appointment customers`, rawCustomers)
      customers = [...rawCustomers]
    }

    log(`🕧📆 Found ${rawAppointments.length} dashboard appointments`, rawAppointments)

    // Create a map of customer salesforceIds that the logged in user has access to
    const allowedCustomerSalesforceIds = indexBy(customers, prop('salesforceId'))

    // Split appointments into two arrays: one with appointments the user has access to and one without
    const [appointments, appointmentsWithInaccessibleCustomers] = rawAppointments.reduce<
      [Appointment[], Appointment[]]
    >(
      (acc, appointment) => {
        if (appointment?.customer?.gid in allowedCustomerSalesforceIds) {
          // The user has access to this appointment's customer
          acc[0].push(appointment)
        } else {
          // The user DOES NOT have access to this appointment's customer
          acc[1].push(appointment)
        }

        return acc
      },
      [[], []]
    )

    if (appointmentsWithInaccessibleCustomers.length > 0) {
      log(
        `🕧📆 Omitted (${appointmentsWithInaccessibleCustomers.length}) dashboard appointment(s) where the current user doesn't have access to the given customer`,
        appointmentsWithInaccessibleCustomers
      )
    }

    return { appointments, customers, meta }
  }

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isError: haveAppointmentsErrored,
    isLoading: areAppointmentsLoading,
    isFetchingNextPage,
    ...results
  } = useInfiniteQuery([hookName, params], doQuery, {
    getNextPageParam: (lastPage) => {
      const { meta } = lastPage
      const maxPages = meta.pages_total - 1

      if (maxPages > meta.page) {
        // Return next page number to PageParams
        return meta.page + 1
      }
      // Return false means no next page (no PageParams value will be set)
      return false
    },
    ...options,
  })

  const rawData = data?.pages ?? []

  let rawAppointments: Appointment[] = []
  let rawCustomers: Customer[] = []

  if (rawData.length > 0) {
    rawAppointments = rawData.flatMap(({ appointments }) => appointments)
    rawCustomers = rawData.flatMap(({ customers }) => customers)
  }

  const customersBySalesforceId = indexBy(rawCustomers, prop('salesforceId'))

  const isError = haveAppointmentsErrored
  const isLoading = areAppointmentsLoading

  // We need to swap out the appointment's customer for the "real" customer service customer
  const appointments: CustomerAppointment[] = rawAppointments.map((appointment: Appointment) => {
    const customer = customersBySalesforceId[appointment.customer.gid]

    const customerAppointment: CustomerAppointment = {
      ...appointment,
      customer,
    }

    return customerAppointment
  })

  return {
    ...results,
    data: appointments,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading,
  }
}

export { useDashboardAppointments }
