import { defaultQueryOptions } from 'constants/'
import { useMyAccount } from 'hooks'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetResourcesQuery, useAppointmentsService } from '../services'

const hookName = 'useResources'
const log = logFactory(hookName)

const useResources = (query: GetResourcesQuery, options: QueryOptions = { enabled: true }) => {
  const { getResources } = useAppointmentsService()
  const { data: account } = useMyAccount()

  const doQuery = async () => {
    const params = { ...query }

    log(`🥼 Fetching resources...`, params)

    const { data } = await getResources(params)

    log(`🥼 Returned resource...`, data)

    return data
  }

  return useQuery([hookName, account?.gid, query], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && account?.gid),
  })
}

export { useResources }
