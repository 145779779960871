import { defaultPagination, defaultQueryOptions } from 'constants/'
import { useCallback } from 'react'
import { useInfiniteQuery } from 'react-query'
import { AnyNote, NoteRequest, NoteResponse, QueryOptions, QueryResponse } from 'types'
import { logFactory } from 'utils'
import { useMedicalRecordsService } from '../services'
import { useCustomer } from '../useCustomer'
import { toCanonicalNote } from './transforms'

type NotesPage = QueryResponse<NoteResponse[]>

interface NotesContext {
  createNote: (request: NoteRequest) => Promise<string>
  error?: string
  hasMore: boolean
  fetchNotes: () => void
  isFetchingMore: boolean
  isLoading: boolean
  isError: boolean
  notes?: AnyNote[]
  refetchNotes: () => void
}

interface InfiniteScrollPageParams {
  pageParam?: number
}

const log = logFactory('useLegacyNotes')

const useLegacyNotes = ({ enabled, ...options }: QueryOptions = { enabled: true }): NotesContext => {
  const { createNote, getNotes } = useMedicalRecordsService()
  const { data: customer } = useCustomer()
  const customerGid = customer?.salesforceId ?? ''
  const isEnabled = Boolean(enabled && customerGid)

  const fetchNotesPage = useCallback(
    async ({ pageParam }: InfiniteScrollPageParams = {}) => {
      const params = { customerGid, page: pageParam ?? defaultPagination.page }

      log('🗒️ Fetching notes...', params)

      const data = await getNotes(params)

      log(`🗒️ Found ${data?.data?.length ?? 0} notes`, data)

      return data
    },
    [customerGid, getNotes]
  )

  const queryKey = ['notes', customerGid]

  const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, ...rest } = useInfiniteQuery<NotesPage>(
    queryKey,
    fetchNotesPage,
    {
      ...defaultQueryOptions,
      enabled: isEnabled,
      ...options,
      getNextPageParam: (lastNotesPage) => {
        const page = lastNotesPage?.meta?.page
        const pages = lastNotesPage?.meta?.pages_total
        const nextPage = page + 1

        return nextPage < pages ? nextPage : undefined
      },
    }
  )

  const notes = data?.pages
    ?.map(({ data }) => data)
    ?.flat(1)
    ?.map((note) => toCanonicalNote(note as NoteResponse))
    ?.filter(Boolean) as AnyNote[]

  return {
    ...rest,
    createNote,
    error: error as string | undefined,
    fetchNotes: fetchNextPage,
    hasMore: Boolean(hasNextPage),
    isFetchingMore: isFetchingNextPage,
    notes,
    refetchNotes: refetch,
  }
}

export { useLegacyNotes }
