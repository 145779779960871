enum Route {
  CUSTOMERS = '/customers',
  GLOBAL_CUSTOMERS = '/customer-search',
  CUSTOMER_PROFILE = '/customers/:customerId',
  CUSTOMER_PROFILE_DEPRECATED = '/profile/:customerId',
  FORMS = '/forms',
  FORMS_POST_APPOINTMENT_OUTCOME_FORM_CUSTOMER = '/forms/post-appointment-form/:customerId',
  HOME = '/',
  NOTIFICATIONS = '/notifications',
  REDIRECT = '/r',
  REDIRECT_ANONYMOUS = '/a',
  SETTINGS = '/settings',
  SETTINGS_SCHEDULING = '/settings/scheduling',
}

export { Route }
