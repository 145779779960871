import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { Query, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useShippingService } from '../services'

type UseHooksProps = Query<{
  ids?: string[]
}>

const hookName = 'useShipments'
const log = logFactory(hookName)

const useShipments = (params: UseHooksProps, options: QueryOptions = { enabled: true }) => {
  const { getShipments } = useShippingService()

  const doQuery = async () => {
    log(`🚚 Fetching shipments...`, params)

    const { data } = await getShipments(params)

    log(`🚚 Found ${data?.length} shipments`, data)

    return data
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && params.ids?.length),
  })
}

export { useShipments }
