import { LogTypes } from '@audibene/ta-browser-tracker'
import { useCallback } from 'react'
import { logRocket } from 'startup'

type ErrorLogFunction = (error: string) => void

const useTrackError = (): ErrorLogFunction => {
  const trackError = useCallback((error: string) => logRocket?.log(LogTypes.ERROR, error), [logRocket])

  return trackError
}

export { useTrackError }
