import { lazy } from 'react'
import { Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'

const Settings = lazy(() =>
  import(
    /* webpackChunkName: "Settings" */
    'components/Settings'
  ).then(namedExportToDefaultExport('Settings'))
)

const Router = () => (
  <Routes>
    <Route element={<Settings />} path="*" />
  </Routes>
)

export { Router as Settings }
