import LiveChat from '../../LiveChat'
import { Header } from '../Header'
import { Main } from '../Main'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Layout = () => (
  <>
    <Header />
    <Main />
    <LiveChat />
  </>
)

export { Layout }
