import { lazy } from 'react'
import { namedExportToDefaultExport } from 'utils'

const componentName = 'Home'

const Home = lazy(() =>
  import(
    /* webpackChunkName: "Home" */
    'components/Home'
  ).then(namedExportToDefaultExport(componentName))
)

export { Home }
