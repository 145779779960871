import { defaultQueryOptions } from 'constants/'
import { createContext, ReactNode, useContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { useParams } from 'react-router'
import type { Customer, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useCustomersService } from '../services'

const hookName = 'useCustomer'
const log = logFactory(hookName)

interface ProfileRouteParams {
  customerId: string
}

type ProviderProps = {
  children: ReactNode
  customer?: Customer
}

const useCustomerQuery = (customerGid?: string, options: QueryOptions = { enabled: true }) => {
  const { searchCustomers } = useCustomersService()

  const params = {
    id: [customerGid as string],
  }

  const doQuery = async () => {
    log(`👤 Fetching customer...`, params)

    const { data } = await searchCustomers(params)
    const [customer] = data ?? ([] as Customer[])

    log(`👤 Found customer`, customer)

    return customer
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

type CustomerQueryResponse = UseQueryResult<Customer, unknown>
const defaultContext = {} as CustomerQueryResponse

const context = createContext(defaultContext)
const { Provider } = context

const CustomerProvider = ({ children, customer: customerProp }: ProviderProps) => {
  // @ts-expect-error string index bs
  const { customerId } = useParams<ProfileRouteParams>()

  const results = useCustomerQuery(customerId as string, {
    enabled: Boolean(!customerProp && customerId),
  })

  const contextValue = {
    ...results,
    data: (customerProp as Customer) ?? results.data,
  } as CustomerQueryResponse

  return <Provider value={contextValue}>{children}</Provider>
}

const useCustomer = () => useContext(context)

export { CustomerProvider, useCustomer }
