import { Customer } from 'types'
import { ianaTimeZoneToShortTimezone } from '../date'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customerToDisplayLocation = (customer: Partial<Customer>): string => {
  if (!customer) {
    return ``
  }

  const { countryCode, timezone } = customer
  const { city, state } = customer?.billingAddress ?? {}

  switch (true) {
    case Boolean(city && countryCode && state && timezone):
      return `${city}, ${state}, ${countryCode} (${ianaTimeZoneToShortTimezone(timezone as string)})`
    case Boolean(countryCode && state && timezone):
      return `${state}, ${countryCode} (${ianaTimeZoneToShortTimezone(timezone as string)})`
    case Boolean(countryCode && state):
      return `${state}, ${countryCode}`
    case Boolean(countryCode && timezone):
      return `${countryCode} (${ianaTimeZoneToShortTimezone(timezone as string)})`
    case Boolean(state && timezone):
      return `${state} (${ianaTimeZoneToShortTimezone(timezone as string)})`
    case Boolean(countryCode):
      return countryCode as string
    case Boolean(state):
      return state as string
    case Boolean(timezone):
      return ianaTimeZoneToShortTimezone(timezone as string)
    default:
      return 'N/A'
  }
}

export { customerToDisplayLocation }
