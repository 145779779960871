import { defaultQueryOptions } from 'constants/'
import { useItemService } from 'hooks/services/useItemService'
import { useQuery } from 'react-query'
import { Coupling, QueryOptions, DeviceCouplingLeftAndRight, DeviceCouplingSlotType, DeviceCouplingDTO } from 'types'
import { logFactory } from 'utils'
import { useMedicalRecordsService, useDeviceService, GetCouplingQuery } from '../services'
import { transformCouplingInfo } from './transformCoupling'
import { transformCouplingInfoFromDeviceService } from './transformCoupling/index'
import { CreatedByType } from 'hooks/services/useDeviceService/types'

interface ResponseStatusContext {
  isError: boolean
  isLoading: boolean
  refetch: () => void
  isRefetching: boolean
}
interface CouplingContext extends ResponseStatusContext {
  data?: Coupling
}
type SingleDeviceCoupling = DeviceCouplingLeftAndRight &
  DeviceCouplingDTO & {
    isLatestAppointmentsCoupling?: boolean
  }

interface CouplingContextFromDeviceService extends ResponseStatusContext {
  data?: { latestCoupling: SingleDeviceCoupling; couplings: SingleDeviceCoupling[] }
}

const deviceCouplingHookName = 'useDeviceCoupling'
const itemServiceHookName = 'useItemService'
const logDeviceCoupling = logFactory(deviceCouplingHookName)
const logItemService = logFactory(itemServiceHookName)

const useCouplingFromDeviceService = (
  customerGid: string,
  opportunityGid: string,
  options: QueryOptions = { enabled: true },
  createdByType?: CreatedByType
): CouplingContextFromDeviceService => {
  const { getLatestCoupling } = useDeviceService()
  const { getItems } = useItemService()

  let latestCouplingParams = {
    customerSalesforceId: customerGid,
    opportunitySalesforceId: opportunityGid,
  } as GetCouplingQuery

  if (createdByType) {
    latestCouplingParams = { ...latestCouplingParams, createdBy: createdByType }
  }

  const fetchCoupling = async () => {
    logDeviceCoupling(`🦻 Fetching coupling...`, latestCouplingParams)
    const response = await getLatestCoupling(latestCouplingParams)

    logDeviceCoupling(`🦻 Found the following:`, response)

    if (!response.data.length) {
      return {
        couplings: [],
        hasCoupling: false,
        latestCoupling: {},
      }
    }

    const couplings = []

    for (let index = 0; index < response.data.length; index++) {
      let coupling = transformCouplingInfoFromDeviceService(response.data[index])

      if (coupling.hasCoupling) {
        const [leftReceiversInDeviceCoupling, rightReceiversInDeviceCoupling] = [
          coupling.left?.slots.find((sl) => sl.type === DeviceCouplingSlotType.RECEIVER_TUBE),
          coupling.right?.slots.find((sl) => sl.type === DeviceCouplingSlotType.RECEIVER_TUBE),
        ]

        const availableReceiverGids: string[] = []

        if (leftReceiversInDeviceCoupling) {
          availableReceiverGids.push(leftReceiversInDeviceCoupling.itemGid)
        }
        if (rightReceiversInDeviceCoupling) {
          availableReceiverGids.push(rightReceiversInDeviceCoupling.itemGid)
        }

        logItemService(`🦻 Fetching receiver details...`, availableReceiverGids)
        const itemServiceResponse = await getItems({ gids: availableReceiverGids })

        logItemService(`🦻 Found the following:`, itemServiceResponse)

        if (leftReceiversInDeviceCoupling && coupling.left) {
          coupling = {
            ...coupling,
            left: {
              ...coupling.left,
              slots: coupling.left.slots.map((sl) => {
                if (sl.customerItemId === leftReceiversInDeviceCoupling.customerItemId) {
                  const matchingItemFromResponse = itemServiceResponse.data.find(
                    (item) => item.gid === leftReceiversInDeviceCoupling.itemGid
                  )

                  return {
                    ...sl,
                    length: matchingItemFromResponse && matchingItemFromResponse.length,
                    strength: matchingItemFromResponse && matchingItemFromResponse.strength,
                  }
                }
                return sl
              }),
            },
          }
        }

        if (rightReceiversInDeviceCoupling && coupling.right) {
          coupling = {
            ...coupling,
            right: {
              ...coupling.right,
              slots: coupling.right.slots.map((sl) => {
                if (sl.customerItemId === rightReceiversInDeviceCoupling.customerItemId) {
                  const matchingItemFromResponse = itemServiceResponse.data.find(
                    (item) => item.gid === rightReceiversInDeviceCoupling.itemGid
                  )

                  return {
                    ...sl,
                    length: matchingItemFromResponse && matchingItemFromResponse.length,
                    strength: matchingItemFromResponse && matchingItemFromResponse.strength,
                  }
                }
                return sl
              }),
            },
          }
        }
        couplings.push(coupling)
      }
    }

    return { couplings, latestCoupling: couplings[0] }
  }

  return useQuery([deviceCouplingHookName, { customerGid, opportunityGid }], fetchCoupling, {
    ...defaultQueryOptions,
    ...options,
  })
}

const legacyCouplingHookName = 'useCoupling'
const logLegacyCoupling = logFactory(legacyCouplingHookName)

const useCoupling = (
  customerGid: string,
  opportunityGid: string,
  options: QueryOptions = { enabled: true }
): CouplingContext => {
  const { getCoupling } = useMedicalRecordsService()
  const params = { customerGid, opportunityGid }

  const fetchCoupling = async () => {
    logLegacyCoupling(`🦻 Fetching coupling...`, params)

    const response = await getCoupling(params)

    logLegacyCoupling(`🦻 Found the following:`, response)

    const transformed = transformCouplingInfo(response)

    logLegacyCoupling(`🦻 Transformed coupling`, transformed)

    return transformed
  }

  return useQuery([legacyCouplingHookName, params], fetchCoupling, {
    ...defaultQueryOptions,
    ...options,
  })
}

export type { SingleDeviceCoupling }
export { useCoupling, useCouplingFromDeviceService }
