import { defaultQueryOptions } from 'constants/'
import { createContext, ReactNode, useContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import type { Customer, OpportunityLifecycle } from 'types'
import { logFactory } from 'utils'
import { useOpportunityLifecyclesService } from '../services'
import { useCustomer } from '../useCustomer'

type ProviderProps = {
  children: ReactNode
  opportunity?: OpportunityLifecycle
}

type UseHookContext = Omit<UseQueryResult, 'data'> &
  Partial<{
    customer: Customer
    isError: boolean
    opportunity: OpportunityLifecycle
  }>

const hookName = 'useOpportunity'
const log = logFactory(hookName)
const context = createContext<Partial<UseHookContext>>({})
const { Provider } = context

const OpportunityLifecycleProvider = ({ children, opportunity: opportunityProp }: ProviderProps) => {
  const { data: customer, isError: hasCustomerErrored, isLoading: isCustomerLoading } = useCustomer()
  const customerGid = customer?.salesforceId
  const actualCustomer = customerGid ? customer : undefined
  const { getOpportunityLifecycles } = useOpportunityLifecyclesService()

  const fetchOpportunity = async () => {
    const params = {
      customer_gid: [customerGid as string],
    }

    log(`🥰 Fetching opportunity...`, params)

    const { data } = await getOpportunityLifecycles(params)
    const [found] = data

    log(`🥰 Found opportunity`, found)

    return found
  }

  const {
    data: opportunity,
    isError: hasOpportunityLifecycleErrored,
    isLoading: isOpportunityLifecycleLoading,
    ...rest
  } = useQuery([hookName, customerGid], fetchOpportunity, {
    ...defaultQueryOptions,
    enabled: Boolean(customerGid),
  })

  const isError = hasCustomerErrored || hasOpportunityLifecycleErrored
  const isLoading = isCustomerLoading || isOpportunityLifecycleLoading

  const contextValue: UseHookContext = {
    ...rest,
    customer: actualCustomer,
    isError,
    isLoading,
    opportunity: opportunityProp || opportunity,
  }

  return <Provider value={contextValue}>{children}</Provider>
}

const useOpportunityLifecycle = () => useContext(context)

export { context as OpportunityLifecycleContext, OpportunityLifecycleProvider, useOpportunityLifecycle }
