import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { JourneyActivity, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useOpportunityService } from '../services'

const hookName = 'useOpportunityJourneyActivities'
const log = logFactory(hookName)

type Query = {
  opportunityIds?: string[]
}

const MAX_REQUEST_ID_COUNT = 50

const chunk = (opportunities: string[]) => {
  const arr = [...opportunities]
  const output = []

  while (arr.length) {
    output.push(arr.splice(0, MAX_REQUEST_ID_COUNT))
  }
  return output
}

const useOpportunityJourneyActivities = ({ opportunityIds }: Query, options: QueryOptions = { enabled: true }) => {
  const { getJourneyActivities } = useOpportunityService()

  const opIds = opportunityIds ?? []
  const idChunks = chunk(opIds)

  const doQuery = async () => {
    let foundItems: JourneyActivity[] = []
    const totalItems = idChunks.map((ids) => {
      let hasNextPage = false
      let pageIndex = 0

      return new Promise((resolve, reject) => {
        ;(async () => {
          try {
            log(`🏃‍♂️ Fetching opportunities' journey activities...`)
            do {
              const { data, meta } = await getJourneyActivities({ offset: pageIndex, opportunityId: ids })

              foundItems = [...foundItems, ...data]

              hasNextPage = pageIndex + 1 < meta.pagesTotal
              pageIndex++
            } while (hasNextPage)

            log(`🏃‍♂️ Found ${foundItems.length} journey activities`, foundItems)

            return resolve(foundItems)
          } catch (err) {
            reject(err)
          }
        })()
      })
    })
    const allActivities = (await Promise.all(totalItems)) as JourneyActivity[]

    const data = allActivities.flat()

    return { data }
  }

  return useQuery([hookName, opportunityIds], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useOpportunityJourneyActivities }
