import { Account } from './account'

enum Country {
  CH = 'CH',
  DE = 'DE',
  IN = 'IN',
  US = 'US',
}

enum Carrier {
  BLUEDART = 'BLUEDART',
  DHL = 'DHL',
  DHL_EXPRESS = 'DHL_EXPRESS',
  DHL_GERMANY = 'DHL_GERMANY',
  DTDC = 'DTDC',
  FEDEX = 'FEDEX',
  SHREE_ANJANI_COURIER = 'SHREE_ANJANI_COURIER',
  SWISS_POST = 'SWISS_POST',
  UPS = 'UPS',
  USPS = 'USPS',
}

enum ShipmentType {
  LABEL = 'SHIPMENT_LABEL',
  TRACKING = 'SHIPMENT_TRACKING',
  RETURN_LABEL = 'RETURN_SHIPMENT_LABEL',
}

enum ServiceLevel {
  DHL_EXPRESS_PARCEL = 'DHL_EXPRESS_PARCEL',
  DHL_EXPRESS_PARCEL_0900 = 'DHL_EXPRESS_PARCEL_0900',
  DHL_EXPRESS_PARCEL_1030 = 'DHL_EXPRESS_PARCEL_1030',
  DHL_EXPRESS_PARCEL_1200 = 'DHL_EXPRESS_PARCEL_1200',
  DHL_EXPRESS_PARCEL_EU = 'DHL_EXPRESS_PARCEL_EU',
  DHL_EXPRESS_PARCEL_INTERNATIONAL = 'DHL_EXPRESS_PARCEL_INTERNATIONAL',
  DHL_GERMANY_PARCEL = 'DHL_GERMANY_PARCEL',
  DHL_GERMANY_PARCEL_EU = 'DHL_GERMANY_PARCEL_EU',
  DHL_GERMANY_PARCEL_INTERNATIONAL = 'DHL_GERMANY_PARCEL_INTERNATIONAL',
  FEDEX_2_DAY = 'FEDEX_2_DAY',
  FEDEX_2_DAY_AM = 'FEDEX_2_DAY_AM',
  FEDEX_EUROPE_FIRST_INTERNATIONAL_PRIORITY = 'FEDEX_EUROPE_FIRST_INTERNATIONAL_PRIORITY',
  FEDEX_EXPRESS_SAVER = 'FEDEX_EXPRESS_SAVER',
  FEDEX_FIRST_FREIGHT = 'FEDEX_FIRST_FREIGHT',
  FEDEX_FIRST_OVERNIGHT = 'FEDEX_FIRST_OVERNIGHT',
  FEDEX_FREIGHT_ECONOMY = 'FEDEX_FREIGHT_ECONOMY',
  FEDEX_FREIGHT_PRIORITY = 'FEDEX_FREIGHT_PRIORITY',
  FEDEX_GROUND = 'FEDEX_GROUND',
  FEDEX_HOME_DELIVERY = 'FEDEX_HOME_DELIVERY',
  FEDEX_INTERNATIONAL_ECONOMY = 'FEDEX_INTERNATIONAL_ECONOMY',
  FEDEX_INTERNATIONAL_FIRST = 'FEDEX_INTERNATIONAL_FIRST',
  FEDEX_INTERNATIONAL_PRIORITY = 'FEDEX_INTERNATIONAL_PRIORITY',
  FEDEX_INTERNATIONAL_PRIORITY_EXPRESS = 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS',
  FEDEX_PRIORITY_OVERNIGHT = 'FEDEX_PRIORITY_OVERNIGHT',
  FEDEX_STANDARD_OVERNIGHT = 'FEDEX_STANDARD_OVERNIGHT',
  SWISS_POST_EXPRESS_MOON = 'SWISS_POST_EXPRESS_MOON',
  SWISS_POST_POSTPAC_ECONOMY = 'SWISS_POST_POSTPAC_ECONOMY',
  SWISS_POST_POSTPAC_PRIORITY = 'SWISS_POST_POSTPAC_PRIORITY',
  UPS_3_DAY_SELECT = 'UPS_3_DAY_SELECT',
  UPS_EXPEDITED = 'UPS_EXPEDITED',
  UPS_EXPRESS = 'UPS_EXPRESS',
  UPS_EXPRESS_1200 = 'UPS_EXPRESS_1200',
  UPS_EXPRESS_PLUS = 'UPS_EXPRESS_PLUS',
  UPS_EXPRESS_PLUS_WORLDWIDE = 'UPS_EXPRESS_PLUS_WORLDWIDE',
  UPS_EXPRESS_WORLDWIDE = 'UPS_EXPRESS_WORLDWIDE',
  UPS_GROUND = 'UPS_GROUND',
  UPS_NEXT_DAY_AIR = 'UPS_NEXT_DAY_AIR',
  UPS_NEXT_DAY_AIR_EARLY_AM = 'UPS_NEXT_DAY_AIR_EARLY_AM',
  UPS_NEXT_DAY_AIR_SAVER = 'UPS_NEXT_DAY_AIR_SAVER',
  UPS_SAVER = 'UPS_SAVER',
  UPS_SECOND_DAY_AIR = 'UPS_SECOND_DAY_AIR',
  UPS_SECOND_DAY_AIR_AM = 'UPS_SECOND_DAY_AIR_AM',
  UPS_STANDARD = 'UPS_STANDARD',
  UPS_SUREPOST = 'UPS_SUREPOST',
  USPS_FIRST = 'USPS_FIRST',
  USPS_FIRST_CLASS_PACKAGE_INTERNATIONAL_SERVICE = 'USPS_FIRST_CLASS_PACKAGE_INTERNATIONAL_SERVICE',
  USPS_MEDIA_MAIL = 'USPS_MEDIA_MAIL',
  USPS_PARCEL_SELECT = 'USPS_PARCEL_SELECT',
  USPS_PRIORITY = 'USPS_PRIORITY',
  USPS_PRIORITY_EXPRESS = 'USPS_PRIORITY_EXPRESS',
  USPS_PRIORITY_MAIL_EXPRESS_INTERNATIONAL = 'USPS_PRIORITY_MAIL_EXPRESS_INTERNATIONAL',
  USPS_PRIORITY_MAIL_INTERNATIONAL = 'USPS_PRIORITY_MAIL_INTERNATIONAL',
}

enum ReturnServiceLevel {
  UPS_RETURN_ELECTRONIC_LABEL = 'UPS_RETURN_ELECTRONIC_LABEL',
  UPS_RETURN_PICKUP_1_ATTEMPT = 'UPS_RETURN_PICKUP_1_ATTEMPT',
  UPS_RETURN_PICKUP_3_ATTEMPT = 'UPS_RETURN_PICKUP_3_ATTEMPT',
  UPS_RETURN_PRINT_AND_MAIL_LABEL = 'UPS_RETURN_PRINT_AND_MAIL',
  UPS_RETURN_PRINT_LABEL = 'UPS_RETURN_PRINT_LABEL',
}

enum OrderTypes {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  RETURN_PURCHASE_ORDER = 'RETURN_PURCHASE_ORDER',
  RETURN_WAREHOUSE_ORDER = 'RETURN_WAREHOUSE_ORDER',
  WAREHOUSE_ORDER = 'WAREHOUSE_ORDER',
}

enum LabelStatus {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  REFUND_PENDING = 'REFUND_PENDING',
  REFUND_REJECTED = 'REFUND_REJECTED',
  REFUNDED = 'REFUNDED',
  SUCCESS = 'SUCCESS',
}

enum MassUnit {
  KG = 'KG',
  LB = 'LB',
}

enum DistanceUnit {
  CM = 'CM',
  IN = 'IN',
}

enum TrackingStatus {
  AVAILABLE_FOR_PICKUP = 'AVAILABLE_FOR_PICKUP',
  DELIVERED = 'DELIVERED',
  EXCEPTION = 'EXCEPTION',
  FAILED_ATTEMPT = 'FAILED_ATTEMPT',
  IN_DELIVERY = 'IN_DELIVERY',
  INFO_RECEIVED = 'INFO_RECEIVED',
  PENDING = 'PENDING',
  RETURNED = 'RETURNED',
  TRANSIT = 'TRANSIT',
}

enum TrackingSubstatus {
  ADDRESS_ISSUE = 'ADDRESS_ISSUE',
  ARRIVED_AT_DESTINATION_COUNTRY = 'ARRIVED_AT_DESTINATION_COUNTRY',
  AVAILABLE_FOR_PICKUP = 'AVAILABLE_FOR_PICKUP',
  CONTACT_CARRIER = 'CONTACT_CARRIER',
  CUSTOMER_CONTACTED = 'CUSTOMER_CONTACTED',
  CUSTOMER_MOVED = 'CUSTOMER_MOVED',
  CUSTOMER_PICKED_UP = 'CUSTOMER_PICKED_UP',
  CUSTOMER_REFUSED = 'CUSTOMER_REFUSED',
  CUSTOMS_COMPLETED = 'CUSTOMS_COMPLETED',
  CUSTOMS_STARTED = 'CUSTOMS_STARTED',
  DELAYED_CUSTOMS = 'DELAYED_CUSTOMS',
  DELAYED_EXTERNAL = 'DELAYED_EXTERNAL',
  DELIVERED = 'DELIVERED',
  DELIVERED_AND_PAID = 'DELIVERED_AND_PAID',
  DELIVERED_AND_SIGNED = 'DELIVERED_AND_SIGNED',
  DELIVERY_ATTEMPTED = 'DELIVERY_ATTEMPTED',
  DELIVERY_SCHEDULED = 'DELIVERY_SCHEDULED',
  EXCEPTION = 'EXCEPTION',
  EXPIRED = 'EXPIRED',
  IN_TRANSIT = 'IN_TRANSIT',
  INFORMATION_RECEIVED = 'INFORMATION_RECEIVED',
  LOCATION_INACCESSIBLE = 'LOCATION_INACCESSIBLE',
  NOTICE_LEFT = 'NOTICE_LEFT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  PACKAGE_ACCEPTED = 'PACKAGE_ACCEPTED',
  PACKAGE_ARRIVED = 'PACKAGE_ARRIVED',
  PACKAGE_DAMAGED = 'PACKAGE_DAMAGED',
  PACKAGE_DEPARTED = 'PACKAGE_DEPARTED',
  PACKAGE_FORWARDED = 'PACKAGE_FORWARDED',
  PACKAGE_HELD = 'PACKAGE_HELD',
  PACKAGE_LOST = 'PACKAGE_LOST',
  PENDING = 'PENDING',
  PICKUP_MISSED = 'PICKUP_MISSED',
  PROBLEM_RESOLVED = 'PROBLEM_RESOLVED',
  RETURNED_TO_SENDER = 'RETURNED_TO_SENDER',
  RETURNING_TO_SENDER = 'RETURNING_TO_SENDER',
}

interface ShipmentLineItem {
  id?: string
  product_id?: string
  product_category_code?: string
  description: string
  quantity: number
}

interface Parcel {
  length: number
  width: number
  height: number
  distance_unit: DistanceUnit
  weight: number
  mass_unit: MassUnit
  line_items?: Array<ShipmentLineItem>
}

interface Label {
  id: string
  status: LabelStatus
  url: string
}

interface Address {
  address_line_1: string
  address_line_2?: string
  address_line_3?: string
  city: string
  company_name?: string
  contact_name: string
  country: string
  email?: string
  phone?: string
  postal_code: string
  state: string
  street_no?: string
}

interface Checkpoint {
  id: string
  external_id: string
  country: string | null
  state: string | null
  city: string | null
  postal_code: string | null
  tracking_status: string
  tracking_substatus: string
  tracking_status_details: string
  tracking_substatus_details: string
  checkpoint_timestamp: Date
  created_at: Date
  updated_at: Date
  shipment_id: string
}

interface Order {
  gid: string
  type: OrderTypes
}

interface Shipment {
  address_from?: Address
  address_to?: Address
  carrier_return_service_level?: ReturnServiceLevel
  carrier_service_level?: ServiceLevel
  carrier?: Carrier
  country: Country
  created_at: Date
  created_by?: Account
  customer_gid?: string
  description: string
  gid: string
  id: string
  label?: Label
  opportunity_gid?: string
  order?: Order
  parcel?: Parcel
  tracking_checkpoints: Array<Checkpoint>
  tracking_id: string
  tracking_number: string
  tracking_status_details: string
  tracking_status: TrackingStatus
  tracking_substatus_details: string
  tracking_substatus: TrackingSubstatus
  tracking_url: string
  type: ShipmentType
  updated_at: Date
  updated_by?: Account
}

export type { Shipment }
export { ShipmentType, TrackingStatus as ShipmentTrackingStatus }
