enum OpportunityStatus {
  APPOINTMENT_CANCELLED = 'appointment_cancelled',
  APPOINTMENT_SCHEDULED = 'appointment_scheduled',
  HEARING_AIDS_RETURNED = 'hearing_aids_returned',
  IN_TRIAL = 'in_trial',
  PAYMENT_POSTED = 'payment_posted',
  TRIAL_COMPLETE = 'trial_complete',
  UNASSIGNED = 'unassigned',
}

export { OpportunityStatus }
