import { Account } from './account'

enum Country {
  CA = 'CA',
  CH = 'CH',
  DE = 'DE',
  FR = 'FR',
  IN = 'IN',
  KR = 'KR',
  MY = 'MY',
  NL = 'NL',
  US = 'US',
  ZA = 'ZA',
}

enum DeliveryLocationType {
  CUSTOMER = 'CUSTOMER',
  PARTNER_SHOP = 'PARTNER_SHOP',
  WAREHOUSE = 'WAREHOUSE',
}

enum DeliveryModel {
  DIRECT_TO_CONSUMER = 'DIRECT_TO_CONSUMER',
  NETWORK = 'NETWORK',
  NETWORK_HOME_DELIVERY = 'NETWORK_HOME_DELIVERY',
  TELEAUDIOLOGY = 'TELEAUDIOLOGY',
}

enum Journey {
  DIRECT_TO_CONSUMER_STANDARD = 'DIRECT_TO_CONSUMER_STANDARD',

  NETWORK_BEST_CHOICE = 'NETWORK_BEST_CHOICE',
  /**
   * @deprecated This journey is no longer used
   */
  NETWORK_DIRECT_SALE = 'NETWORK_DIRECT_SALE',
  NETWORK_HEAR_TODAY = 'NETWORK_HEAR_TODAY',
  /**
   * @deprecated Use NETWORK_MEDICARE_ADVANTAGE instead
   */
  NETWORK_MEDICAL_ADVANTAGE = 'NETWORK_MEDICAL_ADVANTAGE',
  NETWORK_MEDICARE_ADVANTAGE = 'NETWORK_MEDICARE_ADVANTAGE',
  NETWORK_STANDARD = 'NETWORK_STANDARD',

  NETWORK_HOME_DELIVERY_BEST_CHOICE = 'NETWORK_HOME_DELIVERY_BEST_CHOICE',
  NETWORK_HOME_DELIVERY_HEAR_TODAY = 'NETWORK_HOME_DELIVERY_HEAR_TODAY',
  NETWORK_HOME_DELIVERY_MEDICARE_ADVANTAGE = 'NETWORK_HOME_DELIVERY_MEDICARE_ADVANTAGE',
  NETWORK_HOME_DELIVERY_STANDARD = 'NETWORK_HOME_DELIVERY_STANDARD',

  TELEAUDIOLOGY_BEST_CHOICE = 'TELEAUDIOLOGY_BEST_CHOICE',
  TELEAUDIOLOGY_HEAR_TODAY = 'TELEAUDIOLOGY_HEAR_TODAY',
  /**
   * @deprecated Use TELEAUDIOLOGY_MEDICARE_ADVANTAGE instead
   */
  TELEAUDIOLOGY_MEDICAL_ADVANTAGE = 'TELEAUDIOLOGY_MEDICAL_ADVANTAGE',
  TELEAUDIOLOGY_MEDICARE_ADVANTAGE = 'TELEAUDIOLOGY_MEDICARE_ADVANTAGE',
  TELEAUDIOLOGY_STANDARD = 'TELEAUDIOLOGY_STANDARD',
}

enum Solution {
  /**
   * @deprecated Use MEDICARE_ADVANTAGE instead
   */
  ADVANTAGE = 'ADVANTAGE',
  BEST_CHOICE = 'BEST_CHOICE',
  /**
   * @deprecated This solution is no longer used
   */
  DIRECT_SALE = 'DIRECT_SALE',
  HEAR_TODAY = 'HEAR_TODAY',
  /**
   * @deprecated Use MEDICARE_ADVANTAGE instead
   */
  MEDICAL_ADVANTAGE = 'MEDICAL_ADVANTAGE',
  MEDICARE_ADVANTAGE = 'MEDICARE_ADVANTAGE',
  OVER_THE_COUNTER = 'OVER_THE_COUNTER',
  STANDARD = 'STANDARD',
}

enum Side {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

enum SalesOrderLineStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  DELIVERED = 'DELIVERED',
  EXTERNAL_PROCESSING = 'EXTERNAL_PROCESSING',
  INTERNAL_PROCESSING = 'INTERNAL_PROCESSING',
  SHIPPED = 'SHIPPED',
}

enum SalesOrderStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  DELIVERED = 'DELIVERED',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  PROCESSING = 'PROCESSING',
  SHIPPED = 'SHIPPED',
}

enum Subcategory {
  BATTERY = 'BATTERY',
  CHARGER = 'CHARGER',
  COLORKIT = 'COLORKIT',
  CROS = 'CROS',
  DEMO = 'DEMO',
  DOME = 'DOME',
  EARMOLD = 'EARMOLD',
  EARMOLDUPGR = 'EARMOLDUPGR',
  INSURANCE = 'INSURANCE',
  MICROPHONE = 'MICROPHONE',
  MOLDREC = 'MOLDREC',
  PHONE = 'PHONE',
  REMOTE = 'REMOTE',
  REPAIR = 'REPAIR',
  SDCON = 'SDCON',
  SDHA = 'SDHA',
  SDMISC = 'SDMISC',
  SDREC = 'SDREC',
  SDSERV = 'SDSERV',
  STREAMER = 'STREAMER',
  TUBE = 'TUBE',
  VOUCHER = 'VOUCHER',
  WARRANTY = 'WARRANTY',
}

enum Category {
  CON = 'CON',
  HA = 'HA',
  MISC = 'MISC',
  MOBILE = 'MOBILE',
  REC = 'REC',
  SERV = 'SERV',
  TABLET = 'TABLET',
}

interface Address {
  contactName: string
  companyName?: string
  streetNo?: string
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  state: string
  country: string
  postalCode: string
  phone?: string
  email?: string
}

interface DeliveryLocation {
  id: string
  gid: string
  countryCode: Country
  type: DeliveryLocationType
  name: string
  address: Address
  createdAt: Date
  updatedAt: Date
}

interface Color {
  id: string
  gid: string
  code: string
  name: string
}

interface InventoryItem {
  id: string
  gid?: number
  itemId?: string
  itemColorId?: string
  serialNumber?: string
  stockId?: string
}

interface SalesOrderItem {
  id: string
  gid: string
  code: string
  name: string
  color?: Color
  categoryCode: Category
  subcategoryCode?: Subcategory
  isTrackable: boolean
}

interface JourneyDetails {
  journey: Journey
  deliveryModel: DeliveryModel
  solution: Solution
}

enum SalesOrderType {
  FIRST_APPOINTMENT = 'FIRST_APPOINTMENT',
  FOLLOW_UP = 'FOLLOW_UP',
  INITIAL = 'INITIAL',
}

interface SalesOrderStatusHistory {
  id: string
  salesOrderId: string
  status: SalesOrderStatus
  createdAt: Date
  createdBy: Account
}

interface SalesOrderLineStatusHistory {
  id: string
  salesOrderId: string
  salesOrderLineId: string
  status: SalesOrderLineStatus
  createdAt: Date
  createdBy: Account
}

interface SalesOrderLine {
  id: string
  gid: string
  status: SalesOrderLineStatus
  side?: Side
  item: SalesOrderItem
  quantity: number
  inventoryItem?: InventoryItem
  createdAt: Date
  updatedAt: Date
}

interface SalesOrderHeader {
  id: string
  gid: string
  countryCode: Country
  customerGid: string
  opportunityGid?: string
  journeyDetails?: JourneyDetails
  status: SalesOrderStatus
  type: SalesOrderType
  createdAt: Date
  updatedAt: Date
  createdBy: Account
  updatedBy: Account
}

interface PersistedSalesOrder extends SalesOrderHeader {
  lines: SalesOrderLine[]
  deliveryLocationId: string
}

interface SalesOrder extends SalesOrderHeader {
  lines: SalesOrderLine[]
  deliveryLocation: DeliveryLocation
}

enum SalesOrderLineAction {
  CANCEL = 'CANCEL',
  UNCANCEL = 'UNCANCEL',
  PROCESS_INTERNALLY = 'PROCESS_INTERNALLY',
  PROCESS_EXTERNALLY = 'PROCESS_EXTERNALLY',
  UNPROCESS = 'UNPROCESS',
  SHIP = 'SHIP',
  UNSHIP = 'UNSHIP',
  DELIVER = 'DELIVER',
  UNDELIVER = 'UNDELIVER',
}

enum SalesOrderAction {
  CANCEL = 'CANCEL',
  UNCANCEL = 'UNCANCEL',
}

enum SalesOrderRelationType {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  WAREHOUSE_ORDER = 'WAREHOUSE_ORDER',
  SHIPMENT = 'SHIPMENT',
  RETURN_WAREHOUSE_ORDER = 'RETURN_WAREHOUSE_ORDER',
}

interface SalesOrderRelation {
  id: string
  salesOrderId: string
  salesOrderGid: string
  salesOrderLineId?: string
  salesOrderLineGid?: string
  relatedId: string
  relatedGid: string
  relatedLineId?: string
  relatedLineGid?: string
  type: SalesOrderRelationType
  createdAt: Date
}

export type {
  PersistedSalesOrder,
  SalesOrder,
  SalesOrderHeader,
  SalesOrderItem,
  SalesOrderLine,
  SalesOrderLineStatusHistory,
  SalesOrderRelation,
  SalesOrderStatusHistory,
}
export {
  SalesOrderAction,
  SalesOrderLineAction,
  SalesOrderLineStatus,
  SalesOrderRelationType,
  SalesOrderStatus,
  SalesOrderType,
}
