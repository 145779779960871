import { MRS_URL } from 'config'
import { defaultPagination, HttpMethod } from 'constants/'
import { useCallback } from 'react'
import { CouplingInfo, FormTemplate, MedicalRecord, NoteRequest, NotesPage, Query } from 'types'
import { identity, omit, pick } from 'utils'
import { useRequest } from '../useRequest'

enum ApiPathname {
  CUSTOMER_OPPORTUNITY_COUPLING = `/customers/:customerGid/opportunity/:opportunityGid/coupling`,
  MEDICAL_RECORDS = `/customers/:customerGid/opportunity/:opportunityGid/medical-records`,
  NOTES = `/customers/:customerGid/notes`,
  NOTES_CREATE = `/customers/:customerGid/opportunity/:opportunityGid/notes`,
  NOTE_TEMPLATE = `/note-templates/:templateName`,
}

type GetCouplingQuery = Partial<{ customerGid: string; opportunityGid: string }>
type GetCouplingResponse = CouplingInfo

type GetMedicalRecordQuery = Partial<{
  customerGid: string
  opportunityGid: string
}>

type GetMedicalRecordResponse = MedicalRecord

type GetNotesQuery = Query<{ customerGid: string }>
type GetNotesResponse = NotesPage

type GetNoteTemplateQuery = { templateName: string }
type GetNoteTemplateResponse = FormTemplate

type CreateNoteRequest = NoteRequest
type CreateNoteResponse = string

interface UseHookContext {
  createNote: (query: CreateNoteRequest) => Promise<CreateNoteResponse>
  getCoupling: (query: GetCouplingQuery) => Promise<GetCouplingResponse>
  getMedicalRecord: (query: GetMedicalRecordQuery) => Promise<GetMedicalRecordResponse>
  getNotes: (query: GetNotesQuery) => Promise<GetNotesResponse>
  getNoteTemplate: (query: GetNoteTemplateQuery) => Promise<GetNoteTemplateResponse>
}

const baseUrl = new URL(MRS_URL)

const useMedicalRecordsService = (): UseHookContext => {
  const createNote = useRequest<CreateNoteRequest, CreateNoteResponse>({
    baseUrl,
    filterQueryToRequestBody: identity,
    filterQueryToRouteParams: ({ customer_gid: customerGid, opportunity_gid: opportunityGid }: CreateNoteRequest) => ({
      customerGid,
      opportunityGid,
    }),
    method: HttpMethod.POST,
    pathname: ApiPathname.NOTES_CREATE,
    transformResponse: (response: Response) => response.text(),
  })

  const getCoupling = useRequest<GetCouplingQuery, GetCouplingResponse>({
    baseUrl,
    filterQueryToRouteParams: identity,
    pathname: ApiPathname.CUSTOMER_OPPORTUNITY_COUPLING,
  })

  const getMedicalRecord = useRequest<GetMedicalRecordQuery, GetMedicalRecordResponse>({
    baseUrl,
    filterQueryToRouteParams: identity,
    pathname: ApiPathname.MEDICAL_RECORDS,
  })

  const getNotesRequest = useRequest<GetNotesQuery, GetNotesResponse>({
    baseUrl,
    filterQueryToRouteParams: pick(['customerGid']),
    filterQueryToSearchParams: omit(['customerGid']),
    pathname: ApiPathname.NOTES,
  })

  const getNotes = useCallback(
    (query: GetNotesQuery) =>
      getNotesRequest({
        ...defaultPagination,
        ...query,
      }),
    [getNotesRequest]
  )
  const getNoteTemplate = useRequest<GetNoteTemplateQuery, GetNoteTemplateResponse>({
    baseUrl,
    filterQueryToRouteParams: pick(['templateName']),
    pathname: ApiPathname.NOTE_TEMPLATE,
  })

  return {
    createNote,
    getCoupling,
    getMedicalRecord,
    getNoteTemplate,
    getNotes,
  }
}

export { useMedicalRecordsService }
