import { Notifications } from './Notifications'
import { Profile } from './Profile'

const UserItems = () => {
  return (
    <>
      <Notifications />
      <Profile />
    </>
  )
}

export { UserItems }
