import { Typography } from '@material-ui/core'
import type { SearchResult } from '../types'
import { useStyles } from './index.styles'
import { JourneyStatus, SalesStatus } from 'types'
import { opportunityStatusToDisplayText, salesStatusToDisplayText } from './opportunityStatusToDisplayText'

interface Props {
  classes: Record<string, string>
  result: SearchResult
  adminOnly?: boolean
}

const Result = ({ classes, result, adminOnly }: Props) => {
  const statusStyles = useStyles()
  const { customer, opportunity } = result
  const city = customer?.billingAddress?.city
  const state = customer?.billingAddress?.state
  const country = customer?.billingAddress?.country
  const email = customer?.email

  let address = undefined

  switch (true) {
    case Boolean(city && state && country):
      address = `${city}, ${state}, ${country}`
      break
    case Boolean(state && country):
      address = `${state}, ${country}`
      break
    case Boolean(city && country):
      address = `${city}, ${country}`
      break
    case Boolean(city):
      address = city
      break
    case Boolean(country):
      address = country
      break
    default:
      break
  }

  const salesStatusOpen = [SalesStatus.OPEN, SalesStatus.WON]
  const salesStatusLost = [SalesStatus.LOST, SalesStatus.RETURNED]
  const journeyStatusOpen = [
    JourneyStatus.OPEN,
    JourneyStatus.IN_TRIAL,
    JourneyStatus.PREPARING_FOR_FITTING_APPOINTMENT,
  ]

  const actualAddress = <>{address ? address : <i>{'location unknown'}</i>}</>
  const actualEmail = <>{email ? ` / ${email}` : ''}</>
  const isOpportunityInProgress = journeyStatusOpen.includes(opportunity.journeyStatus.status)
  let opportunityStatusStyle = statusStyles.opportunityClosed

  const isSaleInProgress = salesStatusOpen.includes(opportunity.salesStatus.status)

  let statusDisplayText = ''

  if (isOpportunityInProgress) {
    if (isSaleInProgress) {
      if (
        opportunity.journeyStatus.status === JourneyStatus.IN_TRIAL ||
        opportunity.journeyStatus.status === JourneyStatus.PREPARING_FOR_FITTING_APPOINTMENT
      ) {
        statusDisplayText = opportunityStatusToDisplayText(opportunity.journeyStatus.status)
      } else {
        statusDisplayText = salesStatusToDisplayText(opportunity.salesStatus.status)
      }
      opportunityStatusStyle = statusStyles.opportunityOpen
    } else {
      statusDisplayText = salesStatusToDisplayText(opportunity.salesStatus.status)
      opportunityStatusStyle = statusStyles.opportunityLost
    }
  } else {
    if (opportunity.salesStatus.status === SalesStatus.WON) {
      opportunityStatusStyle = statusStyles.opportunityWon
    }
    if (opportunity.salesStatus.status === SalesStatus.OPEN) {
      opportunityStatusStyle = statusStyles.opportunityClosed
    }
    if (salesStatusLost.includes(opportunity.salesStatus.status)) {
      opportunityStatusStyle = statusStyles.opportunityLost
    }
    statusDisplayText = salesStatusToDisplayText(opportunity.salesStatus.status)
  }

  return (
    <div className={statusStyles.customerNameRow}>
      <div className={statusStyles.customerName}>
        <Typography className={classes.resultTitle}>
          {customer.givenName} {customer.familyName}
        </Typography>
      </div>
      <div className={statusStyles.floatRight}>
        <div className={opportunityStatusStyle}>
          <Typography variant="body2">{statusDisplayText}</Typography>
          {adminOnly ? (
            <div style={{ color: 'transparent' }}>
              <Typography variant="caption"> Originally Filtered Out </Typography>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Typography className={classes.resultSubtitle} variant="body2">
        {actualAddress}
        {actualEmail}
      </Typography>
    </div>
  )
}

export { Result }
