import { CustomerProvider } from 'hooks'
import { lazy } from 'react'
import { Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../NotFound'

const PostAppointmentOutcomeForm = lazy(() =>
  import(
    /* webpackChunkName: "PostAppointmentOutcomeForm" */
    'components/PostAppointmentOutcomeForm'
  ).then(namedExportToDefaultExport('PostAppointmentOutcomeForm'))
)

const Forms = () => (
  <Routes>
    <Route element={<NotFound />} index />
    <Route
      element={
        <CustomerProvider>
          <PostAppointmentOutcomeForm />
        </CustomerProvider>
      }
      path="post-appointment-form/:customerId"
    />
    <Route element={<NotFound />} path="*" />
  </Routes>
)

export { Forms }
