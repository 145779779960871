import { OLS_URL } from 'config'
import { defaultPagination } from 'constants/'
import { useCallback } from 'react'
import { OpportunityLifecycle, QueryResponse } from 'types'
import { identity } from 'utils'
import { useRequest } from '../useRequest'

enum ApiPathname {
  OPPORTUNITY_LIFECYCLES_PATHNAME = '/opportunity-lifecycles',
}

type GetOpportunityLifecyclesQuery = Partial<{
  customer_gid: string[]
  fitter_gid: string
  opportunity_gid: string
  page_size: number
  page: number
  sort_by: string
  sort: string
  status: string
  strict: boolean
}>

type GetOpportunityLifecyclesResponse = QueryResponse<OpportunityLifecycle[]>

interface UseHookContext {
  getOpportunityLifecycles: (query: GetOpportunityLifecyclesQuery) => Promise<GetOpportunityLifecyclesResponse>
}

const baseUrl = new URL(OLS_URL)
const filterQueryToSearchParams = identity // Take all that is passed in and apply to query params

const useOpportunityLifecyclesService = (): UseHookContext => {
  const getOpportunityLifecyclesRequest = useRequest<GetOpportunityLifecyclesQuery, GetOpportunityLifecyclesResponse>({
    baseUrl,
    filterQueryToSearchParams,
    pathname: ApiPathname.OPPORTUNITY_LIFECYCLES_PATHNAME,
  })

  const getOpportunityLifecycles = useCallback(
    (query: GetOpportunityLifecyclesQuery) =>
      getOpportunityLifecyclesRequest({
        ...defaultPagination,
        ...query,
      }),
    [getOpportunityLifecyclesRequest]
  )

  return { getOpportunityLifecycles }
}

export { useOpportunityLifecyclesService }
