import { HearingTestSource, HearingTestTransducer } from 'constants/'
import { HearingTestServiceConductionType } from './hearingTest'

interface WordList {
  list: string
  voice: string
}

enum SpeechTestTypeDesignation {
  MCL = 'MCL',
  SAT = 'SAT',
  SRT = 'SRT',
  UCL = 'UCL',
}

type SpeechTestType = Partial<Record<SpeechTestTypeDesignation, SpeechTestServiceTest>>

type SpeechTestResult = {
  masking_sound_level?: number
  recognition_score?: number
  sound_level?: number
}

type TestEarSide = {
  is_hearing_aid_equipped: boolean
  results: SpeechTestResult[]
  tested_at: string
}

interface SpeechTestServiceTest {
  binaural: TestEarSide
  conduction_type: HearingTestServiceConductionType
  created_at: string
  customer_id: string
  fitter_id: string
  id: string
  left_ear: TestEarSide
  right_ear: TestEarSide
  source: HearingTestSource
  transducer_type: HearingTestTransducer
  type: SpeechTestTypeDesignation
  updated_at: string
  word_list?: WordList
}

export type { SpeechTestType, SpeechTestServiceTest, SpeechTestResult, TestEarSide }
export { SpeechTestTypeDesignation }
