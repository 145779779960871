import { COCKPIT_URL } from 'config'
import { Appointment } from 'types'
import { hydrateRoute } from 'utils'

const baseUrl = new URL(COCKPIT_URL)

type AppointmentInfo = Pick<Appointment, 'country' | 'gid' | 'id' | 'opportunity_gid' | 'start_date' | 'status'>

interface QueryParams {
  appointmentGid: string
}

const useFittingLink = (fittingId?: string, appointmentInfo: Partial<AppointmentInfo> = {}) => {
  if (!fittingId) {
    return '#'
  }

  const pathname = hydrateRoute('/fitting/:fittingId', { fittingId })
  const url = new URL(pathname, baseUrl)

  const queryParams: Partial<QueryParams> = {
    appointmentGid: appointmentInfo.gid || appointmentInfo.id,
  }

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      return
    }

    url.searchParams.set(key, value.toString())
  })

  return url.href
}

export { useFittingLink }
