import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { AppointmentCancellationReason, AppointmentRescheduleReason } from 'constants/'
import { makeTranslationProxy } from '../util'

type TranslationKey = AppointmentRescheduleReason | AppointmentCancellationReason

const useAppointmentCancellationReasonTranslations = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      CUSTOMER_NOT_AVAILABLE: (options) => t('appointment_cancellation_reason.CUSTOMER_NOT_AVAILABLE', options),
      CUSTOMER_NOT_INTERESTED: (options) => t('appointment_cancellation_reason.CUSTOMER_NOT_INTERESTED', options),
      OTHER: (options) => t('appointment_cancellation_reason.OTHER', options),
      SYSTEM_ROLLBACK: (options) => t('appointment_cancellation_reason.SYSTEM_ROLLBACK', options),
      WAX_OCCLUSION: (options) => t('appointment_cancellation_reason.WAX_OCCLUSION', options),
    }

    return makeTranslationProxy(translations, t('appointment_status_reason_text.no_status_reason'))
  }, [t])
}

export { useAppointmentCancellationReasonTranslations }
