import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    customerName: {
      display: 'inline-block',
    },
    customerNameRow: {
      width: '100%',
    },
    floatRight: {
      float: 'right',
    },
    opportunityOpen: {
      display: 'inline-block',
      color: '#2A2',
      opacity: '0.75',
      textAlign: 'right',
    },
    opportunityWon: {
      display: 'inline-block',
      color: '#2A2',
      opacity: '0.75',
      textAlign: 'right',
    },
    opportunityClosed: {
      display: 'inline-block',
      color: '#AAA',
      opacity: '0.75',
      textAlign: 'right',
    },
    opportunityLost: {
      display: 'inline-block',
      paddingLeft: '0.25rem',
      color: '#F22',
      opacity: '0.75',
      textAlign: 'right',
    },
  })
) as unknown as UseStylesFunction
