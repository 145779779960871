import { Typography } from '@material-ui/core'
import cx from 'clsx'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useStyles } from './index.styles'
import { useTranslation } from 'react-i18next'

interface Props {
  children?: ReactNode
  className?: string
  pageTitle?: string
  title: string
}

const PageTitle = ({ children, className, pageTitle, title }: Props) => {
  const styles = useStyles()
  const [isPinned, bePinned] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const appTitle = t('general.app_title')

  useEffect(() => {
    const el = ref.current

    const observer = new IntersectionObserver(([e]) => bePinned(e.intersectionRatio < 1), {
      rootMargin: '-88px 0px 0px 0px',
      threshold: [1],
    })

    observer.observe(el as HTMLDivElement)

    return () => observer.disconnect()
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${appTitle} - ${pageTitle ?? title}`}</title>
      </Helmet>
      <div className={cx(styles.container, { [styles.pinned]: isPinned })} ref={ref}>
        <Typography className={styles.title} variant="h1">
          {title}
        </Typography>
        {children && <div className={cx(styles.children, className)}>{children}</div>}
        <div className={cx(styles.after, { [styles.afterPinned]: isPinned })} />
      </div>
    </>
  )
}

export { PageTitle }
