import { TranslationHandler } from '../useEnumTranslation'

const makeTranslationProxy = <T extends string>(translations: Record<T, TranslationHandler>, defaultMessage?: string) =>
  new Proxy(translations, {
    get(target, prop) {
      if (prop in target) {
        return (options?: Record<string, string>) => target[prop as keyof typeof target](options)
      } else {
        return () => defaultMessage ?? ''
      }
    },
  })

export { makeTranslationProxy }
