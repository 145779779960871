import { Suspense } from 'react'
import { Outlet } from 'react-router'
import { ErrorBoundary } from '../../ErrorBoundary'
import { Loader } from '../../Loader'
import { useIsPostAppointmentFormRoute } from '../useIsPostAppointmentForm'
import { Breadcrumbs } from './Breadcrumbs'
import { GlobalStyleOverrides } from './GlobalStyleOverrides'
import { Sidebar } from './Sidebar'
import { useStyles } from './index.styles'

const Main = () => {
  const classes = useStyles()
  const isPostAppointmentFormRoute = useIsPostAppointmentFormRoute()
  const showBreadcrumbs = !isPostAppointmentFormRoute

  return (
    <Suspense fallback={<Loader hasDelay />}>
      <ErrorBoundary>
        {!isPostAppointmentFormRoute ? (
          <nav className={classes.nav}>
            <Sidebar />
          </nav>
        ) : (
          // TODO: This should be removed when we turn on the side bar navigation permanently
          <GlobalStyleOverrides />
        )}
        <main className={classes.main} data-testid="main-container">
          <>
            {showBreadcrumbs && <Breadcrumbs />}
            <Outlet />
          </>
        </main>
      </ErrorBoundary>
    </Suspense>
  )
}

export { Main }
