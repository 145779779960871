import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetAudiogramQuery, useHearingTestService } from '../services'
import { groupPureToneTests } from './groupPureToneTests'

const hookName = 'useLatestAudiograms'
const log = logFactory(hookName)

const useLatestAudiograms = (customerGid?: string, options: QueryOptions = {}) => {
  const { getAudiograms } = useHearingTestService()

  const parameters = {
    customerId: customerGid as string,
  }

  const doQuery = async () => {
    const airConductionParams: GetAudiogramQuery = {
      ...parameters,
      conductionType: 'AC',
      sort: 'desc',
      sort_by: 'created_at',
    }

    const airConductionUCLParams: GetAudiogramQuery = {
      ...airConductionParams,
      type: 'UCL',
    }

    const boneConductionParams: GetAudiogramQuery = {
      ...parameters,
      conductionType: 'BC',
      sort: 'desc',
      sort_by: 'created_at',
    }

    log(`👂 Fetching latest air conduction test result...`, airConductionParams)
    const airConductionPromise = getAudiograms(airConductionParams)

    log(`👂 Fetching latest air conduction test result...`, airConductionUCLParams)
    const airConductionUCLPromise = getAudiograms(airConductionUCLParams)

    log(`👂 Fetching latest air conduction test result...`, boneConductionParams)
    const boneConductionPromise = getAudiograms(boneConductionParams)

    const [airConductionResponse, boneConductionResponse, airConductionUCLResponse] = await Promise.all([
      airConductionPromise,
      boneConductionPromise,
      airConductionUCLPromise,
    ])

    log(`👂 Found latest test results for customer: ${customerGid}`, {
      airConductionResponse,
      airConductionUCLResponse,
      boneConductionResponse,
    })

    const grouped = groupPureToneTests([
      ...airConductionResponse.data,
      ...boneConductionResponse.data,
      ...airConductionUCLResponse.data,
    ])

    log('🦻 Latest group test results', grouped)

    return grouped
  }

  return useQuery([hookName, parameters], doQuery, {
    ...defaultQueryOptions,
    enabled: Boolean(customerGid),
    ...options,
  })
}

export { useLatestAudiograms }
