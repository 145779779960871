import { CustomerProvider, OpportunityLifecycleProvider } from 'hooks'
import { lazy } from 'react'
import { Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../NotFound'

const Index = lazy(() =>
  import(
    /* webpackChunkName: "CustomersGlobalSearch" */
    'components/CustomersGlobalSearch'
  ).then(namedExportToDefaultExport('CustomersGlobalSearch'))
)

const LazyDetails = lazy(() =>
  import(
    /* webpackChunkName: "CustomerDetails" */
    'components/CustomerDetails'
  ).then(namedExportToDefaultExport('CustomerDetails'))
)

const Details = () => (
  <CustomerProvider>
    <OpportunityLifecycleProvider>
      <LazyDetails />
    </OpportunityLifecycleProvider>
  </CustomerProvider>
)

const Router = () => (
  <Routes>
    <Route element={<Index />} index />
    <Route element={<Details />} path=":customerId/*" />
    <Route element={<NotFound />} path="*" />
  </Routes>
)

export { Router as CustomersGlobalSearch }
