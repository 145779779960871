import { LIVECHAT_LICENSE } from 'config'
import { useUser } from 'hooks'
import LiveChatWidget from 'react-livechat'

const license = parseInt(LIVECHAT_LICENSE)

const LiveChat = () => {
  const { accountId } = useUser()

  if (!(license && accountId)) return null

  return <LiveChatWidget license={license} />
}

export default LiveChat
