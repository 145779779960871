import { Badge, BadgeProps } from '@material-ui/core'
import { Notifications as NotificationsIcon } from '@material-ui/icons'
import { Route } from 'constants/'
import { useMyNotifications } from 'hooks'
import { Link } from '../../../../Link'
import { useStyles } from './index.styles'
import { useTranslation } from 'react-i18next'

const MAX_NOTIFICATION_COUNT = 10

const Notifications = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { data: unreadNotifications } = useMyNotifications({
    is_read: false,
    page_size: MAX_NOTIFICATION_COUNT + 1,
  })

  const badgeProps: BadgeProps = unreadNotifications?.length
    ? {
        color: 'error',
        variant: 'dot',
      }
    : {}

  return (
    <Link
      aria-label={t('general.notifications.view_your_notifications_aria_label')}
      className={classes.container}
      to={Route.NOTIFICATIONS}
    >
      <Badge {...badgeProps}>
        <NotificationsIcon />
      </Badge>
    </Link>
  )
}

export { Notifications }
