import { JourneyActivity, JourneyActivityType, Opportunity } from 'types'
import { indexBy, prop, toPairs } from 'utils'

type OpportunityRiskState = 'OK' | 'RETURN_RISK'

type OpportunityFocusReason = 'NO_FOLLOW_UP_SCHEDULED' | 'LOW_WEARING_TIME' | 'PDR_NOT_SIGNED' | 'RETURN_INTENDED'

type OpportunityFocusReasonDetail = {
  isResolved: boolean
  type: OpportunityFocusReason
}

interface Props {
  accumulatedWearingTimeInMinutes?: Opportunity['accumulatedWearingTimeInMinutes']
  focusReasons: OpportunityFocusReasonDetail[]
  journeyActivities: JourneyActivity[]
}

interface HookContext {
  focusReasons: OpportunityFocusReasonDetail[]
  state: OpportunityRiskState
}

const defaultContext: HookContext = {
  focusReasons: [],
  state: 'OK',
}

const isCompleted = (journeyActivity: JourneyActivity) => journeyActivity.isCompleted

const toActivityCompletionMap = (journeyActivities: JourneyActivity[]): Record<JourneyActivityType, boolean> => {
  const journeyActivitiesIndexedByType = indexBy(journeyActivities, prop('type'))
  const pairs = toPairs(journeyActivitiesIndexedByType) as [JourneyActivityType, JourneyActivity][]
  const pairsWithCompletion = pairs.map(([type, journeyActivity]) => [type, isCompleted(journeyActivity)])

  return Object.fromEntries(pairsWithCompletion)
}

const useOpportunityStatus = ({ journeyActivities, focusReasons }: Props): HookContext => {
  const activityCompletionMap = toActivityCompletionMap(journeyActivities)
  const isFollowUpScheduled = activityCompletionMap[JourneyActivityType.FOLLOW_UP_SCHEDULED]

  const isReturnIntended = focusReasons.some(
    (focusReason) => !focusReason.isResolved && focusReason.type === 'RETURN_INTENDED'
  )

  const hasLowWearingTime = focusReasons.some(
    (focusReason) => !focusReason.isResolved && focusReason.type === 'LOW_WEARING_TIME'
  )

  let warnings: OpportunityFocusReasonDetail[] = []

  if (!isFollowUpScheduled) {
    warnings = [...warnings, { isResolved: false, type: 'NO_FOLLOW_UP_SCHEDULED' }]
  }

  if (hasLowWearingTime) {
    warnings = [...warnings, { isResolved: false, type: 'LOW_WEARING_TIME' }]
  }

  if (isReturnIntended) {
    warnings = [...warnings, { isResolved: false, type: 'RETURN_INTENDED' }]
  }

  if (warnings.length) {
    return {
      focusReasons: warnings,
      state: 'RETURN_RISK',
    }
  }

  return defaultContext
}

export { useOpportunityStatus }
export type { OpportunityFocusReason, OpportunityFocusReasonDetail }
