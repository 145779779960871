import { Route as Paths } from 'constants/'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AnonymousDirector } from './AnonymousDirector'
import { AuthenticatedRoutes } from './AuthenticatedRoutes'
import { Providers } from './Providers'

const Authenticated = () => (
  <Providers>
    <AuthenticatedRoutes />
  </Providers>
)

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<AnonymousDirector />} path={Paths.REDIRECT_ANONYMOUS} />
      <Route element={<Authenticated />} path="/*" />
    </Routes>
  </BrowserRouter>
)

export { Router }
