import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { Salutation } from 'constants/'
import { makeTranslationProxy } from '../util'

const useSalutationTranslations = () => {
  const t = useEnumTranslation()

  return useMemo<Record<string, TranslationHandler>>(() => {
    const translations: Record<string, TranslationHandler> = {
      [Salutation.DOCTOR]: (options) => t('salutation.dr', options),
      [Salutation.DOCTOR_MEDICAL]: (options) => t('salutation.dr', options),
      [Salutation.MISS]: (options) => t('salutation.miss', options),
      [Salutation.MISSES]: (options) => t('salutation.mrs', options),
      [Salutation.MISTER]: (options) => t('salutation.mr', options),
      [Salutation.PROFESSOR]: (options) => t('salutation.prof', options),
      [Salutation.PROFESSOR_DOCTOR]: (options) => t('salutation.prof_dr', options),
      [Salutation.PROFESSOR_DOCTOR_DOCTOR]: (options) => t('salutation.prof_dr_dr', options),
      ['Dr']: (options) => t('salutation.dr', options),
      ['Dr.']: (options) => t('salutation.dr', options),
      ['Miss']: (options) => t('salutation.miss', options),
      ['Mr']: (options) => t('salutation.mr', options),
      ['Mr.']: (options) => t('salutation.mr', options),
      ['Mrs']: (options) => t('salutation.mrs', options),
      ['Mrs.']: (options) => t('salutation.mrs', options),
      ['Ms']: (options) => t('salutation.ms', options),
      ['Ms.']: (options) => t('salutation.ms', options),
      ['Prof']: (options) => t('salutation.prof', options),
      ['Prof.']: (options) => t('salutation.prof', options),
      ['Prof. Dr.']: (options) => t('salutation.prof_dr', options),
      ['Prof. Dr. Dr.']: (options) => t('salutation.prof_dr_dr', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useSalutationTranslations }
