export const emptyAudiogram = [
  {
    createdDate: '',
    customerId: '',
    id: '',
    lastModifiedDate: '',
    leftAudiogram: {
      '125': null,
      '250': null,
      '500': null,
      '750': null,
      '1000': null,
      '1500': null,
      '2000': null,
      '3000': null,
      '4000': null,
      '6000': null,
      '8000': null,
    },
    rightAudiogram: {
      '125': null,
      '250': null,
      '500': null,
      '750': null,
      '1000': null,
      '1500': null,
      '2000': null,
      '3000': null,
      '4000': null,
      '6000': null,
      '8000': null,
    },
    title: 'Audiogram',
  },
]
