import { ReactComponent as HearIcon } from 'assets/hear-logo-header.svg'
import { Route } from 'constants/'
import { Link } from '../../../Link'
import { useStyles } from './index.styles'
import { useTranslation } from 'react-i18next'

const Home = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Link
      aria-label={t('general.navigation.text.navigate_to_home_aria_label')}
      className={classes.link}
      to={Route.HOME}
    >
      <HearIcon />
    </Link>
  )
}

export { Home }
