import {
  CommonProp,
  ConsultationSubnoteType,
  MetaFlag,
  MobileAppSetupOutcomeType,
  NoteTemplateName,
  NoteType,
  TechnicalIssueType,
} from 'constants/'
import { Note } from 'hooks/services/useNoteService/types'
import { Appointment, Coupling, DeviceCouplingLeftAndRight } from './'
import { SingleDeviceCoupling } from 'hooks'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseValue = any

enum UiNoteType {
  APPOINTMENT_SERVICE_APPOINTMENT_WITH_DETAIL = 'APPOINTMENT_SERVICE_APPOINTMENT_WITH_DETAIL',
  APPOINTMENT_SERVICE_APPOINTMENT_WITH_LEGACY_PAOF = 'APPOINTMENT_SERVICE_APPOINTMENT_WITH_LEGACY_PAOF',
  DEVICE_SERVICE_COUPLING_WITH_APPOINTMENT = 'DEVICE_SERVICE_COUPLING_WITH_APPOINTMENT',
  DEVICE_SERVICE_COUPLING_WITHOUT_APPOINTMENT = 'DEVICE_SERVICE_COUPLING_WITHOUT_APPOINTMENT',
  INTERACTION_NOTE = 'INTERACTION_NOTE',
  NOTE_SERVICE_NOTE_WITH_RELATED_APPOINTMENT = 'NOTE_SERVICE_NOTE_WITH_RELATED_APPOINTMENT',
  OPPORTUNITY_NOTE = 'OPPORTUNITY_NOTE',
  ORPHAN_LEGACY_APPOINTMENT = 'ORPHAN_LEGACY_APPOINTMENT',
}

type AuthoredBy = {
  email?: string
  id?: string
  system?: string
  type?: string
  photo_url?: string
}

type FormItem = {
  question_values?: ResponseValue[]
  response_value?: string | boolean | string[]
}

type FormTemplate = {
  template_name: string
  responses: Record<string, ResponseValue>
}

type NoteRequest = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addendum: Record<string, any>
  author_gid: string
  customer_gid: string
  is_completed: boolean
  opportunity_gid: string
  responses: Record<string, ResponseValue>
  template_name: string
}

type NoteMeta = {
  [CommonProp.FLAGS]: Record<MetaFlag, boolean>
}

interface NotesPage {
  data: NoteResponse[]
  meta: NoteResponseMeta
}

interface NoteResponse {
  _id: string
  author_gid: string
  created_at: string
  customer_gid: string
  [CommonProp.META]: NoteMeta
  opportunity_gid: string
  [CommonProp.RESPONSES]: Record<string, FormItem>
  [CommonProp.TEMPLATE_NAME]: NoteTemplateName
}

interface NoteTemplate {
  [CommonProp.RESPONSES]: Record<string, FormItem>
  [CommonProp.TEMPLATE_NAME]: NoteTemplateName
}

interface NoteTemplateResponse {
  data: NoteTemplate
}

interface NoteResponseMeta {
  items_total: number
  page: number
  page_size: number
  pages_total: number
}

type UiNote = {
  content?: string
  createdAt: Date
  authoredBy?: AuthoredBy
  provider?: {
    name?: string
    salesforceId?: string
  }
  id: string
  gid?: string
  stylingFlag?: string
  type: NoteType
  subType?: string
}

type ConsultationSubnote = {
  content: string
  type: ConsultationSubnoteType
}

interface ConsultationNote extends UiNote {
  subnotes: ConsultationSubnote[]
}

interface LegacyAppointmentNote extends UiNote {
  didAppointmentTakePlace?: boolean
  nextAppointment?: string
  isFitKitReadyForPickup?: boolean
  isPDRSigned?: boolean
  mobileAppSetupOutcome?: MobileAppSetupOutcomeType
  technicalIssues?: TechnicalIssueType[]
  technicalIssuesComment?: string
}

interface LegacyCouplingNote extends LegacyAppointmentNote {
  coupling?: Coupling
  couplingDelta?: Coupling
  isCustomerKeepingDevices: boolean
}

interface DeviceCouplingDTO {
  hasCoupling: boolean
  createdAt?: string
  createdBy?: AuthoredBy
  id?: string
  isDefaultCoupling?: boolean
  appointmentId?: string
  appointmentGid?: string
}

interface DeviceCouplingNote extends UiNote {
  appointmentGid?: string
  coupling: DeviceCouplingLeftAndRight
  createdAt: Date
  provider: {
    salesforceId: string
  }
  type: NoteType
}

interface OpportunityNote extends UiNote {
  opportunityGid?: string
  opportunitySalesforceId: string
  type: NoteType
  uiNoteType?: UiNoteType
  wasEdited?: boolean
}

interface UiAppointmentScheduledNote extends UiNote {
  appointment: Appointment
  createdAt: Date
  dateLocal: string
  duration: number
  isLatestAppointmentWithCoupling?: boolean
  provider: {
    name: string
    salesforceId: string
    type: string
  }
  relatedAppointment?: LegacyCouplingNote
  relatedASAppointmentGid?: string
  relatedCoupling?: SingleDeviceCoupling
  startTime: Date
  status: {
    current?: string
    details: {
      description?: string
      reason?: string
      replacement_appointment_id?: string
    }
  }
  timeCustomer: string
  timeLocal: string
  uiNoteType?: UiNoteType
  wasCouplingEdited?: boolean
  wasEdited?: boolean
}
interface PostAppointmentNote extends UiNote, Note {}

type AnyNote =
  | ConsultationNote
  | DeviceCouplingNote
  | LegacyCouplingNote
  | OpportunityNote
  | PostAppointmentNote
  | UiAppointmentScheduledNote
  | UiNote

export { UiNoteType }

export type {
  AnyNote as AnyNoteWithCreatedByAccount,
  AnyNote,
  ConsultationNote,
  ConsultationSubnote,
  DeviceCouplingDTO,
  DeviceCouplingNote,
  FormTemplate,
  LegacyAppointmentNote,
  LegacyCouplingNote,
  NoteMeta,
  NoteRequest,
  NoteResponse,
  NoteResponseMeta,
  NotesPage,
  NoteTemplate,
  NoteTemplateResponse,
  OpportunityNote,
  UiAppointmentScheduledNote,
  UiNote,
}
