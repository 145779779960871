/* eslint-disable @typescript-eslint/no-explicit-any */
const arrayToMapByPropName = (array: Record<string, any>[], key: string) => {
  return array.reduce((object, item) => {
    const existingItem = object[item[key]]

    if (!existingItem || item.created_at > existingItem.created_at) {
      object[item[key]] = item
    }
    return object
  }, {})
}

export { arrayToMapByPropName }
