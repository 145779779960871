enum DocumentType {
  HIPAA_NOTICE = 'notice',
  MEDICAL_WAIVER = 'medical-waiver',
  PURCHASE_DELIVERY_RECEIPT = 'purchase-delivery-receipt',
}

enum ConsentDocumentType {
  DATA_PRIVACY = 'data-privacy-consent',
  HIPAA_NOTICE = 'notice-consent',
  MEDICAL_WAIVER = 'medical-waiver-consent',
}

export { ConsentDocumentType, DocumentType }
