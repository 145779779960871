import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { DocumentType as TranslationKey } from 'constants/'
import { makeTranslationProxy } from '../util'

const useDocumentTypeTranslations = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      [TranslationKey.HIPAA_NOTICE]: (options) => t('document_types.notice', options),
      [TranslationKey.MEDICAL_WAIVER]: (options) => t('document_types.medical-waiver', options),
      [TranslationKey.PURCHASE_DELIVERY_RECEIPT]: (options) => t('document_types.purchase-delivery-receipt', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useDocumentTypeTranslations }
