import { AppointmentType, textConstants } from 'constants/'

const appointmentTypeDisplayText = {
  [AppointmentType.AFTERCARE_FOLLOW_UP]: 'appointment_types.aftercare_follow_up',
  [AppointmentType.FOLLOW_UP]: 'appointment_types.follow_up',
  [AppointmentType.HEARING_AID_EVALUATION]: 'appointment_types.hearing_aid_evaluation',
  [AppointmentType.VIDEO_CARE_CALL]: 'appointment_types.video_care_call',
}

const toAppointmentTypeDisplayText = (type: AppointmentType) =>
  appointmentTypeDisplayText[type] ?? textConstants.unknown

export { toAppointmentTypeDisplayText }
