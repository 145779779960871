import { NOTIFICATION_SERVICE_URL } from 'config'
import { defaultPagination } from 'constants/'
import { useCallback } from 'react'
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { Notification, Query, QueryResponse } from 'types'
import { applySearchParametersToUrl, indexBy, prop } from 'utils'
import { useHttpClientConfig } from '../services/useHttpClientConfig'
import { useOpportunityService } from 'hooks/services'

enum ApiPathname {
  NOTIFICATIONS = '/accounts/me/notifications',
}

type MyNotificationsQuery = Query<{
  is_read?: boolean
}>

type MyNotificationsResponse = QueryResponse<Notification[]>

const hookName = 'useMyNotificationsInfinite'
const hookDefaultPageSize = 10
const baseUrl = new URL(NOTIFICATION_SERVICE_URL)

const useMyNotificationsInfinite = (
  query: MyNotificationsQuery
): UseInfiniteQueryResult<QueryResponse<Notification[]>, unknown> => {
  const { getConfig } = useHttpClientConfig()
  const { getLatestOpportunities } = useOpportunityService()

  const fetchPaginatedNotifications = useCallback(
    async (page = 0): Promise<MyNotificationsResponse> => {
      const { is_read, ...rest } = query

      const actualQuery: MyNotificationsQuery = {
        ...defaultPagination,
        page,
        page_size: hookDefaultPageSize,
        ...rest,
      }

      if (typeof is_read !== 'undefined') {
        actualQuery.is_read = is_read
      }

      const url = applySearchParametersToUrl(new URL(ApiPathname.NOTIFICATIONS, baseUrl), actualQuery)
      const config = await getConfig()
      const res = await fetch(url.href, config)

      if (!res.ok) {
        throw new Error(`Failed to get paginated notifications ${await res.text()}`)
      }

      const response = (await res.json()) as MyNotificationsResponse
      const customerGids = response.data.map((notification) => notification.customer_gid)

      const { data: opportunities } = await getLatestOpportunities({ customerSalesforceId: customerGids })
      const opportunitiesByCustomerSalesforceId = indexBy(opportunities, prop('customerSalesforceId'))

      const allNotifications = response.data.filter((notification) =>
        Boolean(
          opportunitiesByCustomerSalesforceId[
            notification.customer_gid as keyof typeof opportunitiesByCustomerSalesforceId
          ]
        )
      )

      const existingNotifications: Record<string, Notification> = allNotifications.reduce((acc, notification) => {
        const key = `${notification.customer_gid}_${notification.metadata.appointment_type}`

        return { ...acc, [key]: notification }
      }, {})

      const deduplicatedAndFilteredNotifications = Object.values(existingNotifications)

      return { data: deduplicatedAndFilteredNotifications, meta: response.meta }
    },
    [getConfig, query]
  )

  const fetchInfiniteQuery = ({ pageParam = 0 }) => fetchPaginatedNotifications(pageParam)

  const infiniteQueryResult = useInfiniteQuery(hookName, fetchInfiniteQuery, {
    getNextPageParam: (lastPage) => {
      const { meta } = lastPage
      const { page, pages_total } = meta
      const nextPageIndex = page + 1

      if (nextPageIndex > pages_total - 1) return // No more pages

      return nextPageIndex
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  return infiniteQueryResult
}

export { useMyNotificationsInfinite }
