import { CustomerSearchQuery } from '../services'
import { useMemo } from 'react'
import { LegacyCustomerOpportunityLifecycle } from 'types'
import { arrayToMapByPropName, take } from 'utils'
import type { Customer } from '../services/useCustomersService/types'
import { useCustomerSearch } from '../useCustomerSearch'
import { useOpportunityLifecycles } from '../useOpportunityLifecycles'

interface UseHookContext {
  data?: LegacyCustomerOpportunityLifecycle[]
  hasMore: boolean
  isError: boolean
  isFetched: boolean
  isFetching: boolean
  isLoading: boolean
  maxResultLimit: number
}

const CUSTOMER_SEARCH_PAGE_SIZE = 300
const UI_MAX_RESULTS_DISPLAY = 25

const useCustomerOpportunitySearchLegacy = (query: CustomerSearchQuery, isEnabled = true): UseHookContext => {
  const customersQuery = {
    ...query,
    page_size: CUSTOMER_SEARCH_PAGE_SIZE,
  }

  const {
    data: customersResponse,
    isError: haveAccountsErrored,
    isFetched: haveAccountsFetched,
    isFetching: areAccountsFetching,
    isLoading: areAccountsLoading,
  } = useCustomerSearch(customersQuery, { enabled: isEnabled })

  const customers = customersResponse?.data

  const opportunityLifecycleQuery = {
    customer_gids: customers?.map((customers) => customers.salesforceId)?.filter(Boolean) ?? [],
    page_size: CUSTOMER_SEARCH_PAGE_SIZE,
  }

  const isOpportunityQueryEnabled = Boolean(!areAccountsLoading && customers?.length && isEnabled)

  const {
    data: opportunityLifecycles = [],
    isError: haveOpportunitiesErrored,
    isFetched: haveOpportunitiesFetched,
    isFetching: areOpportunitiesFetching,
    isLoading: areOpportunitiesLoading,
  } = useOpportunityLifecycles(opportunityLifecycleQuery, { enabled: isOpportunityQueryEnabled })

  const isError = haveAccountsErrored || haveOpportunitiesErrored
  const isFetched = haveAccountsFetched || haveOpportunitiesFetched
  const isFetching = areAccountsFetching || areOpportunitiesFetching
  const isLoading = areAccountsLoading || areOpportunitiesLoading
  const opportunityMapByCustomerGid = arrayToMapByPropName(opportunityLifecycles, 'customer_gid')

  const data = useMemo(() => {
    const filtered =
      customers
        ?.filter((customer) => opportunityMapByCustomerGid[customer.salesforceId])
        .map<LegacyCustomerOpportunityLifecycle>((customer: Customer) => {
          const opportunity = opportunityMapByCustomerGid[customer.salesforceId]
          const end_of_trial_period = opportunity?.end_of_trial_period
          const opportunity_gid = opportunity?.opportunity_gid

          return {
            ...customer,
            end_of_trial_period,
            opportunity_gid,
            status: opportunityMapByCustomerGid[customer.salesforceId]?.status,
          }
        }) ?? ([] as LegacyCustomerOpportunityLifecycle[])

    const results = take(filtered, UI_MAX_RESULTS_DISPLAY)
    const hasMore = filtered.length > UI_MAX_RESULTS_DISPLAY

    return { hasMore, results }
  }, [customers, opportunityMapByCustomerGid])

  return {
    data: data.results,
    hasMore: data.hasMore,
    isError,
    isFetched,
    isFetching,
    isLoading,
    maxResultLimit: UI_MAX_RESULTS_DISPLAY,
  }
}

export { useCustomerOpportunitySearchLegacy }
