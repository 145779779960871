import { AppointmentStatus } from 'constants/'
import { startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { OpportunitySortableFields, useAppointments, useMyAccount } from 'hooks'
import { useOpportunities } from 'hooks/useOpportunities'
import { useOpportunityJourneyActivities } from 'hooks/useOpportunityJourneyActivities'
import { useMemo } from 'react'
import {
  Appointment,
  JourneyActivity,
  JourneyStatus,
  MetaCamelCaseWithOffsetAndLimit,
  Opportunity,
  QueryOptions,
  SalesStatus,
  SortDirection,
} from 'types'
import { groupBy, indexBy, prop } from 'utils'
import { useCustomerSearch as useCustomers } from '../useCustomerSearch'

interface UiCustomerOpportunity {
  appointment?: {
    dateStart: string
    status: string
  }
  customer?: {
    familyName?: string
    givenName?: string
    id?: string
    phoneNumber?: string
    address?: {
      city?: string
      countryCode?: string
      state?: string
      stateCode?: string
    }
  }
  opportunity: Pick<
    Opportunity,
    'accumulatedWearingTimeInMinutes' | 'createdAt' | 'journeyStatus' | 'salesStatus' | 'trialEndDate' | 'focusReasons'
  > & {
    opportunityId: string
    journeyActivities: JourneyActivity[]
  }
}

interface UseHookContext {
  data?: UiCustomerOpportunity[]
  isError: boolean
  isLoading: boolean
  pagination: {
    hasMore: boolean
    itemsTotal: number
    page: number
    pageSize: number
  }
}

type Query = {
  fitterSalesforceId: string
  hadFocusReasonsAddressed?: boolean
  isFocused?: boolean
  journeyStatus?: JourneyStatus[]
  salesStatus?: SalesStatus[]
  limit?: number
  offset?: number
  sort?: SortDirection
  sortBy?: OpportunitySortableFields
}

const DEFAULT_PAGE_SIZE = 50
const MAX_NUMBER_OF_APPOINTMENTS = 100

const toStartOfDay = (timeZone: string) => {
  if (!timeZone) return startOfDay(new Date())

  const clientStartOfDay = startOfDay(new Date())

  return utcToZonedTime(clientStartOfDay, timeZone)
}

const useCustomerOpportunities = (query: Query, options: QueryOptions = { enabled: true }): UseHookContext => {
  const { fitterSalesforceId, limit = DEFAULT_PAGE_SIZE, sort = 'desc', sortBy = 'createdAt' } = query

  const {
    data: opportunityResponse,
    isError: haveOpportunitiesErrored,
    isLoading: areOpportunitiesLoading,
  } = useOpportunities(
    {
      ...query,
      fitterSalesforceId: [fitterSalesforceId],
      limit,
      sort,
      sortBy,
    },
    options
  )

  const opportunities = opportunityResponse?.data ?? []
  const opportunityMeta = opportunityResponse?.meta ?? ({} as MetaCamelCaseWithOffsetAndLimit)
  const customerSalesforceIds = opportunities.map(prop('customerSalesforceId')).filter(Boolean) ?? []
  const opportunityIds = opportunities.map(prop('id'))
  const opportunitySalesforceIds = opportunities.map(prop('salesforceId'))

  // Get Customer Data
  const hasCustomerSalesforceIds = Boolean(customerSalesforceIds.length)

  const {
    data: customersResponse,
    isError: haveCustomersErrored,
    isLoading: areCustomersLoading,
  } = useCustomers({ salesforceId: customerSalesforceIds }, { enabled: hasCustomerSalesforceIds })

  const customers = customersResponse?.data ?? []

  const customerIndexedBySalesforceId = indexBy(customers, prop('salesforceId'))

  // Get Journey Activities
  const {
    data: journeyActivitiesResponse,
    isError: haveJourneyActivitiesErrored,
    isLoading: areJourneyActivitiesLoading,
  } = useOpportunityJourneyActivities({ opportunityIds }, { enabled: hasCustomerSalesforceIds })

  const journeyActivities = journeyActivitiesResponse?.data ?? []

  const journeyActivitiesGroupedByOpportunityId = groupBy(journeyActivities, prop('opportunityId'))

  // Get Appointments
  const { data: account } = useMyAccount()

  const timeZone = account?.timezone

  const dateStart = useMemo(() => toStartOfDay(timeZone as string), [timeZone])

  const {
    data: appointmentsData,
    isError: didAppointmentsError,
    isLoading: areAppointmentsLoading,
  } = useAppointments(
    {
      dateStart,
      fetchAllPages: true, // ❗❗❗ Setting this to true will fetch ALL pages. To be used with extreme caution!
      fitterGids: [fitterSalesforceId],
      opportunityGids: opportunitySalesforceIds,
      page_size: MAX_NUMBER_OF_APPOINTMENTS,
      sort: 'desc',
      sort_by: 'start_date',
      statuses: [AppointmentStatus.CONFIRMED],
    },
    {
      enabled: hasCustomerSalesforceIds,
    }
  )

  const appointments = appointmentsData?.data ?? []

  const appointmentIndexedByCustomerSalesforceId: Record<string, Appointment> = appointments.reduce(
    (acc, appointment) => ({ ...acc, [appointment.customer.gid]: appointment }),
    {}
  )

  const data = opportunities.map<UiCustomerOpportunity>((opportunityFull) => {
    const {
      accumulatedWearingTimeInMinutes,
      createdAt,
      customerSalesforceId,
      focusReasons,
      id: opportunityId,
      journeyStatus,
      salesStatus,
      trialEndDate,
    } = opportunityFull

    const customerFull = customerIndexedBySalesforceId[customerSalesforceId]
    const appointmentFull = appointmentIndexedByCustomerSalesforceId[customerSalesforceId]

    const opportunity = {
      accumulatedWearingTimeInMinutes,
      createdAt,
      focusReasons,
      journeyActivities: journeyActivitiesGroupedByOpportunityId[opportunityId],
      journeyStatus,
      opportunityId,
      salesStatus,
      trialEndDate,
    }

    const customer = {
      address: {
        city: customerFull?.billingAddress.city,
        countryCode: customerFull?.countryCode,
        state: customerFull?.billingAddress.state,
        stateCode: customerFull?.billingAddress.stateCode,
      },
      familyName: customerFull?.familyName,
      givenName: customerFull?.givenName,
      id: customerFull?.id,
      phoneNumber: customerFull?.phoneNumber,
    }

    const appointment = {
      dateStart: appointmentFull?.start_date,
      status: appointmentFull?.status.status,
    }

    return {
      appointment,
      customer,
      opportunity,
    }
  })

  const isError =
    haveCustomersErrored || haveOpportunitiesErrored || haveJourneyActivitiesErrored || didAppointmentsError

  const isLoading =
    areCustomersLoading || areOpportunitiesLoading || areJourneyActivitiesLoading || areAppointmentsLoading

  const hasMore = opportunityMeta.itemsTotal > limit

  return {
    data,
    isError,
    isLoading,
    pagination: {
      hasMore,
      itemsTotal: opportunityMeta.itemsTotal,
      page: opportunityMeta.offset,
      pageSize: opportunityMeta.limit,
    },
  }
}

export type { UiCustomerOpportunity }
export { useCustomerOpportunities }
