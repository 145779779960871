import { initNewrelic } from 'config'
import { logRocket, init as initLogRocket } from './logRocket'
import 'i18n'

const startup = () => {
  initLogRocket()
  initNewrelic()
}

export { logRocket, startup }
