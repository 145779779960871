import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { OpportunityFocusReason as TranslationKey } from '../../'
import { makeTranslationProxy } from '../util'

const useFocusReasonTranslations = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      LOW_WEARING_TIME: (options) => t('focus_reasons.low_wearing_time', options),
      NO_FOLLOW_UP_SCHEDULED: (options) => t('focus_reasons.first_follow_up_not_scheduled', options),
      PDR_NOT_SIGNED: (options) => t('focus_reasons.pdr_not_signed', options),
      RETURN_INTENDED: (options) => t('focus_reasons.return_intended', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useFocusReasonTranslations }
