import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { NoteType as TranslationKey } from 'constants/'
import { makeTranslationProxy } from '../util'

const useNoteTypeTranslations = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      [TranslationKey.AFTERCARE_FOLLOW_UP]: (options) => t('appointment_note_types.aftercare_follow_up', options),
      [TranslationKey.APPOINTMENT_CANCELLATION]: (options) =>
        t('appointment_note_types.appointment_did_not_take_place', options),
      [TranslationKey.CONSULTATION_EVALUATION]: (options) =>
        t('appointment_note_types.consultation_evaluation', options),
      [TranslationKey.CONSULTATION_NOTE]: (options) => t('appointment_note_types.consultation_notes', options),
      [TranslationKey.COUPLING_NOTE]: (options) => t('appointment_note_types.coupling', options),
      [TranslationKey.CUSTOMER_INTERACTION]: (options) => t('appointment_note_types.customer_interaction', options),
      [TranslationKey.FOLLOW_UP]: (options) => t('appointment_note_types.follow_up', options),
      [TranslationKey.GENERAL]: (options) => t('appointment_note_types.general_notes', options),
      [TranslationKey.HEAR_INTERACTION]: (options) => t('appointment_note_types.hear.com_interaction', options),
      [TranslationKey.HEARING_AID_EVALUATION]: (options) => t('appointment_note_types.hearing_aid_evaluation', options),
      [TranslationKey.INITIAL_FITTING_WITH_SHOEBOX]: (options) =>
        t('appointment_note_types.initial_fitting_with_shoebox', options),
      [TranslationKey.INITIAL_FITTING]: (options) => t('appointment_note_types.initial_fitting', options),
      [TranslationKey.LEGACY_FOLLOW_UP]: (options) => t('appointment_note_types.follow_up', options),
      [TranslationKey.OPPORTUNITY_NOTE]: (options) => t('appointment_note_types.note', options),
      [TranslationKey.VIDEO_CARE_CALL]: (options) => t('appointment_note_types.video_care_call', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useNoteTypeTranslations }
