import { NotificationProvider, SelectedCountryProvider, UserSettingsProvider } from 'hooks'
import { FC } from 'react'
import { AnalyticsProvider } from './Analytics'
import { AuthProvider } from './Auth'
import { FeatureFlagProvider } from './FeatureFlag'
import { NewReleaseProvider } from './NewRelease'
import { QueryProvider } from './Query'
import { ThemeProvider } from './Theme'

const Providers: FC = ({ children }) => (
  <ThemeProvider>
    <AuthProvider>
      <QueryProvider>
        <UserSettingsProvider>
          <SelectedCountryProvider>
            <FeatureFlagProvider>
              <NotificationProvider>
                <NewReleaseProvider>
                  <AnalyticsProvider>{children}</AnalyticsProvider>
                </NewReleaseProvider>
              </NotificationProvider>
            </FeatureFlagProvider>
          </SelectedCountryProvider>
        </UserSettingsProvider>
      </QueryProvider>
    </AuthProvider>
  </ThemeProvider>
)

export { Providers }
