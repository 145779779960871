import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { MetaCamelCaseWithOffsetAndLimit, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { DailyWearingTime, GetDailyWearingTimesQuery, useDeviceService } from '../services'

type Query = GetDailyWearingTimesQuery & {
  fetchAllPages?: boolean
}

const hookName = 'useDailyWearingTime'
const log = logFactory(hookName)

const useDailyWearingTime = (
  { fetchAllPages = false, ...params }: Query,
  options: QueryOptions = { enabled: true }
) => {
  const { getDailyWearingTimes } = useDeviceService()

  const doQuery = async ({ offset = 0 }) => {
    log(`📊 Fetching daily wearing time...`, params)

    let items: DailyWearingTime[] = []
    let hasNextPage = false
    let pageIndex = offset + 0
    let actualMeta: MetaCamelCaseWithOffsetAndLimit

    do {
      const { data, meta } = await getDailyWearingTimes({ ...params, offset: pageIndex })

      items = [...items, ...data]
      actualMeta = meta
      hasNextPage = pageIndex + 1 < meta.pagesTotal
      pageIndex += 1

      if (!fetchAllPages) {
        hasNextPage = false
      }
    } while (hasNextPage)

    log(`📊 Found ${items?.length} daily wearing time`, items)

    return { data: items, meta: actualMeta }
  }

  // @ts-expect-error ???
  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useDailyWearingTime }
