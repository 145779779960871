import { NOTIFICATION_SERVICE_URL } from 'config'
import { HttpMethod } from 'constants/'
import { InvalidateQueryFilters, useQueryClient } from 'react-query'
import { Notification, Query, QueryResponse } from 'types'
import { always, identity } from 'utils'
import { useRequest } from '../useRequest'

enum ApiPathname {
  MY_NOTIFICATIONS = '/accounts/me/notifications',
  NOTIFICATIONS_MARK_ALL_READ = '/accounts/me/notifications/read',
  NOTIFICATIONS_MARK_ONE_READ = `/notifications/:notificationId`,
}

type GetMyNotificationsQuery = Query<{
  is_read?: boolean
}>

type GetMyNotificationsResponse = QueryResponse<Notification[]>

type MarkAllAsReadQuery = undefined
type MarkAllAsReadResponse = void

type MarkOneAsReadQuery = { notificationId: string }
type MarkOneAsReadResponse = void

interface UseHookContext {
  getMyNotifications: (query: GetMyNotificationsQuery) => Promise<GetMyNotificationsResponse>
  markAllRead: (query: MarkAllAsReadQuery) => Promise<MarkAllAsReadResponse>
  markOneRead: (query: MarkOneAsReadQuery) => Promise<MarkOneAsReadResponse>
}

const baseUrl = new URL(NOTIFICATION_SERVICE_URL)

const useNotificationsService = (): UseHookContext => {
  const client = useQueryClient()

  const getMyNotifications = useRequest<GetMyNotificationsQuery, GetMyNotificationsResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.MY_NOTIFICATIONS,
  })

  const markAllReadQueryFiltersToInvalidate: InvalidateQueryFilters[] = [{ queryKey: 'useMyNotifications' }]

  const markAllRead = useRequest<MarkAllAsReadQuery, MarkAllAsReadResponse>({
    baseUrl,
    client,
    method: HttpMethod.PATCH,
    pathname: ApiPathname.NOTIFICATIONS_MARK_ALL_READ,
    queryFiltersToInvalidate: markAllReadQueryFiltersToInvalidate,
    transformResponse: () => Promise.resolve({}),
  })

  const markOneRead = useRequest<MarkOneAsReadQuery, MarkOneAsReadResponse>({
    baseUrl,
    filterQueryToRequestBody: always({ is_read: true }),
    filterQueryToRouteParams: identity,
    method: HttpMethod.PATCH,
    pathname: ApiPathname.NOTIFICATIONS_MARK_ONE_READ,
  })

  return { getMyNotifications, markAllRead, markOneRead }
}

export { useNotificationsService }
