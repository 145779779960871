import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Layout } from 'components/layout'
import { Route as Paths } from 'constants/'
import { useAccessToken, useFeatureFlags, useTrackPageView } from 'hooks'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { Customers } from '../../../Customers'
import { CustomersGlobalSearch } from '../../../CustomersGlobalSearch'
import { Director } from '../../../Director'
import { Forms } from '../../../Forms'
import { Home } from '../../../Home'
import { NotFound } from '../../../NotFound'
import { Notifications } from '../../../Notifications'
import { Settings } from '../../../Settings'

const RouteContainer = () => {
  const { isLoading } = useAccessToken()
  const client = useLDClient()

  const [isSettingsPageEnabled] = useFeatureFlags(['SETTINGS_PAGES'])
  const [areFeatureFlagsLoaded, beFeatureFlagsLoaded] = useState(false)
  const isAppReady = Boolean(areFeatureFlagsLoaded && !isLoading)

  useEffect(() => {
    if (areFeatureFlagsLoaded || !client) {
      return
    }

    const async = async () => {
      await client.waitForInitialization()

      // HACK: There is lag time between the LD client saying it's ready and the flag value being accurate
      const timeout = setTimeout(() => beFeatureFlagsLoaded(true), 100)

      return () => clearTimeout(timeout)
    }

    async()
  }, [client])

  useTrackPageView()

  if (!isAppReady) {
    // Block the auth routes from rendering until our dependencies are loaded
    return null
  }

  const rootLoader = document.getElementById('root_loader')

  if (rootLoader) rootLoader.style.display = 'none'

  const SettingsOrRedirect = () => (isSettingsPageEnabled ? <Settings /> : <Navigate replace to={Paths.HOME} />)

  return (
    <Routes>
      <Route element={<Layout />} path="/">
        <Route element={<Home />} index />
        <Route element={<CustomersGlobalSearch />} path={`${Paths.GLOBAL_CUSTOMERS}/*`} />
        <Route element={<Customers />} path={`${Paths.CUSTOMERS}/*`} />
        <Route element={<Home />} path={`${Paths.HOME}/*`} />
        <Route element={<Notifications />} path={`${Paths.NOTIFICATIONS}/*`} />
        <Route element={<Forms />} path={`${Paths.FORMS}/*`} />
        <Route element={<SettingsOrRedirect />} path={`${Paths.SETTINGS}/*`} />
        <Route element={<NotFound />} path="/*" />
      </Route>
      <Route element={<Director />} path={Paths.REDIRECT} />
    </Routes>
  )
}

const AuthenticatedRoutes = withAuthenticationRequired(RouteContainer)

export { AuthenticatedRoutes }
