import { ConsentDocumentType, defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import type { QueryOptions } from 'types'
import { logFactory, omit } from 'utils'
import { ConsentDocument, GetConsentDocumentResponseError, useDocumentService } from '../services'

interface Params {
  customerSalesforceId: string
  type: ConsentDocumentType[]
}

const hookName = 'useConsentDocuments'
const log = logFactory(hookName)

const useConsentDocuments = (params: Params, options: QueryOptions = { enabled: true, refetchOnMount: false }) => {
  const { getConsentDocument } = useDocumentService()
  const { type: types } = params
  const actualParams = omit(params, ['type'])

  const fetchDocuments = async () => {
    log(`☑️ Fetching consent documents...`, params)

    const promises = types.map((type) =>
      getConsentDocument({
        ...actualParams,
        type,
      }).catch((error) => {
        const errorResponse: GetConsentDocumentResponseError = {
          error_code: 'UNEXPECTED_ERROR',
          error_message: error?.message || 'An unexpected error occurred. Check network response for more details.',
          type: 'error',
        }

        return errorResponse
      })
    )

    const consentDocuments = (await Promise.all(promises))
      .filter((response) => response.type !== 'error')
      .map((response) => {
        if (response.type === 'success') {
          return response.consent
        }
      }) as ConsentDocument[]

    log(`☑️ Found ${consentDocuments.length} consent documents`, consentDocuments)

    return consentDocuments
  }

  return useQuery([hookName, params], fetchDocuments, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(types?.length),
  })
}

export { useConsentDocuments }
