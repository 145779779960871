import { Route } from 'constants/'
import { useMatch } from 'react-router'

const matchPath = `${Route.FORMS}/*`

const useIsPostAppointmentFormRoute = () => {
  const match = useMatch(matchPath)
  const isMatch = Boolean(match)
  const [, , formType] = isMatch ? match?.pathname.split('/') ?? [] : [] // /forms/:type/:customerId

  return Boolean(formType)
}

export { useIsPostAppointmentFormRoute }
