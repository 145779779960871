import {
  CommonProp,
  DeviceStyle,
  DeviceType,
  DomeSize,
  FittingProp,
  MetaFlag,
  MobileAppSetupOutcomeType,
  ReceiverLength,
  ReceiverStrength,
  ReceiverType,
  SleeveSize,
  TechnicalIssueType,
} from 'constants/'
import { NoteResponse } from 'types'
import { makeReadResponseValue } from '../util'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasAnswer = (value: any) => value !== ''

const toDeviceSize = (deviceSize?: string): SleeveSize | DomeSize =>
  (deviceSize as SleeveSize) || (deviceSize as DomeSize)

const toDeviceStyle = (deviceStyle?: string) => deviceStyle as DeviceStyle
const toDeviceType = (deviceType?: string) => deviceType as DeviceType
const toReceiverLength = (length?: string) => length as ReceiverLength
const toReceiverStrength = (strength?: string) => strength as ReceiverStrength
const toReceiverType = (type?: string) => type as ReceiverType
const toTechnicalIssueType = (technicalIssue?: string) => technicalIssue as TechnicalIssueType

const toTechnicalIssues = (technicalIssues?: string[]) =>
  hasAnswer(technicalIssues) ? technicalIssues?.map(toTechnicalIssueType) : []

const toMobileOutcomeSetupType = (mobileOutcomeSetup?: string) => mobileOutcomeSetup as MobileAppSetupOutcomeType

const notScheduledAppointmentDate = 'not_scheduled'

const toAppointmentDate = (value: unknown) => (!value || value === notScheduledAppointmentDate ? undefined : value)

const toFittingNoteProps = (note: NoteResponse): Record<string, unknown> => {
  const read = makeReadResponseValue(note)
  const hasCoupling = Boolean(note[CommonProp.META]?.[CommonProp.FLAGS]?.[MetaFlag.HAS_COUPLING])

  const noteProps: Record<string, unknown> = {
    content: read(FittingProp.CONTENT),
    didAppointmentTakePlace: read(FittingProp.DID_APPOINTMENT_TAKE_PLACE),
    isCustomerKeepingDevices: read(FittingProp.IS_CUSTOMER_KEEPING_DEVICES),
    isFitKitReadyForPickup: read(FittingProp.IS_FITKIT_READY_FOR_PICKUP),
    isPDRSigned: read(FittingProp.IS_PDR_SIGNED),
    mobileAppSetupOutcome: toMobileOutcomeSetupType(read(FittingProp.MOBILE_OUTCOME_SETUP) as string),
    nextAppointment: toAppointmentDate(read(FittingProp.NEXT_APPOINTMENT)),
    technicalIssues: toTechnicalIssues(read(FittingProp.TECHNICAL_ISSUES) as string[]),
    technicalIssuesComment: read(FittingProp.TECHNICAL_ISSUES_COMMENT),
  }

  if (hasCoupling) {
    const left = read(FittingProp.COUPLING_LEFT_DEVICE_SIZE)
      ? {
          device: {
            size: toDeviceSize(read(FittingProp.COUPLING_LEFT_DEVICE_SIZE) as string),
            style: toDeviceStyle(read(FittingProp.COUPLING_LEFT_DEVICE_STYLE) as string),
            type: toDeviceType(read(FittingProp.COUPLING_LEFT_DEVICE_TYPE) as string),
          },
          isCROSDevice: read(FittingProp.COUPLING_LEFT_IS_CROS_DEVICE) as boolean,
          receiver: {
            length: toReceiverLength(read(FittingProp.COUPLING_LEFT_RECEIVER_LENGTH) as string),
            strength: toReceiverStrength(read(FittingProp.COUPLING_LEFT_RECEIVER_STRENGTH) as string),
            type: toReceiverType(read(FittingProp.COUPLING_LEFT_RECEIVER_TYPE) as string),
          },
        }
      : undefined

    const right = read(FittingProp.COUPLING_RIGHT_DEVICE_SIZE)
      ? {
          device: {
            size: toDeviceSize(read(FittingProp.COUPLING_RIGHT_DEVICE_SIZE) as string),
            style: toDeviceStyle(read(FittingProp.COUPLING_RIGHT_DEVICE_STYLE) as string),
            type: toDeviceType(read(FittingProp.COUPLING_RIGHT_DEVICE_TYPE) as string),
          },
          isCROSDevice: read(FittingProp.COUPLING_RIGHT_IS_CROS_DEVICE) as boolean,
          receiver: {
            length: toReceiverLength(read(FittingProp.COUPLING_RIGHT_RECEIVER_LENGTH) as string),
            strength: toReceiverStrength(read(FittingProp.COUPLING_RIGHT_RECEIVER_STRENGTH) as string),
            type: toReceiverType(read(FittingProp.COUPLING_RIGHT_RECEIVER_TYPE) as string),
          },
        }
      : undefined

    noteProps.coupling = { left, right }
  }

  return noteProps
}

export { toFittingNoteProps }
