import { Salutation } from 'constants/'

// Ref: https://github.com/Audibene-GMBH/ta.customer-service/blob/master/internal/model/canonical/canonical-to-domain-salutation.go#L18
const salutationToDisplayTextMap: Record<keyof typeof Salutation | string, string> = {
  [Salutation.DOCTOR]: 'general.salutation.dr',
  [Salutation.DOCTOR_MEDICAL]: 'general.salutation.dr',
  [Salutation.MISS]: 'general.salutation.miss',
  [Salutation.MISSES]: 'general.salutation.mrs',
  [Salutation.MISTER]: 'general.salutation.mr',
  [Salutation.PROFESSOR]: 'general.salutation.prof',
  [Salutation.PROFESSOR_DOCTOR]: 'general.salutation.prof_dr',
  [Salutation.PROFESSOR_DOCTOR_DOCTOR]: 'general.salutation.prof_dr_dr',
  ['Dr']: 'general.salutation.dr',
  ['Dr.']: 'general.salutation.dr',
  ['Miss']: 'general.salutation.miss',
  ['Mr']: 'general.salutation.mr',
  ['Mr.']: 'general.salutation.mr',
  ['Mrs']: 'general.salutation.mrs',
  ['Mrs.']: 'general.salutation.mrs',
  ['Ms']: 'general.salutation.ms',
  ['Ms.']: 'general.salutation.ms',
  ['Prof']: 'general.salutation.prof',
  ['Prof.']: 'general.salutation.prof',
  ['Prof. Dr.']: 'general.salutation.prof_dr',
  ['Prof. Dr. Dr.']: 'general.salutation.prof_dr_dr',
}

const salutationToDisplayText = (salutation?: string | Salutation) =>
  salutationToDisplayTextMap[salutation as Salutation] ?? ''

export { salutationToDisplayText }
