import { PartnerEmployee } from 'hooks/services/usePartnerService/types'
import type { Account, FitterAccount } from 'types'

const fitterToDisplayName = (fitter?: Account | FitterAccount | PartnerEmployee): string => {
  if (!fitter) {
    return ''
  }

  // @ts-expect-error We know that all of these may not exist on all the types
  const { givenName, familyName, firstname = givenName, lastname = familyName } = fitter

  if (!firstname || !lastname) {
    return ''
  }

  return `${firstname} ${lastname}`
}

export { fitterToDisplayName }
