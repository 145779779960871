import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Typography } from '@material-ui/core'
import { EventType, Route } from 'constants/'
import { useCustomerSearch, useEventTracking } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { ExternalRedirectData } from 'types'
import { hydrateRoute } from 'utils'

enum KnownSearchParameter {
  CUSTOMER_GID = 'customer_gid',
  CUSTOMER_SALESFORCE_ID = 'customer_salesforce_id',
  UTM_SOURCE = 'utm_source',
}

const BAD_ADDRESS = 'Something in the address is not correct. Please check that it is valid and try again.'

const UNKNOWN_SOURCE = 'unknown'

const styles = {
  display: 'grid',
  height: '100vh',
  placeItems: 'center',
  width: '100%',
}

const isGid = (value: string | null = '') => /^[0-9]+[.][a-z-]+$/giu.test(value ?? '')

const isSalesforceId = (value: string | null = '') =>
  Boolean(value && !isGid(value) && [15, 18].indexOf(value.length) > -1) && /[0-9a-z]+/giu.test(value ?? '')

const Director = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { track } = useEventTracking()
  const [message, setMessage] = useState('Redirecting...')
  const customerGidParam = searchParams.get(KnownSearchParameter.CUSTOMER_GID)
  const customerSalesforceIdParam = searchParams.get(KnownSearchParameter.CUSTOMER_SALESFORCE_ID)
  const utmSource = searchParams.get(KnownSearchParameter.UTM_SOURCE) ?? UNKNOWN_SOURCE
  const customerGid = useMemo(() => (isGid(customerGidParam) ? customerGidParam : undefined), [customerGidParam])

  const customerSalesforceId = useMemo(
    () => (isSalesforceId(customerGidParam) ? customerGidParam : customerSalesforceIdParam ?? undefined),
    [customerGidParam, customerSalesforceIdParam]
  )

  const customerSearchParams = useMemo(() => {
    if (customerGid) {
      return { gid: [customerGid] }
    }

    if (customerSalesforceId) {
      return { salesforceId: [customerSalesforceId] }
    }

    return {}
  }, [customerGid, customerSalesforceId])

  const isCustomerSearchEnabled = Boolean(Object.keys(customerSearchParams).length)
  const { data } = useCustomerSearch(customerSearchParams, { enabled: isCustomerSearchEnabled })
  const customers = data?.data

  useEffect(() => {
    if (!utmSource) return

    const data: ExternalRedirectData = {
      source: (utmSource ?? UNKNOWN_SOURCE) as string,
    }

    track(EventType.EXTERNAL_REDIRECT, {
      data,
      options: {
        transport: 'sendBeacon',
      },
    })
  }, [])

  useEffect(() => {
    if (!customers?.length) return

    const [customer] = customers

    if (customer) {
      const url = new URL(hydrateRoute(Route.CUSTOMER_PROFILE, { customerId: customer?.id }), window.origin)

      navigate(url.pathname)

      return
    } else {
      setMessage(BAD_ADDRESS)

      // eslint-disable-next-line no-console
      console.error('No customer was found with gid', customerGid)
    }
  }, [customers, navigate, setMessage])

  useEffect(() => {
    const timeout = setTimeout(() => setMessage(BAD_ADDRESS), 5_000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div style={styles}>
      <Typography>{message ?? 'Loading...'}</Typography>
    </div>
  )
}

const AuthenticatedDirector = withAuthenticationRequired(Director)

export { AuthenticatedDirector as Director }
