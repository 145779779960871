import { defaultQueryOptions, AppointmentStatus } from 'constants/'
import { useQuery } from 'react-query'
import { Appointment, Query, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useAppointmentsService } from '../services'

type UseHooksProps = Query<{
  dateEnd?: Date
  dateStart?: Date
  customerGids?: string[]
  fetchAllPages?: boolean
  fitterGids?: string[]
  opportunityGids?: string[]
  statuses?: AppointmentStatus[]
}>

const hookName = 'useAppointments'
const log = logFactory(hookName)

const useAppointments = (
  { customerGids, fetchAllPages = false, fitterGids, opportunityGids, statuses, ...rest }: UseHooksProps,
  options: QueryOptions = { enabled: true }
) => {
  const { getAppointments } = useAppointmentsService()
  let foundItems: Appointment[] = []
  let hasNextPage = false
  let pageIndex = 0
  let finalMeta

  const params = {
    ...rest,
    customer_gid: customerGids,
    opportunity_gid: opportunityGids,
    resourceGids: fitterGids,
    status: statuses,
  }

  const doQuery = async ({ pageParam = 0 }) => {
    log(`📆 Fetching appointments...`, params)

    do {
      const { data, meta } = await getAppointments({ ...params, page: pageParam })

      foundItems = [...foundItems, ...data]
      finalMeta = meta
      hasNextPage = pageIndex + 1 < meta.pages_total
      pageIndex++
      pageParam++
      if (!fetchAllPages) {
        hasNextPage = false
      }
    } while (hasNextPage)

    log(`📆 Found ${foundItems?.length} appointments`, foundItems)

    return { data: foundItems, meta: finalMeta }
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useAppointments }
