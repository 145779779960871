import { HttpMethod } from 'constants/'
import { useMyAccount } from 'hooks/useMyAccount'
import { useUser } from 'hooks/useUser'
import { useCallback } from 'react'
import {
  JourneyActivity,
  JourneyStatus,
  MetaCamelCaseWithOffsetAndLimit,
  Opportunity,
  PartnerPortalDeliveryModel,
  SortableFields,
} from 'types'
import { identity, pick } from 'utils'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'

enum ApiPathname {
  OPPORTUNITIES_PATHNAME = '/opportunities/v1/opportunities',
  OPPORTUNITY_PATHNAME = '/opportunities/v1/opportunities/:opportunityId',
  JOURNEY_ACTIVITIES_PATHNAME = '/opportunities/v1/journey-activities',
}

type OpportunitySortableFields = SortableFields | 'trialEndDate' | 'isFocused'

type GetOpportunityServiceQuery = Partial<{
  customerSalesforceId?: string[]
  fitterSalesforceId?: string[]
  opportunityGid?: string[]
  deliveryModel?: PartnerPortalDeliveryModel[]
  pageSize: number
  offset: number
  sortBy: OpportunitySortableFields
  sort: string
  status?: JourneyStatus[]
  strict: boolean
}>

type GetJourneyActivitiesQuery = Partial<{
  opportunityId: string[]
  offset: number
  sortBy: string
  sort: string
}>

type UpdateOpportunityQuery = {
  opportunityId: string
  hadFocusReasonsAddressed: boolean
}

type GetOpportunityServiceResponse = { data: Opportunity[]; meta: MetaCamelCaseWithOffsetAndLimit }
type GetJourneyActivitiesResponse = { data: JourneyActivity[]; meta: MetaCamelCaseWithOffsetAndLimit }

interface UseHookContext {
  getLatestOpportunities: (query: GetOpportunityServiceQuery) => Promise<GetOpportunityServiceResponse>
  getJourneyActivities: (query: GetJourneyActivitiesQuery) => Promise<GetJourneyActivitiesResponse>
  updateFocusReasonResolved: (query: UpdateOpportunityQuery) => Promise<Opportunity>
}

const filterQueryToSearchParams = identity // Take all that is passed in and apply to query params

type Options = {
  restrictQueryToOpportunityFitter?: boolean
}

const useOpportunityService = (options?: Options): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('opportunity')
  const { allowedDeliveryModels, roleFlags } = useUser()
  const { data: account } = useMyAccount()
  const actualDeliveryModels = (allowedDeliveryModels || []).filter(
    (deliveryModel) => PartnerPortalDeliveryModel[deliveryModel as keyof typeof PartnerPortalDeliveryModel]
  ) as PartnerPortalDeliveryModel[]

  const restrictQueryToOpportunityFitter = Boolean(
    roleFlags?.isPartnerFitter || options?.restrictQueryToOpportunityFitter
  )

  const getOpportunitiesRequest = useRequest<GetOpportunityServiceQuery, GetOpportunityServiceResponse>({
    baseUrl,
    filterQueryToSearchParams,
    pathname: ApiPathname.OPPORTUNITIES_PATHNAME,
  })

  const getLatestOpportunities = useCallback(
    (query: GetOpportunityServiceQuery) => {
      const fitterSalesforceId =
        restrictQueryToOpportunityFitter && account?.gid ? [account.gid] : query.fitterSalesforceId

      return getOpportunitiesRequest({
        sort: 'desc',
        sortBy: 'createdAt',
        ...query,
        deliveryModel: actualDeliveryModels,
        fitterSalesforceId,
      })
    },
    [account?.gid, actualDeliveryModels, getOpportunitiesRequest, restrictQueryToOpportunityFitter]
  )

  const getOpportunityJourneyActivitiesRequest = useRequest<GetJourneyActivitiesQuery, GetJourneyActivitiesResponse>({
    baseUrl,
    filterQueryToSearchParams,
    pathname: ApiPathname.JOURNEY_ACTIVITIES_PATHNAME,
  })

  const getJourneyActivities = useCallback(
    (query: GetJourneyActivitiesQuery) =>
      getOpportunityJourneyActivitiesRequest({
        sort: 'desc',
        sortBy: 'opportunityId',
        ...query,
      }),
    [getOpportunityJourneyActivitiesRequest]
  )

  const updateFocusReasonResolved = useRequest<UpdateOpportunityQuery, Opportunity>({
    baseUrl,
    filterQueryToRequestBody: pick(['hadFocusReasonsAddressed']),
    filterQueryToRouteParams: pick(['opportunityId']),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.OPPORTUNITY_PATHNAME,
  })

  return { getJourneyActivities, getLatestOpportunities, updateFocusReasonResolved }
}

export { useOpportunityService }
export type { OpportunitySortableFields }
