import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { Query, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useOrdersService } from '../services'

type UseHooksProps = Query<{
  customerGid?: string
  page?: number
  pageSize?: number
}>

const hookName = 'useSaleOrders'
const log = logFactory(hookName)

const useSaleOrders = (params: UseHooksProps, options: QueryOptions = { enabled: true }) => {
  const { getSalesOrders } = useOrdersService()

  const doQuery = async () => {
    log(`🏷 Fetching sales orders...`, params)

    const { data } = await getSalesOrders(params)

    log(`🏷 Found ${data?.length} sales orders`, data)

    return data
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && params.customerGid),
  })
}

export { useSaleOrders }
