import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useLegacyAppointmentsService } from '../services'

const hookName = 'useLegacyAppointments'
const log = logFactory(hookName)

const useLegacyAppointments = (opportunityGid: string, options: QueryOptions = { enabled: true }) => {
  const { getAppointments } = useLegacyAppointmentsService()
  const params = { opportunityGids: [opportunityGid] }

  const doQuery = async () => {
    log(`📆 Fetching legacy appointments...`, params)

    const { data } = await getAppointments(params)

    log(`📆 Found ${data?.length} legacy appointments`, data)

    return data
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useLegacyAppointments }
