import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useMedicalRecordsService } from '../services'

const hookName = 'useNoteTemplate'
const log = logFactory(hookName)

const useNoteTemplate = (templateName: string, options: QueryOptions = { enabled: true }) => {
  const { getNoteTemplate } = useMedicalRecordsService()
  const params = { templateName }

  const doQuery = async () => {
    log(`📓 Getting note template...`, params)

    const data = await getNoteTemplate(params)

    log(`📓 Found note template`, data)

    return data
  }

  return useQuery([hookName], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useNoteTemplate }
