import { Country } from 'constants/'
import {
  DeviceStyle,
  DeviceType,
  DomeSize,
  ReceiverLength,
  ReceiverStrength,
  Side,
  SleeveSize,
  ReceiverType,
} from 'constants/'

type DeviceSize = SleeveSize | DomeSize

type Device = {
  size: DeviceSize
  style: DeviceStyle
  type: DeviceType
}

type Receiver = {
  length: ReceiverLength
  strength: ReceiverStrength
  type: ReceiverType
}

interface HearingAid {
  productId: string
  name: string
}

type EarCoupling = {
  device?: Device
  hearingAid?: HearingAid
  isCROSDevice?: boolean
  receiver?: Receiver
}

type Coupling = {
  hasCoupling: boolean
  createdAt?: string | undefined
  left?: EarCoupling
  right?: EarCoupling
}

interface CouplingEar {
  coupler?: Coupler
  receiver?: Receiver
  hearing_aid?: HearingAid
}

interface Coupler {
  size: DeviceSize
  style: DeviceStyle
  type: DeviceType
}

interface CouplingInfo {
  customer_gid: string
  created_at?: string
  hearing_aid_updated_at?: string
  opportunity_gid: string
  updated_at?: string
  cros_device?: Side
  left_ear?: CouplingEar
  right_ear?: CouplingEar
}

// ---------------- Latest device coupling model ----------------

enum DeviceBuildType {
  BTE = 'BTE',
  CROS = 'CROS',
  ITE = 'ITE',
  RIC = 'RIC',
}

enum DeviceCouplingSlotType {
  HEARING_DEVICE = 'HEARING_DEVICE',
  RECEIVER_TUBE = 'RECEIVER_TUBE',
  DOMES_SLEEVES = 'DOMES_SLEEVES',
  RETENTION_WIRES = 'RETENTION_WIRES',
}

type DeviceCouplingSlot = {
  categoryCode: string
  subcategoryCode?: string
  itemCode: string
  itemGid: string
  itemName: string
  colorCode?: string
  colorGid?: string
  colorName: string
  customerItemId: string
  inventoryGid?: string
  serialNumber?: string
  stockId?: string
  type: DeviceCouplingSlotType
  length?: string
  strength?: string
}

type DeviceCouplingSide = {
  slots: DeviceCouplingSlot[]
  type: DeviceBuildType
}

type DeviceCouplingLeftAndRight = {
  left?: DeviceCouplingSide
  right?: DeviceCouplingSide
}

type DeviceCouplingCustomerOpportunityType = {
  gid?: string
  salesforceId: string
}

interface LatestDeviceCouplingInfo {
  appointmentGid?: string
  countryCode: Country
  createdAt: string
  customer: DeviceCouplingCustomerOpportunityType
  opportunity: DeviceCouplingCustomerOpportunityType
  gid: string
  id: string
  coupling: DeviceCouplingLeftAndRight
  createdBy: { type?: string; system?: string }
}

export type {
  Coupling,
  CouplingEar,
  CouplingInfo,
  Device,
  DeviceSize,
  EarCoupling,
  HearingAid,
  Receiver,
  LatestDeviceCouplingInfo,
  DeviceCouplingLeftAndRight,
  DeviceCouplingSide,
  DeviceCouplingSlot,
  DeviceCouplingCustomerOpportunityType,
}
export { DeviceCouplingSlotType, DeviceBuildType }
