import { HearingTestSource, HearingTestTransducer } from 'constants/'
import { HearingTestServiceConductionType, SpeechTestServiceTest, TestEarSide } from 'types'
import { sort } from 'utils'

type UiWordRecognitionTestType = 'OVER_EAR' | 'BONE_CONDUCTOR' | 'FREE_FIELD' | 'IN_SITU' | 'INSERTS'
type EmptyResultObject = Record<string, never>

type TestEarSideDTO = TestEarSide & {
  source: HearingTestSource
  conduction_type: HearingTestServiceConductionType
  transducer_type: HearingTestTransducer
  word_list?: { list: string; voice: string }
}

const SIBLING_CREATED_AT_THRESHOLD_IN_DAYS = 1

const sortTestsByCreatedAtDesc = (a: SpeechTestServiceTest, b: SpeechTestServiceTest) => {
  const aTime = new Date(a.created_at)
  const bTime = new Date(b.created_at)

  const aCompare = aTime.getTime()
  const bCompare = bTime.getTime()

  return bCompare - aCompare
}

const sortTestsByTestedAtDesc = (a: TestEarSide, b: TestEarSide) => {
  const aTime = new Date(a.tested_at)
  const bTime = new Date(b.tested_at)

  const aCompare = aTime.getTime()
  const bCompare = bTime.getTime()

  return bCompare - aCompare
}
const removeEmptyResults = (test: TestEarSide) => Boolean(test.results)

const isValidTest = (wordTest: SpeechTestServiceTest) => {
  const { left_ear, right_ear, binaural } = wordTest

  return Boolean(left_ear?.results || right_ear?.results || binaural?.results)
}

type EarSideGrouping = Record<string, TestEarSideDTO[]>
type WordRecognitionTestGroup = Record<UiWordRecognitionTestType, EarSideGrouping> | EmptyResultObject

const toGroupedTests = (wordTests: SpeechTestServiceTest[]): WordRecognitionTestGroup => {
  if (!wordTests?.length) {
    return {}
  }

  const groupedByEarAndTestType = wordTests.reduce((acc, test) => {
    const binaural = {
      ...test.binaural,
      conduction_type: test.conduction_type,
      source: test.source,
      transducer_type: test.transducer_type,
      word_list: test.word_list,
    }
    const left_ear = {
      ...test.left_ear,
      conduction_type: test.conduction_type,
      source: test.source,
      transducer_type: test.transducer_type,
      word_list: test.word_list,
    }
    const right_ear = {
      ...test.right_ear,
      conduction_type: test.conduction_type,
      source: test.source,
      transducer_type: test.transducer_type,
      word_list: test.word_list,
    }

    if (!acc[test.transducer_type]) {
      acc[test.transducer_type] = {
        binaural: [binaural],
        left_ear: [left_ear],
        right_ear: [right_ear],
      }
    } else {
      acc[test.transducer_type].left_ear = [...acc[test.transducer_type].left_ear, left_ear]
      acc[test.transducer_type].right_ear = [...acc[test.transducer_type].right_ear, right_ear]
      acc[test.transducer_type].binaural = [...acc[test.transducer_type].binaural, binaural]
    }

    acc[test.transducer_type].binaural = acc[test.transducer_type].binaural
      .sort(sortTestsByTestedAtDesc)
      .filter(removeEmptyResults)
    acc[test.transducer_type].left_ear = acc[test.transducer_type].left_ear
      .sort(sortTestsByTestedAtDesc)
      .filter(removeEmptyResults)
    acc[test.transducer_type].right_ear = acc[test.transducer_type].right_ear
      .sort(sortTestsByTestedAtDesc)
      .filter(removeEmptyResults)

    return acc
  }, {} as WordRecognitionTestGroup)

  return groupedByEarAndTestType
}

const groupWordRecognitionTests = (
  allSpeechTests: SpeechTestServiceTest[]
): WordRecognitionTestGroup | EmptyResultObject => {
  if (!allSpeechTests?.length) {
    return {}
  }

  const validSpeechTests = allSpeechTests.filter(isValidTest)
  const validSortedSpeechTests = sort(validSpeechTests, sortTestsByCreatedAtDesc)

  return toGroupedTests(validSortedSpeechTests)
}

export { SIBLING_CREATED_AT_THRESHOLD_IN_DAYS, groupWordRecognitionTests }
export type { EarSideGrouping, WordRecognitionTestGroup, TestEarSideDTO, UiWordRecognitionTestType }
