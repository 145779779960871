import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import type { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetLicenseQuery, License, usePartnersService } from '../services'

const hookName = 'useLicense'
const log = logFactory(hookName)

const useLicenses = (query: GetLicenseQuery = {} as GetLicenseQuery, options: QueryOptions = { enabled: true }) => {
  const { getLicenses } = usePartnersService()

  const doQuery = async () => {
    log(`🎫 Fetching licenses...`, query)

    const { data } = await getLicenses(query)

    log(`🎫 Found licenses`, data)

    return data
  }

  return useQuery([hookName, query], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export type { License }
export { useLicenses }
