import { JourneyStatus, textConstants } from 'constants/'

const journeyStatusDisplayText: Record<JourneyStatus, string> = {
  [JourneyStatus.CLOSED]: 'CLOSED',
  [JourneyStatus.IN_TRIAL]: 'IN TRIAL',
  [JourneyStatus.OPEN]: 'OPEN',
  [JourneyStatus.PREPARING_FOR_FITTING_APPOINTMENT]: 'PREPARING APPOINTMENT',
}

const toJourneyStatusDisplayText = (type: JourneyStatus) => journeyStatusDisplayText[type] ?? textConstants.unknown

export { toJourneyStatusDisplayText }
