import { LEGACY_APPOINTMENT_SERVICE_URL } from 'config'
import { defaultPagination, HttpMethod } from 'constants/'
import { useCallback } from 'react'
import { LegacyAppointment, Query, QueryResponse } from 'types'
import { omit, prop } from 'utils'
import { useRequest } from '../useRequest'

enum ApiPathname {
  APPOINTMENTS_QUERY = '/appointments/query',
  APPOINTMENTS_MY_LATEST = '/accounts/me/latest-appointments',
}

type GetAppointmentsQuery = Query<{ opportunityGids: string[] }>
type GetAppointmentsResponse = QueryResponse<LegacyAppointment[]>

interface UseHookContext {
  getAppointments: (query: GetAppointmentsQuery) => Promise<GetAppointmentsResponse>
}

const baseUrl = new URL(LEGACY_APPOINTMENT_SERVICE_URL)

const useLegacyAppointmentsService = (): UseHookContext => {
  const getAppointmentsRequest = useRequest<GetAppointmentsQuery, GetAppointmentsResponse>({
    baseUrl,
    filterQueryToRequestBody: prop('opportunityGids'),
    filterQueryToSearchParams: omit(['opportunityGids']),
    method: HttpMethod.POST,
    pathname: ApiPathname.APPOINTMENTS_QUERY,
  })

  const getAppointments = useCallback(
    (query: GetAppointmentsQuery) =>
      getAppointmentsRequest({
        ...defaultPagination,
        ...query,
      }),
    [getAppointmentsRequest]
  )

  return { getAppointments }
}

export { useLegacyAppointmentsService }
