import { ReactNode, createContext, useContext } from 'react'
import { Appointment } from 'types'

interface Context {
  appointment: Appointment
}

interface Props {
  appointment: Appointment
  children: ReactNode
}

const context = createContext<Context>({ appointment: {} as Appointment })

const { Provider } = context

const AppointmentProvider = ({ appointment, children }: Props) => (
  <Provider value={{ appointment }}>{children}</Provider>
)

const useAppointment = () => useContext(context)

export { AppointmentProvider, useAppointment }
