import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useRemoteFittingService } from '../services'

const hookName = 'useFitting'
const log = logFactory(hookName)

const useFitting = (fittingId?: string, options: QueryOptions = { enabled: true }) => {
  const { getFitting } = useRemoteFittingService()

  const doQuery = async () => {
    const params = { fittingId: fittingId as string }

    log(`👕 Fetching fitting...`, params)

    const fitting = await getFitting(params)

    log(`👕 Returned fitting...`, fitting)

    return fitting
  }

  return useQuery([hookName, fittingId], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && fittingId),
  })
}

export { useFitting }
