import { Country, defaultPagination } from 'constants/'
import { useCallback } from 'react'
import { PartnerPortalDeliveryModel, Query, QueryResponse } from 'types'
import { identity, toSafeDate } from 'utils'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'
import type { Customer } from './types'

enum ApiPathname {
  CUSTOMERS = '/api/v1/customers',
}

type CustomerSearchQuery = Query<{
  countryCode?: Country
  deliveryModel?: PartnerPortalDeliveryModel
  id?: string[]
  gid?: string[]
  salesforceId?: string[]
  search?: string
}>

const defaultQuery: Partial<CustomerSearchQuery> = {
  deliveryModel: PartnerPortalDeliveryModel.TELEAUDIOLOGY,
  sort: 'DESC',
  sortBy: 'createdAt',
}

type SearchCustomersResponse = QueryResponse<Customer[]>

interface UseHookContext {
  searchCustomers: (query: CustomerSearchQuery) => Promise<SearchCustomersResponse>
}

const useCustomersService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('customer')

  const searchCustomersRequest = useRequest<CustomerSearchQuery, SearchCustomersResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.CUSTOMERS,
    transformResponse: async (response: Response) => {
      const { data, meta } = await response.json()

      const items = data.map((customer: Customer) => ({
        ...customer,
        createdAt: toSafeDate(customer?.createdAt),
        dateOfBirth: toSafeDate(customer?.dateOfBirth),
        updatedAt: toSafeDate(customer?.updatedAt),
      }))

      return {
        data: items,
        meta,
      }
    },
  })

  const searchCustomers = useCallback(
    (query: CustomerSearchQuery) =>
      searchCustomersRequest({
        ...defaultPagination,
        ...defaultQuery,
        ...query,
        page_size: undefined,
        pageSize: 300,
      }),
    [searchCustomersRequest]
  )

  return { searchCustomers }
}

export { useCustomersService }
export type { CustomerSearchQuery }
