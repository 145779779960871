import { AppointmentStatus, textConstants } from 'constants/'

const appointmentStatusDisplayText: Record<AppointmentStatus, string> = {
  [AppointmentStatus.CANCELED]: 'appointment_status.canceled',
  [AppointmentStatus.COMPLETED]: 'appointment_status.completed',
  [AppointmentStatus.CONFIRMED]: 'appointment_status.confirmed',
  [AppointmentStatus.DENIED]: 'appointment_status.denied',
  [AppointmentStatus.PENDING]: 'appointment_status.pending',
  [AppointmentStatus.RESCHEDULED]: 'appointment_status.rescheduled',
}

const toAppointmentStatusDisplayText = (type: AppointmentStatus) =>
  appointmentStatusDisplayText[type] ?? textConstants.unknown

export { toAppointmentStatusDisplayText }
