import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { Query, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useOpportunityLifecyclesService } from '../services'

type OpportunityLifecyclesQuery = Query<{
  customer_gids: string[]
}>

const hookName = 'useOpportunityLifecycles'
const log = logFactory(hookName)

const useOpportunityLifecycles = (
  { customer_gids, ...query }: OpportunityLifecyclesQuery,
  options: QueryOptions = { enabled: true }
) => {
  const { getOpportunityLifecycles } = useOpportunityLifecyclesService()

  const params = {
    customer_gid: customer_gids,
    ...query,
  }

  const doQuery = async () => {
    log(`🥰 Fetching opportunity lifecycles...`, params)

    const { data } = await getOpportunityLifecycles(params)

    log(`🥰 Found ${data.length} opportunity lifecycles`, data)

    return data
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useOpportunityLifecycles }
