import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { CustomerSearchQuery, useCustomersService } from '../services'

const hookName = 'useCustomerSearch'
const log = logFactory(hookName)
const searchDefaultPageSize = 10

const useCustomerSearch = (query: CustomerSearchQuery, options: QueryOptions = { enabled: true }) => {
  const { searchCustomers } = useCustomersService()

  const doQuery = async () => {
    log(`🫂 Searching customers...`, query)

    const { data, meta } = await searchCustomers({
      pageSize: searchDefaultPageSize,
      sort: 'DESC',
      sortBy: 'createdAt',
      ...query,
    })

    log(`🫂 Found ${data.length} customers`, data)

    return { data, meta }
  }

  return useQuery([hookName, query], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useCustomerSearch }
