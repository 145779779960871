import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import type { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { Document, DownloadableDocument, useDocumentService } from '../services'

interface UseDownloadableDocumentsProps {
  documents: Pick<Document, 'id' | 'version_id'>[]
}

const hookName = 'useDownloadableDocuments'
const log = logFactory(hookName)

const useDownloadableDocuments = (
  { documents = [] }: UseDownloadableDocumentsProps,
  options: QueryOptions = { enabled: true, refetchOnMount: false }
) => {
  const { getDownloadableDocument } = useDocumentService()
  const params = { documents }

  const fetchDocuments = async () => {
    log(`📂 Fetching downloadable documents...`, params)

    const promises = documents.map(({ id, version_id }) =>
      getDownloadableDocument({
        id,
        version: version_id,
      }).catch(() => undefined)
    )

    const downloadableDocuments = (await Promise.all(promises)).filter(Boolean) as unknown as DownloadableDocument[]

    log(`📂 Found ${downloadableDocuments.length} downloadable documents`, downloadableDocuments)

    return downloadableDocuments
  }

  return useQuery([hookName, params], fetchDocuments, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(documents?.length),
  })
}

export { useDownloadableDocuments }
