import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useAccountService } from '../services'

type AccountSearchQuery = {
  id?: string[]
}

const hookName = 'useA3AccountsById'
const log = logFactory(hookName)

const useA3AccountsById = (query: AccountSearchQuery, options: QueryOptions = { enabled: true }) => {
  const { getAccounts } = useAccountService()

  const doQuery = async () => {
    log(`🫂 Fetching accounts...`, query)
    const { data: accounts } = await getAccounts({
      ...query,
    })

    log(`🫂 Found accounts`, accounts)

    return accounts
  }

  return useQuery([hookName, query], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useA3AccountsById }
