import { OpportunityFocusReasonDetail } from 'hooks'
import { JourneyActivity } from './journeyActivity'

interface OpportunityLifecycle {
  closed_date_lost: string
  created_at: string
  customer_gid: string
  date_confirmation_receipt: string
  end_of_trial_period: string
  financial_notes: string
  fitter_gid: string
  id: string
  is_closed: string
  opportunity_gid: string
  owner_gid: string
  payment_date: string
  return_date: string
  status: string
  updated_at: string
}

enum JourneyStatus {
  CLOSED = 'CLOSED',
  IN_TRIAL = 'IN_TRIAL',
  OPEN = 'OPEN',
  PREPARING_FOR_FITTING_APPOINTMENT = 'PREPARING_FOR_FITTING_APPOINTMENT',
}

enum SalesStatus {
  LOST = 'LOST',
  OPEN = 'OPEN',
  RETURNED = 'RETURNED',
  WON = 'WON',
}

enum Journey {
  DIRECT_TO_CONSUMER_STANDARD = 'DIRECT_TO_CONSUMER_STANDARD',

  NETWORK_BEST_CHOICE = 'NETWORK_BEST_CHOICE',
  /**
   * @obsolete This journey should not be longer used
   */
  NETWORK_DIRECT_SALE = 'NETWORK_DIRECT_SALE',
  NETWORK_HEAR_TODAY = 'NETWORK_HEAR_TODAY',
  /**
   * @deprecated Use NETWORK_MEDICARE_ADVANTAGE instead
   */
  NETWORK_MEDICAL_ADVANTAGE = 'NETWORK_MEDICAL_ADVANTAGE',
  NETWORK_MEDICARE_ADVANTAGE = 'NETWORK_MEDICARE_ADVANTAGE',
  NETWORK_STANDARD = 'NETWORK_STANDARD',
  NETWORK_TRUHEARING = 'NETWORK_TRUHEARING',
  NETWORK_YOUNG_HEARING_AID = 'NETWORK_YOUNG_HEARING_AID',

  NETWORK_HOME_DELIVERY_BEST_CHOICE = 'NETWORK_HOME_DELIVERY_BEST_CHOICE',
  NETWORK_HOME_DELIVERY_HEAR_TODAY = 'NETWORK_HOME_DELIVERY_HEAR_TODAY',
  NETWORK_HOME_DELIVERY_MEDICARE_ADVANTAGE = 'NETWORK_HOME_DELIVERY_MEDICARE_ADVANTAGE',
  NETWORK_HOME_DELIVERY_STANDARD = 'NETWORK_HOME_DELIVERY_STANDARD',
  NETWORK_HOME_DELIVERY_TRUHEARING = 'NETWORK_HOME_DELIVERY_TRUHEARING',
  NETWORK_HOME_DELIVERY_YOUNG_HEARING_AID = 'NETWORK_HOME_DELIVERY_YOUNG_HEARING_AID',

  TELEAUDIOLOGY_BEST_CHOICE = 'TELEAUDIOLOGY_BEST_CHOICE',
  TELEAUDIOLOGY_ECONOMY = 'TELEAUDIOLOGY_ECONOMY',
  TELEAUDIOLOGY_HEAR_TODAY = 'TELEAUDIOLOGY_HEAR_TODAY',
  /**
   * @deprecated Use TELEAUDIOLOGY_MEDICARE_ADVANTAGE instead
   */
  TELEAUDIOLOGY_MEDICAL_ADVANTAGE = 'TELEAUDIOLOGY_MEDICAL_ADVANTAGE',
  TELEAUDIOLOGY_MEDICARE_ADVANTAGE = 'TELEAUDIOLOGY_MEDICARE_ADVANTAGE',
  TELEAUDIOLOGY_STANDARD = 'TELEAUDIOLOGY_STANDARD',
  TELEAUDIOLOGY_TRUHEARING = 'TELEAUDIOLOGY_TRUHEARING',
}

enum DeliveryModel {
  DIRECT_TO_CONSUMER = 'DIRECT_TO_CONSUMER',
  NETWORK = 'NETWORK',
  NETWORK_HOME_DELIVERY = 'NETWORK_HOME_DELIVERY',
  TELEAUDIOLOGY = 'TELEAUDIOLOGY',
}

enum Solution {
  /**
   * @deprecated Use MEDICARE_ADVANTAGE instead
   */
  ADVANTAGE = 'ADVANTAGE',
  BEST_CHOICE = 'BEST_CHOICE',
  /**
   * @deprecated This solution is no longer used
   */
  DIRECT_SALE = 'DIRECT_SALE',
  ECONOMY = 'ECONOMY',
  HEAR_TODAY = 'HEAR_TODAY',
  /**
   * @deprecated Use MEDICARE_ADVANTAGE instead
   */
  MEDICAL_ADVANTAGE = 'MEDICAL_ADVANTAGE',
  MEDICARE_ADVANTAGE = 'MEDICARE_ADVANTAGE',
  OVER_THE_COUNTER = 'OVER_THE_COUNTER',
  STANDARD = 'STANDARD',
  TRUHEARING = 'TRUHEARING',
}

type JourneyStatusObject = {
  createdAt: string
  createdBy: {
    type: string
    system: string
  }
  id: string
  opportunityId: string
  status: JourneyStatus
}

type SalesStatusObject = {
  createdAt: string
  createdBy: {
    type: string
    system: string
  }
  id: string
  opportunityId: string
  status: SalesStatus
}

interface Opportunity {
  accumulatedWearingTimeInMinutes?: Partial<Record<`last${number}Days` | `week${number}`, number>>
  country: string
  createdAt: string
  createdBy: {
    type: string
    system: string
  }
  customerSalesforceId: string
  deliveryModel: DeliveryModel
  fitterSalesforceId: string
  focusReasons: OpportunityFocusReasonDetail[]
  gid: string
  id: string
  journey: Journey
  journeyActivities?: JourneyActivity[]
  journeyStatus: JourneyStatusObject
  mobileAppInstallationStatus: MobileAppInstallationStatus
  mobileDeviceOS: MobileDeviceOs
  ownerName?: string
  salesforceId: string
  salesforceOwnerId: string
  salesStatus: SalesStatusObject
  solution: Solution
  trialDurationInDays: string
  trialEndDate: string
  updatedAt: string
  updatedBy: {
    type: string
    system: string
  }
}

const mobileDeviceOses = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
} as const

type MobileDeviceOs = typeof mobileDeviceOses[keyof typeof mobileDeviceOses]

const mobileAppInstallationStatuses = {
  INSTALLED: 'INSTALLED',
  NOT_INSTALLED: 'NOT_INSTALLED',
  UNKNOWN: 'UNKNOWN',
} as const

type MobileAppInstallationStatus = typeof mobileAppInstallationStatuses[keyof typeof mobileAppInstallationStatuses]

const pairingAppStatuses = {
  NOT_PAIRED: 'NOT_PAIRED',
  PAIRED: 'PAIRED',
  PAIRED_WITH_TELECARE: 'PAIRED_WITH_TELECARE',
} as const

type PairingAppStatuses = typeof pairingAppStatuses[keyof typeof pairingAppStatuses]

const appPairingInstallStatuses = {
  ANDROID: mobileDeviceOses.ANDROID,
  DEFAULT: 'DEFAULT',
  IOS: mobileDeviceOses.IOS,
  NOT_PAIRED: pairingAppStatuses.NOT_PAIRED,
  PAIRED: pairingAppStatuses.PAIRED,
  PAIRED_WITH_TELECARE: pairingAppStatuses.PAIRED_WITH_TELECARE,
  UNKNOWN: mobileAppInstallationStatuses.UNKNOWN,
} as const

type AppPairingInstallStatuses = typeof appPairingInstallStatuses[keyof typeof appPairingInstallStatuses]

export {
  DeliveryModel,
  Journey,
  JourneyStatus,
  SalesStatus,
  Solution,
  mobileDeviceOses,
  mobileAppInstallationStatuses,
  appPairingInstallStatuses,
  pairingAppStatuses,
}

export type {
  JourneyStatusObject,
  MobileDeviceOs,
  MobileAppInstallationStatus,
  AppPairingInstallStatuses,
  PairingAppStatuses,
  Opportunity,
  OpportunityLifecycle,
  SalesStatusObject,
}
