import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetAudiogramQuery, useHearingTestService } from '../services'
import { groupSpeechTests } from './groupSpeechTests'

const hookName = 'useLatestSpeechTests'
const log = logFactory(hookName)

const useLatestSpeechTests = (customerGid?: string, options: QueryOptions = {}) => {
  const { getSpeechTests } = useHearingTestService()

  const parameters = {
    customerId: customerGid as string,
  }

  const doQuery = async () => {
    const airConductionParams: GetAudiogramQuery = {
      ...parameters,
      conductionType: 'AC',
      sort: 'desc',
      sort_by: 'created_at',
    }

    const boneConductionParams: GetAudiogramQuery = {
      ...parameters,
      conductionType: 'BC',
      sort: 'desc',
      sort_by: 'created_at',
    }

    log(`👂 Fetching latest air conduction test result...`, airConductionParams)
    const airConductionPromise = getSpeechTests(airConductionParams)

    log(`👂 Fetching latest air conduction test result...`, boneConductionParams)
    const boneConductionPromise = getSpeechTests(boneConductionParams)

    const [airConductionResponse, boneConductionResponse] = await Promise.all([
      airConductionPromise,
      boneConductionPromise,
    ])

    log(`👂 Found latest test results for customer: ${customerGid}`, { airConductionResponse, boneConductionResponse })

    const grouped = groupSpeechTests([...airConductionResponse.data, ...boneConductionResponse.data])

    log('🦻 Latest group test results', grouped)

    return grouped
  }

  return useQuery([hookName, parameters], doQuery, {
    ...defaultQueryOptions,
    enabled: Boolean(customerGid),
    ...options,
  })
}

export { useLatestSpeechTests }
