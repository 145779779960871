import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { Query, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useAccountService } from '../services'

type AccountSearchQuery = Query<{
  accountGids?: string[]
  id?: string[]
  onlySearchCustomers?: boolean
  searchTerm?: string
}>

const hookName = 'useA3AccountSearch'
const log = logFactory(hookName)
const accountSearchDefaultPageSize = 100

const useA3AccountSearch = (query: AccountSearchQuery, options: QueryOptions = { enabled: true }) => {
  const { searchAccounts } = useAccountService()

  const doQuery = async () => {
    log(`🫂 Fetching accounts...`, query)

    const { data: accounts } = await searchAccounts({
      page_size: accountSearchDefaultPageSize,
      ...query,
    })

    log(`🫂 Found ${accounts.length} accounts`, accounts)

    return accounts
  }

  return useQuery([hookName, query], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useA3AccountSearch }
