import { useCallback } from 'react'
import { LatestDeviceCouplingInfo, Query, QueryResponse, QueryResponseCamelCase } from 'types'
import { identity } from 'utils'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'
import { AppConfiguration, CreatedByType, DailyWearingTime } from './types'

const apiVersionSuffix = '/api/v1'

enum ApiPathname {
  CUSTOMER_OPPORTUNITY_COUPLINGS = '/couplings',
  DAILY_WEARING_TIMES = '/daily-wearing-times',
  APP_CONFIGURATION = '/app-configuration',
}

type GetCouplingQuery = Query<{
  customerSalesforceId: string
  opportunitySalesforceId: string
  createdBy?: CreatedByType
}>
type GetCouplingResponse = QueryResponse<LatestDeviceCouplingInfo[]>

type GetAppConfigQuery = Query<{ opportunitySalesforceId: string; serialNumbers?: string[] }>
type GetAppConfigResponse = {
  items: AppConfiguration[]
  itemsTotal: number
}

type GetDailyWearingTimesQuery = {
  isFinal?: boolean
  limit?: number
  offset?: number
  serialNumber: string[]
  wearingDay?: string[]
  wearingDayEnd?: Date | string
  wearingDayStart?: Date | string
}

type GetDailyWearingTimesResponseRaw = QueryResponseCamelCase<
  // This is because dates are strings over the wire and will need to be converted to Date objects to match the canonical shape
  Omit<DailyWearingTime, 'createdAt' | 'syncedAt' | 'updatedAt' | 'wearingDay'> & {
    createdAt: string
    syncedAt: string
    updatedAt: string
    wearingDay: string
  }
>

type GetDailyWearingTimesResponse = QueryResponseCamelCase<DailyWearingTime>

interface UseHookContext {
  getDailyWearingTimes: (query: GetDailyWearingTimesQuery) => Promise<GetDailyWearingTimesResponse>
  getLatestCoupling: (query: GetCouplingQuery) => Promise<GetCouplingResponse>
  getAppConfiguration: (query: GetAppConfigQuery) => Promise<GetAppConfigResponse>
}

const useDeviceService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('device')

  const getCouplingsRequest = useRequest<GetCouplingQuery, GetCouplingResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: `${apiVersionSuffix}${ApiPathname.CUSTOMER_OPPORTUNITY_COUPLINGS}`,
  })

  const getLatestCoupling = useCallback(
    (query: GetCouplingQuery) =>
      getCouplingsRequest({
        ...{ sort: 'DESC', sortBy: 'createdAt' },
        ...query,
      }),
    [getCouplingsRequest]
  )

  const getAppConfigRequest = useRequest<GetAppConfigQuery, GetAppConfigResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: `${apiVersionSuffix}${ApiPathname.APP_CONFIGURATION}`,
  })

  const getAppConfiguration = useCallback(
    (query: GetAppConfigQuery) =>
      getAppConfigRequest({
        ...query,
      }),
    [getAppConfigRequest]
  )

  const getDailyWearingTimesRequest = useRequest<GetDailyWearingTimesQuery, GetDailyWearingTimesResponseRaw>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: `${apiVersionSuffix}${ApiPathname.DAILY_WEARING_TIMES}`,
  })

  const getDailyWearingTimes = useCallback(
    async ({ isFinal = true, wearingDayEnd, wearingDayStart, ...query }: GetDailyWearingTimesQuery) => {
      const actualQuery: GetDailyWearingTimesQuery = {
        isFinal,
        ...query,
        ...(wearingDayEnd && wearingDayStart
          ? {
              wearingDayEnd: new Date(wearingDayEnd).toISOString(),
              wearingDayStart: new Date(wearingDayStart).toISOString(),
            }
          : {}),
      }

      const { data = [], ...rest } = await getDailyWearingTimesRequest({
        ...{ sort: 'ASC', sortBy: 'wearingDay' },
        ...actualQuery,
      })

      const transformed = data.map(({ createdAt, syncedAt, updatedAt, wearingDay, ...dailyWearingTime }) => ({
        ...dailyWearingTime,
        createdAt: new Date(createdAt),
        syncedAt: new Date(syncedAt),
        updatedAt: new Date(updatedAt),
        wearingDay: new Date(wearingDay),
      }))

      return {
        ...rest,
        data: transformed,
      }
    },
    [getDailyWearingTimesRequest]
  )

  return {
    getAppConfiguration,
    getDailyWearingTimes,
    getLatestCoupling,
  }
}

export { useDeviceService }
export type { DailyWearingTime, GetCouplingQuery, GetDailyWearingTimesQuery }
