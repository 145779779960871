import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { Query, QueryOptions, SalesOrderRelationType } from 'types'
import { logFactory } from 'utils'
import { useOrdersService } from '../services'

type UseHooksProps = Query<{
  salesOrderGid?: string[]
}>

const hookName = 'useSalesOrderRelations'
const log = logFactory(hookName)

const useSaleOrderRelations = (params: UseHooksProps, options: QueryOptions = { enabled: true }) => {
  const { getSalesOrderRelations } = useOrdersService()

  const actualParams = {
    ...params,
    type: [SalesOrderRelationType.SHIPMENT],
  }

  const doQuery = async () => {
    log(`🎀 Fetching sales order relations...`, actualParams)

    const { data } = await getSalesOrderRelations(actualParams)

    log(`🎀 Found ${data?.length} sales order relations`, data)

    return data
  }

  return useQuery([hookName, actualParams], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && actualParams.salesOrderGid?.length),
  })
}

export { useSaleOrderRelations }
