import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useAppointmentsService } from '../services'
import { useMyAccount } from 'hooks'

const hookName = 'useResource'
const log = logFactory(hookName)

const useResource = (options: QueryOptions = { enabled: true }) => {
  const { getResources } = useAppointmentsService()
  const { data: account } = useMyAccount()

  const doQuery = async () => {
    const params = { gids: [account?.gid as string] }

    log(`🥼 Fetching resources...`, params)

    const { data } = await getResources(params)
    const [resource] = data

    log(`🥼 Returned resource...`, resource)

    return resource
  }

  return useQuery([hookName, account?.gid], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && account?.gid),
  })
}

export { useResource }
