import { FeatureFlagsIdentify } from '@audibene/ta.react-feature-flags'
import { useMyAccount, useSelectedCountry, useUser } from 'hooks'
import { useMemo } from 'react'

const Identify = () => {
  const { data: myAccount } = useMyAccount()
  const { accountId, email, roleFlags } = useUser()
  const fitterCountry = myAccount?.country
  const { selectedCountry } = useSelectedCountry()

  const identity = useMemo(
    () => ({
      accountId: accountId || 'anonymous',
      country: selectedCountry || 'XX',
      email,
      fitterCountry,
      impersonatorEmail: email ?? myAccount?.email,
      isFitter: roleFlags?.isFitter,
    }),
    [accountId, email, fitterCountry, selectedCountry]
  )

  return <FeatureFlagsIdentify identity={identity} />
}

export { Identify }
