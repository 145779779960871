import { useAuth0 } from '@auth0/auth0-react'
import { Country, Role, RoleFlag } from 'constants/'
import { useEffect, useMemo } from 'react'
import { DeliveryModel } from 'types'
import { logFactory } from 'utils'

type RoleFlagsMap = Record<RoleFlag, boolean>

interface UserContext {
  accountId?: string
  allowedCountries: Country[]
  allowedDeliveryModels: DeliveryModel[]
  email?: string
  isAuthenticated: boolean
  name?: string
  roleFlags?: RoleFlagsMap
}

const DOMAIN = 'https://hear.com'

interface Auth0User {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [DOMAIN]: Record<string, any>
  email: string
  name: string
}

const log = logFactory('useUser')

const FALLBACK_ALLOWED_COUNTRIES: Country[] = ['US']

const useUser = (): UserContext => {
  const auth0Context = useAuth0()
  const user = auth0Context.user as Auth0User | undefined
  const email = user?.email
  const meta = user?.[DOMAIN]
  const name = user?.name
  const accountId = meta?.user_metadata?.account_id
  const roles = meta?.roles
  const isAuthenticated = Boolean(user)
  const metaAllowedCountries: Country[] | undefined = meta?.user_metadata?.allowed_countries
  const allowedDeliveryModels: DeliveryModel[] = meta?.user_metadata?.allowed_delivery_models ?? []

  const allowedCountries = useMemo(() => {
    if (metaAllowedCountries?.length) {
      return metaAllowedCountries
    }

    return isAuthenticated ? FALLBACK_ALLOWED_COUNTRIES : []
  }, [isAuthenticated, metaAllowedCountries])

  const userContext: UserContext = useMemo(
    () => ({
      accountId,
      allowedCountries,
      allowedDeliveryModels,
      email,
      isAuthenticated,
      name,
    }),
    [accountId, allowedCountries, email, isAuthenticated, name]
  )

  useEffect(() => {
    if (!isAuthenticated) return

    log(`👤 Auth0 user`, user)
  }, [isAuthenticated])

  /**
   * NOTE: During local development Auth0 will not get back roles
   * ...unless we prompt the user with a consent modal
   * https://github.com/auth0/auth0-react/issues/65#issuecomment-656543646
   * https://auth0.com/docs/authorization/user-consent-and-third-party-applications
   */
  if (roles) {
    const isAdmin = roles?.includes(Role.ADMIN)
    const isEmployeeFitter = roles?.includes(Role.EMPLOYEE_FITTER)
    const isPartnerFitter = roles?.includes(Role.PARTNER_FITTER)
    const hasInternalFitterRole = roles?.includes(Role.INTERNAL_FITTER)
    const isFitter = isEmployeeFitter || isPartnerFitter || hasInternalFitterRole
    const isInternalFitter = isAdmin || isEmployeeFitter || hasInternalFitterRole
    const isOperations = roles?.includes(Role.OPERATIONS)

    userContext.roleFlags = {
      isAdmin,
      isEmployeeFitter,
      isFitter,
      isInternalFitter,
      isOperations,
      isPartnerFitter,
    }
  }

  return userContext
}

export type { RoleFlagsMap }
export { useUser }
