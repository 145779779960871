import { HttpMethod } from 'constants/'
import { useCallback } from 'react'
import { Query, QueryResponse } from 'types'
import { identity, pick } from 'utils'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'
import { Note } from './types'

enum ApiPathname {
  NOTES = `/api/v1/notes`,
  NOTE = `/api/v1/notes/:noteId`,
}

type GetManyNotesQuery = Query<
  Partial<Omit<Note, 'author' | 'content' | 'createdBy' | 'relatedEntityType' | 'updatedBy'>>
>
type GetManyResponse = QueryResponse<Note[]>

type CreateNoteRequest = Omit<Note, 'createdAt' | 'gid' | 'id' | 'updatedAt' | 'updatedBy' | 'wasEdited'>
type CreateNoteResponse = Note

type UpdateNoteRequest = {
  id: string
  content: string
}

type UpdateNoteResponse = Note

interface UseHookContext {
  createNote: (query: CreateNoteRequest) => Promise<CreateNoteResponse>
  getNote: (query: GetManyNotesQuery) => Promise<GetManyResponse>
  updateNote: (query: UpdateNoteRequest) => Promise<UpdateNoteResponse>
}

const useNoteService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('note')

  const createNoteRequest = useRequest<CreateNoteRequest, CreateNoteResponse>({
    baseUrl,
    filterQueryToRequestBody: identity,
    method: HttpMethod.POST,
    pathname: ApiPathname.NOTES,
  })

  const updateNoteRequest = useRequest<UpdateNoteRequest, UpdateNoteResponse>({
    baseUrl,
    filterQueryToRequestBody: pick(['content']),
    filterQueryToRouteParams: ({ id: noteId }: UpdateNoteRequest) => ({ noteId }),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.NOTE,
  })

  const createNote = useCallback((request: CreateNoteRequest) => createNoteRequest(request), [createNoteRequest])

  const updateNote = useCallback(
    (request: UpdateNoteRequest) => {
      return updateNoteRequest(request)
    },
    [updateNoteRequest]
  )

  const getNoteRequest = useRequest<GetManyNotesQuery, GetManyResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.NOTES,
  })

  const getNote = useCallback(
    (query: GetManyNotesQuery) =>
      getNoteRequest({
        ...query,
        sort: 'DESC',
        sortBy: 'createdAt',
      }),
    [getNoteRequest]
  )

  return {
    createNote,
    getNote,
    updateNote,
  }
}

export { useNoteService }
export type { CreateNoteRequest, CreateNoteResponse, GetManyNotesQuery, UpdateNoteRequest, UpdateNoteResponse }
