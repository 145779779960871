/**
 *
 * https://developer.mozilla.org/en-US/docs/Glossary/Base64
 */
async function bytesToBase64DataUrl(bytes: ArrayBuffer, type = 'image/svg+xml'): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = Object.assign(new FileReader(), {
      onerror: () => reject(reader.error),
      onload: () => resolve(reader.result as string),
    })

    reader.readAsDataURL(new File([bytes], '', { type }))
  })
}

const svgElToBase64Png = async (svgEl: Node, width: number, height?: number): Promise<string | undefined> => {
  const svgSerialized = await new XMLSerializer().serializeToString(svgEl)
  const encodedBytes = new TextEncoder().encode(svgSerialized)
  const svgDataUrl = await bytesToBase64DataUrl(encodedBytes)

  return new Promise((resolve) => {
    const img = document.createElement('img')

    // this ensures the SVG is not visible when converting to PNG
    img.style.position = 'absolute'
    img.style.top = '-10000px'

    img.onload = () => {
      document.body.appendChild(img)

      const canvas = document.createElement('canvas')
      const ratio = img.clientWidth / img.clientHeight || 1

      document.body.removeChild(img)

      canvas.width = width
      canvas.height = height ?? width / ratio

      const ctx = canvas.getContext('2d')

      ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)

      try {
        const base64 = canvas.toDataURL('image/png', 1.0)

        resolve(base64)
      } catch (e) {
        console.error('Error occured when converting canvas to png base64', e)
        resolve(undefined)
      }
    }

    img.onerror = (...err) => {
      console.error('Error occured while loading audiogram svg on image', err)
      resolve(undefined)
    }

    img.src = svgDataUrl
  })
}

export { svgElToBase64Png }
