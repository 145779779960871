import { FeatureFlagsToggle as SharedFeatureFlagsToggle, useFeatureFlags } from '@audibene/ta.react-feature-flags'
import { isLiveEnvironment } from 'config'
import { useKeyBindings } from 'hooks'
import { useState } from 'react'

const FeatureFlagsToggle = () => {
  const [isUserAllowedToToggleFlags] = useFeatureFlags(['TEST_FEATURE_FLAGS'])
  const isEnabledInitially = Boolean(!isLiveEnvironment && isUserAllowedToToggleFlags)
  const [isEnabled, beEnabled] = useState(isEnabledInitially)

  useKeyBindings({
    'f l a g s': () => isUserAllowedToToggleFlags && beEnabled(!isEnabled),
  })

  if (isEnabled) {
    return <SharedFeatureFlagsToggle />
  }

  return null
}

export { FeatureFlagsToggle }
