import { Link as MuiLink } from '@material-ui/core'
import cx from 'clsx'
import { forwardRef } from 'react'
import { Link as RrdLink } from 'react-router-dom'
import { useStyles } from './index.styles'

// eslint-disable-next-line @typescript-eslint/no-explicit-any,react/display-name
const StyledLink = forwardRef(({ className, ...props }: any, ref) => {
  const styles = useStyles()

  return <RrdLink {...props} className={cx(styles.container, className)} ref={ref} />
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Link = (props: any) => <MuiLink component={StyledLink} {...props} />

export { Link }
