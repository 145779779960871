import { format, utcToZonedTime } from 'date-fns-tz'
import enUS from 'date-fns/locale/en-US'

const INVALID_DATE = 'Invalid Date'
const FALLBACK = ''
const fallbackLocale = 'en-US'

const calendarDateFormatOptions: Partial<Intl.DateTimeFormatOptions> = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
const dateAndTimeOptions: Partial<Intl.DateTimeFormatOptions> = {
  ...calendarDateFormatOptions,
  hour: '2-digit',
  minute: '2-digit',
}
const dateAndTimeWithWeekdayOptions: Partial<Intl.DateTimeFormatOptions> = { ...dateAndTimeOptions, weekday: 'short' }

const dateAtTimeOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
  month: '2-digit',
  year: 'numeric',
}

/**
 * Formerly: formatAbvTmz
 * @param time E.g. "2022-01-01 00:00:00"
 * @returns E.g. "EST"
 */
const ianaTimeZoneToShortTimezone = (ianaTimeZone: string) =>
  format(utcToZonedTime(new Date(), ianaTimeZone), 'zzz', {
    locale: enUS,
    timeZone: ianaTimeZone,
  })

/**
 * Formerly: formatDate
 * @param time E.g. "2022-01-01 00:00:00"
 * @returns E.g. "Jan 1, 2022"
 */
const dateTimeSansOffsetToClientCalendarDate = (date: Date, locale: string = fallbackLocale) => {
  return date.toLocaleDateString(locale, calendarDateFormatOptions)
}

/**
 * Formerly: formatDateTime
 * @param time E.g. "2022-01-01 13:00:00"
 * @returns E.g. "Jan 1, 2022 01:00 PM"
 */
const dateTimeSansOffsetToClientDateTime = (time: string, locale: string = fallbackLocale) => {
  const date = new Date(time)

  return date.toLocaleDateString(locale, dateAndTimeOptions)
}
/**
 * Formerly formatAppointmentDateTime
 * @param date E.g. "2022-01-01 13:00:00"
 * @returns E.g. "01/01/2022 at 01:00 PM"
 */
const dateTimeSansOffsetToClientDateAtTime = (date: string, locale: string = fallbackLocale) => {
  if (!date) return undefined

  const dateTime = new Date(date).toLocaleDateString(locale, dateAtTimeOptions)

  return dateTime.toString().replace(',', ' at')
}

/**
 *
 * @param dateOfBirth An ISO date string
 * @returns The formatted date string. E.g. `Jan 1, 1900`. Returns an empty string if the given `dateOfBirth` is not parsable by `new Date()`
 */
const formatDateOfBirth = (dateOfBirth?: string | Date, locale: string = fallbackLocale): string => {
  if (!dateOfBirth) return FALLBACK

  try {
    const date = new Date(dateOfBirth)

    if (date.toString() === INVALID_DATE) return FALLBACK

    return Intl.DateTimeFormat(locale, {
      day: 'numeric',
      month: 'short',
      timeZone: 'GMT',
      year: 'numeric',
    }).format(date)
  } catch {
    return FALLBACK
  }
}

/**
 * Formerly: formatDateTimeWithDay
 * @param time
 * @returns
 */
const formatDateTimeWithDayOfWeek = (time: string, locale: string = fallbackLocale) => {
  const date = new Date(time)
  const dateText = date.toLocaleDateString(locale, dateAndTimeWithWeekdayOptions)

  return `${dateText}`
}

/**
 * Formerly: formatTimeTmz
 * @param time
 * @returns
 */
const dateTimeSansOffsetToClientLongDateWithTimeZone = (time: string, locale: string = fallbackLocale) => {
  const date = new Date(time)
  const zone = date.toLocaleTimeString(locale, { timeZoneName: 'short' }).split(' ')[2]

  const dateText = date
    .toLocaleDateString(locale, {
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      month: 'short',
      weekday: 'short',
      year: 'numeric',
    })
    .toString()

  return `${dateText} (${zone})`
}

/**
 *
 * @param dateOfBirth An ISO date string
 * @returns The formatted age. E.g. `42`. If age is zero or not parsable by `new Date()`, returns empty string
 */
const formatAge = (dateOfBirth?: string): string => {
  if (!dateOfBirth) return FALLBACK

  try {
    const dobDate = new Date(dateOfBirth)

    if (dobDate.toString() === INVALID_DATE) return FALLBACK

    const ageDifMs = Date.now() - dobDate.getTime()
    const ageDate = new Date(ageDifMs) // milliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970)

    return age ? age.toString() : ''
  } catch {
    return FALLBACK
  }
}

const makeFormatAppointmentShortDate = (locale: string = fallbackLocale) =>
  Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format

const makeFormatShortDateAndTimeWithTimeZone = (locale: string = fallbackLocale, timeZone?: string) =>
  Intl.DateTimeFormat(locale, {
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    month: '2-digit',
    timeZone,
    timeZoneName: 'short',
    year: 'numeric',
  }).format

export {
  dateTimeSansOffsetToClientCalendarDate,
  dateTimeSansOffsetToClientDateAtTime,
  dateTimeSansOffsetToClientDateTime,
  dateTimeSansOffsetToClientLongDateWithTimeZone,
  formatAge,
  formatDateOfBirth,
  formatDateTimeWithDayOfWeek,
  ianaTimeZoneToShortTimezone,
  makeFormatAppointmentShortDate,
  makeFormatShortDateAndTimeWithTimeZone,
}
