import { RfsDocument } from 'types'

const mergeDocumentsByType = (documents: RfsDocument[]): RfsDocument[] => {
  const documentsObject = documents.reduce((obj: Record<string, RfsDocument>, doc: RfsDocument) => {
    if (!obj[doc.type] || obj[doc.type].updatedAt < doc.updatedAt) {
      obj[doc.type] = doc
    }

    return obj
  }, {})

  return Object.values(documentsObject).reduce((array: RfsDocument[], doc: RfsDocument) => {
    array.push(doc)

    return array
  }, [])
}

export { mergeDocumentsByType }
