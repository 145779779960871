import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'grid',
      width: '36rem',
      maxWidth: '40vw',

      '& .MuiInputBase-root': {
        backgroundColor: '#23273c',
        color: '#a4a6af',
      },

      '& .MuiInputAdornment-root': {
        color: '#a4a6af',
      },

      '& input[type="search"]': {
        color: '#a4a6af',

        '&::-webkit-search-cancel-button': {
          appearance: 'none',
        },
      },

      '& .MuiAutocomplete-clearIndicator': {
        color: '#a4a6af',
        cursor: 'pointer',
        height: '100%',
      },

      '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
        {
          paddingRight: '1rem',
        },

      '& .MuiAutocomplete-endAdornment': {
        display: 'none',
      },

      '&:focus-within': {
        '& .MuiAutocomplete-clearIndicator': {
          color: '#fff',
          cursor: 'pointer',
          height: '100%',
        },
        '& .MuiInputAdornment-root': {
          color: '#fff',
          cursor: 'pointer',
          height: '100%',
        },

        '& input[type="search"]': {
          color: '#fff',

          '&::-webkit-search-cancel-button': {
            appearance: 'none',
          },
        },
      },
    },
    resultTitle: {
      fontWeight: 'normal',
    },
    resultSubtitle: {
      opacity: 0.6,
    },
  })
) as unknown as UseStylesFunction
