import { AppointmentStatus, Country, defaultPagination } from 'constants/'
import { useCallback } from 'react'
import { identity } from 'remeda'
import { Appointment, Query, QueryResponse, Resource, Shop } from 'types'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'

enum ApiPathname {
  APPOINTMENTS = '/api/v1/appointments',
  RESOURCES = '/api/v1/resources',
  SHOPS = '/api/v1/shops',
}

type GetAppointmentsQuery = Query<{
  customerGids?: string[]
  dateEnd?: Date
  dateStart?: Date
  resourceGids?: string[]
  statuses?: AppointmentStatus[]
}>

type GetResourcesQuery = Query<{
  gids: string[]
}>

type GetShopsQuery = Query<{
  country?: Country
  id?: string[]
  gid?: string[]
  search?: string
}>

type GetAppointmentsResponse = QueryResponse<Appointment[]>
type GetResourcesResponse = QueryResponse<Resource[]>
type GetShopsResponse = QueryResponse<Shop[]>

interface UseHookContext {
  getAppointments: (query: GetAppointmentsQuery) => Promise<GetAppointmentsResponse>
  getResources: (query: GetResourcesQuery) => Promise<GetResourcesResponse>
  getShops: (query: GetShopsQuery) => Promise<GetShopsResponse>
}

const filterAppointmentsQueryToSearchParams = ({
  dateEnd,
  dateStart,
  resourceGids: resource_gid,
  ...rest
}: GetAppointmentsQuery) => ({
  ...rest,
  date_end: dateEnd?.toISOString(),
  date_start: dateStart?.toISOString(),
  resource_gid,
})

const filterResourcesQueryToSearchParams = ({ gids }: GetResourcesQuery) => ({
  gid: gids,
})

const useAppointmentsService = (): UseHookContext => {
  const { url: baseUrl } = useServiceCountryUrl('appointment')

  const getAppointmentsRequest = useRequest<GetAppointmentsQuery, GetAppointmentsResponse>({
    baseUrl,
    filterQueryToSearchParams: filterAppointmentsQueryToSearchParams,
    pathname: ApiPathname.APPOINTMENTS,
  })

  const getAppointments = useCallback(
    (query: GetAppointmentsQuery) =>
      getAppointmentsRequest({
        ...defaultPagination,
        ...query,
      }),
    [getAppointmentsRequest]
  )

  const getResourcesRequest = useRequest<GetResourcesQuery, GetResourcesResponse>({
    baseUrl,
    filterQueryToSearchParams: filterResourcesQueryToSearchParams,
    pathname: ApiPathname.RESOURCES,
  })

  const getResources = useCallback(
    (query: GetResourcesQuery) =>
      getResourcesRequest({
        ...defaultPagination,
        ...query,
      }),
    [getAppointmentsRequest]
  )

  const getShopsRequest = useRequest<GetShopsQuery, GetShopsResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.SHOPS,
  })

  const getShops = useCallback(
    (query: GetShopsQuery) =>
      getShopsRequest({
        ...defaultPagination,
        ...query,
      }),
    [getAppointmentsRequest]
  )

  return { getAppointments, getResources, getShops }
}

export { useAppointmentsService }
export type { GetResourcesQuery, GetShopsQuery }
