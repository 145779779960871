import { useQuery } from 'react-query'
import { logFactory } from 'utils'
import { useMedicalRecordsService } from '../services'

const hookName = 'useMedicalRecord'
const log = logFactory(hookName)

interface GetMedicalRecordQuery {
  customerGid?: string
  opportunityGid?: string
}

const useMedicalRecord = ({ customerGid, opportunityGid }: GetMedicalRecordQuery) => {
  const { getMedicalRecord } = useMedicalRecordsService()
  const params = { customerGid, opportunityGid }

  const doQuery = async () => {
    log(`👨‍⚕️ Fetching medical record...`, params)

    const medicalRecord = await getMedicalRecord({
      customerGid,
      opportunityGid,
    })

    log(`👨‍⚕️ Found medical record`, medicalRecord)

    return medicalRecord
  }

  return useQuery([hookName, params], doQuery, {
    enabled: Boolean(customerGid && opportunityGid),
  })
}

export { useMedicalRecord }
