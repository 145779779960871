import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { makeTranslationProxy } from '../util'

enum TranslationKey {
  LEFT_EAR = 'LEFT_EAR',
  RIGHT_EAR = 'RIGHT_EAR',
  DATE = 'DATE',
  HEARING_TEST = 'HEARING_TEST',
  NO_DATA = 'NO_DATA',
}
const useSpeechAndWordTestHeaderTranslations = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      DATE: (options) => t('speech_and_word_tests.table_headers.date', options),
      HEARING_TEST: (options) => t('speech_and_word_tests.table_headers.hearing_test', options),
      LEFT_EAR: (options) => t('speech_and_word_tests.table_headers.left_ear', options),
      NO_DATA: (options) => t('speech_and_word_tests.default_text.no_data', options),
      RIGHT_EAR: (options) => t('speech_and_word_tests.table_headers.right_ear', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useSpeechAndWordTestHeaderTranslations }
