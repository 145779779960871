import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import type { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetManyPartnersQuery, usePartnersService } from '../services'

const hookName = 'usePartner'
const log = logFactory(hookName)

const usePartners = (
  query: GetManyPartnersQuery = {} as GetManyPartnersQuery,
  options: QueryOptions = { enabled: true }
) => {
  const { getMany } = usePartnersService()

  const doQuery = async () => {
    log(`👤 Fetching partners...`, query)

    const { data } = await getMany(query)

    log(`👤 Found partners`, data)

    return data
  }

  return useQuery([hookName, query], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { usePartners }
