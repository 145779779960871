import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { FC } from 'react'
import { theme } from '../../../GlobalStyles'
import { TaLight, ThemeBase } from '@audibene/react-components-lib'

const ThemeProvider: FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <StyledThemeProvider theme={TaLight as ThemeBase}>
      <StylesProvider injectFirst>{children}</StylesProvider>
    </StyledThemeProvider>
  </MuiThemeProvider>
)

export { ThemeProvider }
