import { CommonProp } from './postAppointmentOutcome'

enum MetaFlag {
  HAS_COUPLING = CommonProp.HAS_COUPLING,
}

enum TimelineAction {
  ADD = 'ADD',
  ADD_APPOINTMENT = 'ADD_APPOINTMENT',
  EDIT = 'EDIT',
  EDIT_APPOINTMENT = 'EDIT_APPOINTMENT',
}

enum NoteType {
  AFTERCARE_FOLLOW_UP = 'aftercare_follow_up',
  APPOINTMENT_CANCELLATION = 'appointment_cancellation',
  CONSULTATION_EVALUATION = 'consultation_evaluation',
  CONSULTATION_NOTE = 'consultation_note',
  COUPLING_NOTE = 'coupling_note',
  CUSTOMER_INTERACTION = 'customer_interaction',
  FOLLOW_UP = 'follow_up',
  GENERAL = 'general',
  HEAR_INTERACTION = 'hear_interaction',
  HEARING_AID_EVALUATION = 'hearing_aid_evaluation',
  INITIAL_FITTING = 'initial_fit',
  INITIAL_FITTING_WITH_SHOEBOX = 'initial_fit_with_shoebox',
  LEGACY_FOLLOW_UP = 'legacy_follow_up',
  OPPORTUNITY_NOTE = 'opportunity_note',
  VIDEO_CARE_CALL = 'video_care_call',
}

enum NoteSubType {
  CANCELED = 'CANCELED',
  RESCHEDULED = 'RESCHEDULED',
}

enum NoteTemplateName {
  CUSTOMER_INTERACTION = 'interaction_customer',
  HEAR_INTERACTION = 'interaction_hear',
  GENERAL = 'note_general',
  POST_APPOINTMENT_OUTCOME_CAN = 'post_appointment_outcome_form_ca',
  POST_APPOINTMENT_OUTCOME_US = 'post_appointment_outcome_form_us',
}

enum ConsultationSubnoteType {
  FINANCIAL = 'financial',
  GENERAL = 'general',
  LIFESTYLE = 'lifestyle',
  MOTIVATION = 'motivation',
}

export { ConsultationSubnoteType, MetaFlag, NoteTemplateName, NoteType, NoteSubType, TimelineAction }
