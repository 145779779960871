import { SalesOrderStatus, SalesOrderType } from 'types'

const typeDisplayText: Record<SalesOrderType | 'UNKNOWN', string> = {
  FIRST_APPOINTMENT: 'First Appointment',
  FOLLOW_UP: 'Follow-up',
  INITIAL: 'Initial',
  UNKNOWN: 'Unknown',
}

const statusDisplayText: Record<SalesOrderStatus | 'UNKNOWN', string> = {
  CANCELLED: 'Canceled',
  CREATED: 'Created',
  DELIVERED: 'Delivered',
  PARTIALLY_DELIVERED: 'Partially Delivered',
  PARTIALLY_SHIPPED: 'Partially Shipped',
  PROCESSING: 'Processing',
  SHIPPED: 'Shipped',
  UNKNOWN: 'Unknown',
}

const orderStatusToDisplayText = (status: SalesOrderStatus) => statusDisplayText[status] ?? statusDisplayText.UNKNOWN
const orderTypeToDisplayText = (type: SalesOrderType) => typeDisplayText[type] ?? typeDisplayText.UNKNOWN

export { orderStatusToDisplayText, orderTypeToDisplayText }
