import { FeatureFlagsProvider } from '@audibene/ta.react-feature-flags'
import { LAUNCH_DARKLY_CLIENT_ID, FORCE_ENABLED_FEATURE_FLAGS as featureFlagOverrides, isLiveEnvironment } from 'config'
import { FeatureFlagName, featureFlagNameToKeyMap } from 'hooks'
import { ReactNode } from 'react'
import { FeatureFlagsToggle } from './FeatureFlagToggle'
import { Identify } from './Identify'

const forceEnabledFeatureFlags = (isLiveEnvironment ? [] : featureFlagOverrides) as FeatureFlagName[]

type Props = {
  children: ReactNode
}

const FeatureFlagProvider = ({ children }: Props) => (
  <FeatureFlagsProvider
    config={{ clientId: LAUNCH_DARKLY_CLIENT_ID }}
    featureFlagNameToKeyMap={featureFlagNameToKeyMap}
    forceEnabledFeatureFlags={forceEnabledFeatureFlags}
  >
    <FeatureFlagsToggle />
    <Identify />
    {children}
  </FeatureFlagsProvider>
)

export { FeatureFlagProvider }
