import merge from 'lodash.merge'
import {
  clamp,
  fromPairs,
  groupBy,
  identity,
  indexBy,
  isArray,
  isBoolean,
  isDate,
  isDefined,
  isError,
  isFunction,
  isNil,
  isNumber,
  isObject,
  isPromise,
  isString,
  map,
  omit,
  pathOr,
  pick,
  pipe,
  prop,
  reverse,
  sort,
  sortBy,
  take,
  toPairs,
  uniq,
} from 'remeda'

/**
 *
 * @param value any value that you wish to return when this method is called
 * @returns the given value
 */
const always = (value: unknown) => () => value

const isEmptyArray = (value: unknown) => Array.isArray(value) && value.length === 0
const isEmptyObject = (value: unknown) => isObject(value) && Object.keys(value as Record<string, unknown>).length === 0
const isEmptyString = (value: unknown) => isString(value) && value.length === 0

/**
 *
 * @param value The value to check for emptiness
 * @returns boolean
 *
 * ```javascript
 * // Some known empty values...
 * undefined, null, '', {}, [], NaN
 * ```
 */
const isEmpty = (value: unknown) => {
  switch (true) {
    case isNil(value) || // null or undefined
      isEmptyArray(value) || // empty array
      isEmptyString(value) || // empty string
      isEmptyObject(value): // empty object
      return true
    default:
      return false
  }
}

export {
  always,
  clamp,
  fromPairs,
  groupBy,
  identity,
  indexBy,
  isArray,
  isBoolean,
  isEmpty,
  isDate,
  isDefined,
  isError,
  isFunction,
  isNil,
  isNumber,
  isObject,
  isPromise,
  isString,
  map,
  merge,
  omit,
  pathOr,
  pick,
  pipe,
  prop,
  reverse,
  sort,
  sortBy,
  take,
  toPairs,
  uniq,
}
