import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { logFactory } from 'utils'
import { useRemoteFittingService } from '../services'

const hookName = 'useRfsDocuments'
const log = logFactory(hookName)

const useRfsDocuments = (fittingId?: string) => {
  const { getDocuments } = useRemoteFittingService()

  const fetchDocuments = async () => {
    const params = { fittingId: fittingId as string }

    log(`🗃️ Fetching RFS documents...`, params)

    const documents = await getDocuments(params)

    log(`🗃️ Found ${documents.length} RFS documents`, documents)

    return documents
  }

  return useQuery([hookName, fittingId], fetchDocuments, {
    ...defaultQueryOptions,
    enabled: Boolean(fittingId),
    refetchOnMount: false,
  })
}

export { useRfsDocuments }
