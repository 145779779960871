import type { Customer } from 'types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customerV2ToDisplayName = (customer?: Customer): string => {
  if (!customer) {
    return ``
  }

  const { familyName, givenName } = customer

  switch (true) {
    case Boolean(familyName && givenName):
      return `${familyName}, ${givenName}`
    case Boolean(familyName):
      return `${familyName}, ???`
    case Boolean(givenName):
      return `???, ${givenName}`
    default:
      return ''
  }
}

export { customerV2ToDisplayName }
