import { AppState, Auth0Provider as Auth0ProviderNative } from '@auth0/auth0-react'
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'config'
import { FC } from 'react'
import { useNavigate } from 'react-router'

const Auth0Provider: FC = ({ children }) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0ProviderNative
      audience={AUTH0_AUDIENCE}
      cacheLocation="localstorage"
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      useRefreshTokens
    >
      {children}
    </Auth0ProviderNative>
  )
}

export { Auth0Provider }
