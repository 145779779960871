import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { AppointmentType as TranslationKey } from 'constants/'
import { makeTranslationProxy } from '../util'

const useAppointmentTypeAbbreviationTranslations = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      [TranslationKey.AFTERCARE_FOLLOW_UP]: (options) =>
        t('appointment_type_abbreviations.aftercare_follow_up', options),
      [TranslationKey.FOLLOW_UP]: (options) => t('appointment_type_abbreviations.follow_up', options),
      [TranslationKey.HEARING_AID_EVALUATION]: (options) =>
        t('appointment_type_abbreviations.hearing_aid_evaluation', options),
      [TranslationKey.VIDEO_CARE_CALL]: (options) => t('appointment_type_abbreviations.video_care_call', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useAppointmentTypeAbbreviationTranslations }
