import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    columnGap: '1rem',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr',
    margin: '0 -1rem 0rem -1rem',
    padding: '0.5rem 1rem',
    position: 'relative',
    transition: '0.2s box-shadow ease-in-out',
    zIndex: 10,
  },
  children: {
    alignItems: 'center',
    display: 'grid',
    justifyContent: 'end',
    position: 'relative',
  },
  pinned: {
    position: 'sticky',
    top: '5.25rem',
    zIndex: 100,
  },
  title: {
    lineHeight: 2,
  },
  after: {
    background: '#ffffff',
    bottom: 0,
    position: 'absolute',
    height: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'all 0.3s ease-in-out',
    width: '25%',
  },
  afterPinned: {
    background: 'rgb(0 0 0 / 20%)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 40%)',
    width: 'calc(100% - 1rem)',
  },
})

export { useStyles }
