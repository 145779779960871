import { useMemo } from 'react'
import { useEnumTranslation, TranslationHandler } from '../useEnumTranslation'
import { makeTranslationProxy } from '../util'

enum TranslationKey {
  LOW_WEARING_TIME = 'LOW_WEARING_TIME',
  IN_TRIAL = 'IN_TRIAL',
  LOST = 'LOST',
  NO_FOLLOW_UP_SCHEDULED = 'NO_FOLLOW_UP_SCHEDULED',
  OPEN = 'OPEN',
  PDR_NOT_SIGNED = 'PDR_NOT_SIGNED',
  PREPARING_FOR_FITTING_APPOINTMENT = 'PREPARING_FOR_FITTING_APPOINTMENT',
  RETURNED = 'RETURNED',
  UNKNOWN = 'UNKNOWN',
  WON = 'WON',
}

const useOpportunityStatusTranslations = () => {
  const t = useEnumTranslation()

  return useMemo<Record<TranslationKey, TranslationHandler>>(() => {
    const translations: Record<TranslationKey, TranslationHandler> = {
      IN_TRIAL: (options) => t('opportunity_status.in_trial', options),
      LOST: (options) => t('opportunity_status.lost', options),
      LOW_WEARING_TIME: (options) => t('opportunity_status.low_wearing_time', options),
      NO_FOLLOW_UP_SCHEDULED: (options) => t('opportunity_status.no_follow_up_scheduled', options),
      OPEN: (options) => t('opportunity_status.open', options),
      PDR_NOT_SIGNED: (options) => t('opportunity_status.pdr_not_signed', options),
      PREPARING_FOR_FITTING_APPOINTMENT: (options) =>
        t('opportunity_status.preparing_for_fitting_appointment', options),
      RETURNED: (options) => t('opportunity_status.returned', options),
      UNKNOWN: (options) => t('opportunity_status.unknown', options),
      WON: (options) => t('opportunity_status.won', options),
    }

    return makeTranslationProxy(translations)
  }, [t])
}

export { useOpportunityStatusTranslations }
