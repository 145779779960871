import { differenceInDays, isValid as isValidDate } from 'date-fns'
import { HearingTestServiceConductionType, HearingTestServicePureToneTest, HearingTestServiceType } from 'types'
import { prop, sort } from 'utils'

type UiConductionType = 'airConduction' | 'boneConduction'
type UITestType = 'threshold' | 'ucl' | 'mcl'
type PureToneTestGrouping = Record<UiConductionType | UITestType, HearingTestServicePureToneTest>

type SiblingTestResult = {
  remainingTests: HearingTestServicePureToneTest[]
  siblingTestGrouping: PureToneTestGrouping
  sourceTest: HearingTestServicePureToneTest
}

const SIBLING_CREATED_AT_THRESHOLD_IN_DAYS = 1

const sortTestsByCreatedAtDesc = (a: HearingTestServicePureToneTest, b: HearingTestServicePureToneTest) => {
  const aTime = new Date(a.created_at)
  const bTime = new Date(b.created_at)

  const aCompare = aTime.getTime()
  const bCompare = bTime.getTime()

  return bCompare - aCompare
}

const serviceConductionTypeToUiConductionType: Record<HearingTestServiceConductionType, UiConductionType> =
  Object.freeze({
    AC: 'airConduction',
    BC: 'boneConduction',
  })

const serviceTypeToUiType: Record<HearingTestServiceType, UITestType> = Object.freeze({
  MCL: 'mcl',
  THRESHOLD: 'threshold',
  UCL: 'ucl',
})

const isValidTest = (pureToneTest: HearingTestServicePureToneTest) => {
  const { left_ear, right_ear } = pureToneTest

  return Boolean(left_ear?.results?.length || right_ear?.results?.length)
}

const makeIsSiblingTest =
  ({ created_at, conduction_type, type }: HearingTestServicePureToneTest) =>
  (pureToneTest: HearingTestServicePureToneTest) => {
    const sourceTestCreatedAt = new Date(created_at)
    const sourceTestConductionType = serviceConductionTypeToUiConductionType[conduction_type]
    const sourceTestType = serviceTypeToUiType[type]
    const destinationTestCreatedAt = new Date(pureToneTest.created_at)
    const destinationTestConductionType = serviceConductionTypeToUiConductionType[pureToneTest.conduction_type]
    const destinationTestType = serviceTypeToUiType[pureToneTest.type]
    const areAllDatesValid = [sourceTestCreatedAt, destinationTestCreatedAt].every(isValidDate)
    const areAllConductionTypesValid = [sourceTestConductionType, destinationTestConductionType].every(Boolean)

    if (!areAllDatesValid || !areAllConductionTypesValid) {
      return false
    }

    if (sourceTestConductionType === destinationTestConductionType && sourceTestType === destinationTestType) {
      return false
    }

    const daysDifference = Math.abs(differenceInDays(sourceTestCreatedAt, destinationTestCreatedAt))

    return daysDifference <= SIBLING_CREATED_AT_THRESHOLD_IN_DAYS
  }

const findSiblingTestResult = (pureToneTests: HearingTestServicePureToneTest[]): SiblingTestResult => {
  // Pluck off the first "non-UCL" test to be our source test, keep the rest in a new (but still desc ordered) array
  const orderedTests = [...pureToneTests]
  const firstNonUclTestIndex = orderedTests.findIndex((test) => test.type !== 'UCL')
  const sourceTest = orderedTests.splice(firstNonUclTestIndex, 1)[0]
  // Grab all the remaining test ids in the correct order for later use
  const testIds = orderedTests.map(prop('id'))

  // Create our isSibling function based on the source test
  const isSiblingTest = makeIsSiblingTest(sourceTest)

  // Find all the siblings
  const siblingTestGrouping = orderedTests
    .filter(isSiblingTest) // Only valid siblings
    .map(prop('id')) // Reduce to a list of test ids
    .map((testId) => testIds.indexOf(testId)) // Reduce to a list of test indexes
    .reduce((acc, siblingIndex) => {
      const siblingTest = orderedTests[siblingIndex]
      const conductionType = serviceConductionTypeToUiConductionType[siblingTest.conduction_type]
      const type = serviceTypeToUiType[siblingTest.type]

      if (acc[conductionType] || acc[type]) {
        // Already added this conduction type / test type, don't overwrite
        return acc
      }

      // Add this conduction type / test type to our mapping
      return {
        ...acc,
        [type === 'ucl' ? type : conductionType]: siblingTest,
      }
    }, {} as PureToneTestGrouping)

  const actualSiblingTestIndexes = Object.values(siblingTestGrouping).map(({ id }) => testIds.indexOf(id))
  const remainingTests = [...orderedTests]
  let currentIndex = orderedTests.length - 1

  // Remove all the actual sibling tests from our remaining tests
  while (currentIndex >= 0) {
    if (actualSiblingTestIndexes.indexOf(currentIndex) !== -1) {
      // Remove test from remaining tests
      remainingTests.splice(currentIndex, 1)
    }

    currentIndex = currentIndex - 1
  }

  return {
    remainingTests,
    siblingTestGrouping,
    sourceTest,
  }
}

const toGroupedTests = (pureToneTests: HearingTestServicePureToneTest[]): PureToneTestGrouping[] => {
  if (!pureToneTests?.length) {
    return []
  }

  let { remainingTests, siblingTestGrouping, sourceTest } = findSiblingTestResult(pureToneTests)

  const conductionType = serviceConductionTypeToUiConductionType[sourceTest.conduction_type]

  let testGroupings: PureToneTestGrouping[] = [
    {
      ...siblingTestGrouping,
      [conductionType]: sourceTest,
    },
  ]

  while (remainingTests.length > 0) {
    ;({ remainingTests, siblingTestGrouping, sourceTest } = findSiblingTestResult(remainingTests))

    const conductionType = serviceConductionTypeToUiConductionType[sourceTest.conduction_type]

    const testGrouping: PureToneTestGrouping = {
      ...siblingTestGrouping,
      [conductionType]: sourceTest,
    }

    testGroupings = [...testGroupings, testGrouping]
  }

  return testGroupings
}

const groupPureToneTests = (allPureToneTests: HearingTestServicePureToneTest[]) => {
  if (!allPureToneTests?.length) {
    return []
  }

  const validPureToneTests = allPureToneTests.filter(isValidTest)
  const validSortedPureToneTests = sort(validPureToneTests, sortTestsByCreatedAtDesc)

  return toGroupedTests(validSortedPureToneTests)
}

export { SIBLING_CREATED_AT_THRESHOLD_IN_DAYS, groupPureToneTests }
