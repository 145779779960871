import { ReactNode, createContext, useContext } from 'react'
import { Appointment } from 'types'

interface Context {
  appointment?: Pick<Appointment, 'country' | 'gid' | 'id' | 'opportunity_gid' | 'status'>
}

interface ProviderProps {
  appointment: Context['appointment']
  children: ReactNode
}

const context = createContext<Context>({})

const useCurrentAppointment = () => {
  const { appointment } = useContext(context)

  return appointment
}

const { Provider } = context

const CurrentAppointmentProvider = ({ appointment, children }: ProviderProps) => (
  <Provider value={{ appointment }}>{children}</Provider>
)

export { CurrentAppointmentProvider, useCurrentAppointment }
