import { ShipmentTrackingStatus } from 'types'

const statusDisplayText: Record<ShipmentTrackingStatus | 'UNKNOWN', string> = {
  AVAILABLE_FOR_PICKUP: 'Available for pickup',
  DELIVERED: 'Delivered',
  EXCEPTION: 'Delivery exception',
  FAILED_ATTEMPT: 'Failed delivery attempt',
  IN_DELIVERY: 'Out for delivery',
  INFO_RECEIVED: 'In transit',
  PENDING: 'In transit',
  RETURNED: 'Returned',
  TRANSIT: 'In transit',
  UNKNOWN: 'Unknown',
}

const shipmentTrackingStatusToDisplayText = (status: ShipmentTrackingStatus) =>
  statusDisplayText[status] ?? statusDisplayText.UNKNOWN

export { shipmentTrackingStatusToDisplayText }
