import { TYPEFORM_ORDER_FORM_URL } from 'config'
import { Customer } from 'types'
import { applySearchParametersToUrl } from 'utils'

const baseUrl = new URL(TYPEFORM_ORDER_FORM_URL)

const useOrderLink = (customer?: Partial<Customer>) => {
  const url = applySearchParametersToUrl(baseUrl, {
    country: customer?.countryCode,
    customer: customer?.salesforceId,
  })

  return url.href
}

export { useOrderLink }
